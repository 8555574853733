import { css } from "@emotion/css";
import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import {
    AntennaIcon,
    ArrowUpCircleIcon,
    ChevronDownIcon,
    ClapperboardIcon,
    HomeIcon,
    KeyIcon,
    NotepadTextIcon,
    PencilRulerIcon,
    SettingsIcon,
    SparkleIcon,
    Users2Icon,
    Wand2Icon,
} from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { switchProjectAction } from "~/bootstap";
import backend from "~/client/sdk";
import { QUERY_KEYS } from "~/constants/keys";
import { getClientInfo } from "~/dataProcessor/api/api";
import { usePHFeatureFlag } from "~/dataProcessor/hooks/global/posthog";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { DialogDescription, DialogFooter, DialogHeader, DialogSubtitle, DialogTitle } from "~/design-system/atom/dialog";
import ComposioLogo from "~/ui/components/base/logo";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { usePlainLogs } from "~/ui/screens/action_logs/logs";
import { If } from "~/utils/reactComponent";
import { upperCaseFirstLetter } from "~/utils/string";

import { Input } from "~/design-system/atom/input";
import { Button } from "~/design-system/atom/newButton";
import { useToast } from "~/design-system/atom/use-toast";
import { queryClient } from "~/queryClient";
import { scrollBarStyle } from "~/ui/constants/style/common";

export const selectProjectAtom = atom<string | null>(null);
export const useProjects = () => {
    const {
        data: projectsData,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.GET_PROJECTS_QUERY_KEY,
        queryFn: () =>
            backend.clientAuthService.getProjects().then((res) => {
                return res.data;
            }),
        staleTime: 100 * 100000,
        retry: false,
        enabled: true,
    });

    const [selectedProject, setSelectedProject] = useAtom(selectProjectAtom);

    const createProject = useMutation({
        mutationFn: (name: string) => {
            return backend.clientAuthService.addProject({
                body: {
                    name,
                },
            });
        },
    });

    const deleteProject = useMutation({
        mutationFn: (id: string) => {
            return backend.clientAuthService.deleteProject({
                path: {
                    projectId: id,
                },
            });
        },
    });

    const switchProject = async (id: string) => {
        setSelectedProject(id);
        switchProjectAction(id);
        //@ts-ignore
        window.projectId = id;
        const allQueries = queryClient.getQueryCache().findAll();
        allQueries.forEach((query) => {
            queryClient.resetQueries({ queryKey: query.queryKey });
        });
    };

    useEffect(() => {
        if (!selectedProject && projectsData?.items?.length) {
            // Flow:
            // select first oldest project, the list is sorted by createdAt in descending order
            // This is done to ensure if x-composio-project-id is not set, the first created project is selected
            // Otherwise, we would have to wait for all projects to load, which might degrade the UX
            const lastProjectIndex = projectsData?.items?.length - 1;
            const lastProject = projectsData?.items[lastProjectIndex] as { id: string };
            if (!!lastProject) {
                setSelectedProject(lastProject.id);
            }
        }
    }, [projectsData]);

    return {
        projects: projectsData?.items || [],
        isProjectsLoading: isLoading,
        isProjectsError: isError,
        isProjectsSuccess: isSuccess,
        refetchProjects: refetch,
        createProject,
        deleteProject,
        switchProject,
    };
};

export const ProjectBox = () => {
    const { projects, switchProject } = useProjects();
    const [selectedProject] = useAtom(selectProjectAtom);

    const projectOptions = useMemo(() => {
        const options = projects.map((project) => ({
            name: project.name,
            value: project.id,
        }));

        options.push({
            name: "Add new project",
            value: "add_new_project",
        });

        return options;
    }, [projects]);
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);

    const [showSwitchProjectModal, setShowSwitchProjectModal] = useState(false);

    const handleSwitchProject = useCallback(
        (id: string) => {
            setShowSwitchProjectModal(true);
            switchProject(id);
            setTimeout(() => {
                setShowSwitchProjectModal(false);
            }, 1000);
        },
        [switchProject],
    );

    return (
        <>
            <SimpleDropdown
                className="w-full w-[180px]"
                //@ts-ignore
                options={projectOptions}
                placeholder="Project"
                onChange={(value) => {
                    if (value === "add_new_project") {
                        setShowAddProjectModal(true);
                    } else {
                        handleSwitchProject(value as string);
                    }
                }}
                value={selectedProject || ""}
            />
            <If condition={showAddProjectModal}>
                <AddProjectModal
                    currentProjects={projects}
                    setOpen={() => {
                        setShowAddProjectModal(false);
                    }}
                    onSuccess={(id: string) => {
                        handleSwitchProject(id as string);
                    }}
                />
            </If>
        </>
    );
};

export const AddProjectModal = ({
    setOpen,
    onSuccess: handleSuccess,
    currentProjects,
}: {
    setOpen: (e: boolean) => void;
    onSuccess: (id: string) => void;
    currentProjects: {
        [key: string]: unknown;
    }[];
}) => {
    const { toast } = useToast();

    const { createProject, refetchProjects, switchProject } = useProjects();

    const [projectName, setProjectName] = useState("");

    const router = useRouter();
    const handleAddProject = () => {
        const projectNameSchema = z
            .string()
            .min(3, { message: "Project name cannot be shorter than 2 characters" })
            .max(50, { message: "Project name cannot be longer than 50 characters" })
            .regex(/^[a-zA-Z]+$/, { message: "Project name should only contain alphabets" })
            .refine((name) => !currentProjects.some((project) => project.name === name), {
                message: "Project with this name already exists",
            });

        const validationResult = projectNameSchema.safeParse(projectName);

        if (!validationResult.success) {
            toast({
                title: "Error",
                description: validationResult.error.errors[0]?.message || "Validation error",
                variant: "destructive",
            });
            return;
        }
        createProject.mutate(projectName, {
            onSuccess: (project) => {
                setOpen(false);
                toast({
                    title: "Project added",
                    description: "Project created successfully. Switching to the new project...",
                    variant: "success",
                });
                refetchProjects();
                router.push("/dashboard");

                //@ts-ignore
                handleSuccess(project.data.id);
            },
            onError: (error) => {
                const errorMessage = (error as any)?.response?.data?.message || "Something went wrong";
                toast({
                    title: "Error",
                    description: errorMessage,
                });
            },
        });
    };
    return (
        <DialogBoxCenter
            open={true}
            setOpen={setOpen}
            className={clsx("max-h-[90vh] w-[500px] overflow-y-scroll bg-[#fff] px-6 py-7 pt-5", scrollBarStyle)}
        >
            <DialogHeader>
                <DialogTitle>Add new project</DialogTitle>
                <DialogSubtitle>Add a new project to get started</DialogSubtitle>
            </DialogHeader>
            <DialogDescription>
                <div className="flex gap-4">
                    <Input
                        className="h-[36px] w-[300px] flex-auto"
                        placeholder="Project name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </div>
            </DialogDescription>
            <DialogFooter>
                <Button disabled={createProject.isLoading} variant="default" size={40} className="ml-0 h-[36px]" onClick={handleAddProject}>
                    Add and switch
                </Button>
            </DialogFooter>
        </DialogBoxCenter>
    );
};

export const Sidebar = React.memo(({ actionable = true }: { actionable?: boolean }) => {
    const { logsData } = usePlainLogs();
    const showPlayground = usePHFeatureFlag({
        key: "onboarding-v2",
        defaultValue: {
            isEnabled: false,
            payload: null,
        },
    });

    // @ts-ignore
    const isConnectorAndLogsLogsEmpty = useMemo(() => {
        return logsData?.length === 0;
    }, [logsData]);

    const sidebarStyle = clsx(
        "fixed flex h-[100vh] max-h-[100vh] flex-col",
        css`
            width: 264px;
            transition: width 0.1s ease-in-out;

            .menu span,
            .hidden-text {
                visibility: visible;
                transition: visibility 10s cubic-bezier(0, 0, 1);
            }
        `,
        "sidebar",
    );

    const isLogsNotEmpty = useMemo(() => {
        return logsData?.length > 0;
    }, [logsData]);

    const links = useMemo(() => {
        return [
            ...(showPlayground.isEnabled
                ? [
                      {
                          link: "/playground",
                          icon: <SparkleIcon height={13} width={13} strokeWidth={1.5} />,
                          text: "Playground",
                      },
                  ]
                : []),
            {
                link: "/dashboard",
                icon: <HomeIcon height={14} width={14} />,
                text: "Dashboard",
            },
            ...(isLogsNotEmpty
                ? [
                      {
                          link: isConnectorAndLogsLogsEmpty ? "/dashboard" : "/sdk_guide",
                          icon: <Wand2Icon type="solid" height={13} width={13} strokeWidth={2} />,
                          text: isConnectorAndLogsLogsEmpty ? "Get started" : "SDK Guide",
                      },
                  ]
                : []),
            {
                link: "/apps",
                icon: <PencilRulerIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Tools",
                children: [
                    {
                        link: "/apps",
                        icon: <ClapperboardIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "All tools",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/your_apps",
                        icon: <ClapperboardIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Integrations",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/connections",
                        icon: <Users2Icon height={13} width={13} strokeWidth={1.8} stroke={"#000"} />,
                        text: "Connected accounts",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                    {
                        link: "/active_triggers?showDisabled=true",
                        icon: <AntennaIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Active triggers",
                        disabled: isConnectorAndLogsLogsEmpty,
                    },
                ],
            },
            {
                link: "/logs",
                icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Logs",
                disabled: isConnectorAndLogsLogsEmpty,
                children: [
                    ,
                    {
                        link: "/logs",
                        icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Action logs",
                    },
                    {
                        link: "/trigger_logs",
                        icon: <NotepadTextIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Trigger logs",
                    },
                ],
            },
            {
                link: "/settings",
                icon: <SettingsIcon height={13} width={13} strokeWidth={1.8} />,
                text: "Settings",
                children: [
                    {
                        link: "/settings",
                        icon: <KeyIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "API Keys",
                    },
                    {
                        link: "/events_and_triggers",
                        icon: <AntennaIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Events and Triggers",
                    },
                    {
                        link: "/plan_settings",
                        icon: <PencilRulerIcon height={13} width={13} strokeWidth={1.8} />,
                        text: "Billing",
                    },
                    {
                        link: "/team_members",
                        icon: <Users2Icon height={13} width={13} strokeWidth={1.8} />,
                        text: "Manage team",
                    },
                ],
            },
        ];
    }, [isLogsNotEmpty]);

    return (
        <div className={sidebarStyle}>
            <div className="px-5 pt-4">
                <div className="flex w-full items-center justify-between  text-[#000]">
                    <ComposioLogo textColor={"#171717"} height={24} className=" ml-[-4px]  flex items-center " />
                </div>
            </div>
            <div className="flex h-full flex-col flex-col justify-between px-[14px] pb-3 pt-8">
                <MenuSection
                    // @ts-ignore
                    // @ts-ignore

                    links={links}
                    actionable={actionable}
                />

                <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center flex justify-between w-full">
                        <span className="text-[13px] font-[500] text-[#000]">Project</span>
                        <ProjectBox />
                    </div>
                    <PlanUpgrade />
                </div>
            </div>
        </div>
    );
});

export const PlanUpgrade = () => {
    const {
        data: clientInfo,
        isLoading,
        isError,
        isSuccess,
    } = useQuery({
        queryKey: ["clientInfo"],
        queryFn: getClientInfo,
        retry: 3,
        refetchOnMount: true,
        staleTime: Infinity,
    });

    // @ts-ignore
    const plan = clientInfo?.data?.client?.plan;
    const isHobbyPlan = plan === "HOBBY";
    const router = useRouter();

    if (isLoading || isError) return null;

    return (
        <div
            className={clsx(
                "flex w-full flex-col ",
                css`
                    border: 0.5px solid rgb(202, 202, 202);
                    border-radius: 12px !important;
                    overflow: hidden;
                `,
            )}
        >
            <div
                className={clsx(
                    "px-2 py-1 text-center font-gilroy text-[13px] font-[500] text-black-800",
                    css`
                        border-bottom: 0.5px solid rgb(202, 202, 202);
                        background: #fff;
                    `,
                )}
            >
                Your Plan
            </div>
            <div className={clsx("flex min-w-[236px] items-center text-[13px] font-[600]", pricingPlanCSS)}>
                <div className="px-2">{isHobbyPlan ? "🎾 Hobby Plan" : `⭐ ${upperCaseFirstLetter(plan?.toLowerCase() || "")} Plan`}</div>
                {isHobbyPlan && (
                    <div
                        onClick={() => {
                            router.push("/plan_settings");
                        }}
                        className="ml-auto flex h-full cursor-pointer items-center rounded-[0px] bg-[#701EF5] px-2 text-white hover:bg-[#701EF5]"
                    >
                        <ArrowUpCircleIcon height={15} width={15} />
                        <span className="ml-2 mt-[2px] text-[12.5px]">Upgrade</span>
                    </div>
                )}
            </div>
        </div>
    );
};

const pricingPlanCSS = css`
    background: #fff;
    overflow: hidden;
    height: 32px;
    color: rgb(64, 64, 64);
    transition: 0.25s ease-in-out;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-family: Gilroy, sans-serif;
    cursor: default !important;
`;

const openMenuAtom = atom("");

const MenuSection = ({
    links,
}: {
    links: {
        link: string;
        icon: React.ReactNode;
        text: string;
        disabled?: boolean;
        children?: {
            link: string;
            icon: React.ReactNode;
            text: string;
            disabled?: boolean;
        }[];
    }[];
    actionable: boolean;
}) => {
    const [openMenu, setOpenMenu] = useAtom(openMenuAtom);
    const router = useRouter();

    useEffect(() => {
        const matchedLink = links.find(
            (link) => link.link === router.pathname || link.children?.some((child) => child.link === router.pathname),
        );

        if (matchedLink) {
            setOpenMenu(matchedLink.link);
        }
    }, [router.asPath, links]);

    return (
        <div className=" mb-3 flex flex-col gap-[10px] ">
            {links.map(({ link, icon, text, disabled, children }) => {
                return (
                    // @ts-ignore
                    <MenuBlock
                        {...{
                            link,
                            icon,
                            text,
                            disabled,
                            childItems: children,
                            openMenu,
                        }}
                        key={link}
                    />
                );
            })}
        </div>
    );
};

const MenuBlock = ({
    link,
    icon,
    text,
    disabled,
    childItems,
    openMenu,
}: {
    link: string;
    icon: React.ReactNode;
    text: string;
    disabled: boolean;
    childItems: {
        link: string;
        icon: React.ReactNode;
        text: string;
        disabled: boolean;
    }[];
    openMenu: string;
}) => {
    const hasMenuChildren = childItems && childItems.length > 0;
    const isMenuOpen = openMenu === link;

    return (
        <React.Fragment key={link}>
            <MenuLink
                hasMenuChildren={!!hasMenuChildren}
                isMenuOpen={isMenuOpen && !!hasMenuChildren}
                key={link}
                {...{
                    link,
                    icon,
                    text,
                    disabled,
                }}
            />
            <If condition={hasMenuChildren && isMenuOpen}>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-col gap-[10px]">
                        {hasMenuChildren &&
                            isMenuOpen &&
                            childItems.map(({ link, icon, text, disabled }) => {
                                return (
                                    <div className="pl-2" key={link}>
                                        <MenuLink
                                            hasMenuChildren={false}
                                            isMenuOpen={isMenuOpen}
                                            key={link}
                                            {...{
                                                link,
                                                icon,
                                                text,
                                                disabled,
                                            }}
                                        />{" "}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </If>
        </React.Fragment>
    );
};

const MenuLink = ({
    hasMenuChildren,
    isMenuOpen,
    text,
    icon,
    link,
    onClick,
}: {
    hasMenuChildren: boolean;
    isMenuOpen: boolean;
    text: string;
    icon: React.ReactNode;
    link?: string;
    onClick?: () => void;
}) => {
    const { pathname } = useRouter();
    const isSelected = pathname === link && !hasMenuChildren;

    return (
        <Link href={link || "#"} className={clsx("cursor-pointer")} onClick={onClick}>
            <div
                className={clsx(
                    "menu flex h-[30px] flex-1 cursor-pointer items-center gap-2 rounded-[10px] px-[12px] py-[4.5px] text-[13.5px] font-[500] text-black-400",
                    css`
                        border: 0.5px solid transparent;

                        ${isSelected &&
                        `
                         path,rect,circle,polyline{
                            stroke:#9537de;
                            
                        }
                        color: #000;
                        background-color: #fff;
                        box-shadow: lch(0 0 0 / 0.02) 0px 4px 4px -1px,lch(0 0 0 / 0.03) 0px 1px 1px 0px!important;
                        border: .5px solid #cacaca;
                        font-weight:500;`}
                        transition: all .18s linear;

                        :hover {
                            background: #fff;
                            border: 0.5px solid #dfdfdf;
                        }
                    `,
                )}
            >
                {icon}
                <span className="mt-[2px] tracking-[.45px]">{text}</span>
                {hasMenuChildren && (
                    <motion.div
                        initial={{
                            rotate: 0,
                            marginLeft: "auto",
                        }}
                        animate={{
                            rotate: !isMenuOpen ? -0 : -180,
                            marginLeft: "auto",
                        }}
                        style={{
                            marginLeft: "auto",
                        }}
                        transition={{
                            duration: 0.2,
                        }}
                    >
                        <ChevronDownIcon height={15} width={15} className={clsx("ml-auto transition-transform duration-100")} />
                    </motion.div>
                )}
            </div>
        </Link>
    );
};
