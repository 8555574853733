import { useRouter } from "next/router";
import { useEffect } from "react";
import { getCookie } from "~/utils/cookie";

export function withAuth(WrappedComponent: React.ComponentType<unknown>) {
    return (props: unknown) => {
        const router = useRouter();

        useEffect(() => {
            const isLoggedIn = getCookie("isLoggedIn");

            if (isLoggedIn !== "true") router.replace("/");
        }, [router]);

        const isLoggedIn = getCookie("isLoggedIn");

        if (isLoggedIn !== "true") return null;

        // @ts-expect-error
        return <WrappedComponent {...props} />;
    };
}
