import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
import { useRouter } from "next/router";
import { getConnectorIntegrationInfo } from "~/dataProcessor/api/api";
import ConnectorPage from "~/ui/screens/integrationPage";
import { withAuth } from "~/utils/hoc";

export const useGetConnectorInfo = (connectorId: string) => {
    const { data, isSuccess, isError, isLoading, refetch } = useQuery(
        ["connector", connectorId],
        () =>
            getConnectorIntegrationInfo({
                connectorId,
            }),
        {
            enabled: !!connectorId,
            staleTime: 1000 * 60 * 5,
            refetchOnMount: "always",
        },
    );

    return {
        data,
        isSuccess,
        isError,
        isLoading,
        refetch,
    };
};

function Page() {
    const { query } = useRouter();

    const { data: connectorInfo } = useGetConnectorInfo(query.connectorId as string);

    return (
        <>
            <Head>
                <title>{connectorInfo?.name}</title>
            </Head>
            <ConnectorPage />
        </>
    );
}

export default withAuth(Page);
