import { clsx } from "clsx";

//@ts-ignore
import NoSSR from "react-no-ssr";
import { plainBlackBackgroundCSS, scrollBarStyle } from "../constants/style/common";
import { Sidebar } from "../containers/base/Sidebar";

export const SidebarLayoutWrapper = ({ children, isNonSidebarPage }: { children: React.ReactNode; isNonSidebarPage: boolean }) => {
    return isNonSidebarPage ? (
        <>{children}</>
    ) : (
        <div className={clsx("flex min-h-[calc(100vh-10px)] overflow-x-hidden text-white", plainBlackBackgroundCSS, scrollBarStyle)}>
            <Sidebar />
            {children}
        </div>
    );
};

export const SSRWrapper = ({ children, isSEOPath }: { children: React.ReactNode; isSEOPath: boolean }) =>
    isSEOPath ? <>{children}</> : <NoSSR>{children}</NoSSR>;
