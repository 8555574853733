import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    queryCache: new QueryCache({}),
    mutationCache: new MutationCache({}),
    defaultOptions: {
        queries: {
            staleTime: 30 * 1000,
            refetchOnMount: "always",
            cacheTime: 120 * 1000,
            refetchOnWindowFocus: true,
            retry: 3,
        },
    },
});
