import { useRouter } from "next/router";

export const UpdateQuery = () => {
    const router = useRouter();

    const updateQueryFn = (key: string, value: string | any) => {
        const query = { ...router.query };

        if (value !== undefined) {
            query[key] = value;
        } else {
            delete query[key];
        }

        router.replace(
            {
                pathname: router.pathname,
                query,
            },
            undefined,
            {
                shallow: true,
            },
        );
    };

    return updateQueryFn;
};

export const UpdateQueryBatch = (isReplace = true) => {
    const router = useRouter();

    const updateQueryFn = (updates: Record<string, string | null>) => {
        const query = { ...router.query };

        for (const [key, value] of Object.entries(updates)) {
            if (value === null || value === undefined || value === "") {
                delete query[key];
            } else {
                query[key] = value;
            }
        }

        if (isReplace) {
            router.replace(
                {
                    pathname: router.pathname,
                    query,
                },
                undefined,
                {
                    shallow: true,
                },
            );
        } else {
            router.push({
                pathname: router.pathname,
                query,
            });
        }
    };

    return updateQueryFn;
};
