import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import { TabletSmartphoneIcon } from "lucide-react";

export const MobileNotAvailableBanner = ({ showLoader }: { showLoader: boolean }) => {
    const userAgent = navigator.userAgent;
    const isNotDesktop = typeof window !== "undefined" && /android|iPad|iPhone|iPod/i.test(userAgent);
    return (
        <AnimatePresence>
            {showLoader && (
                <div
                    className={clsx(
                        "fixed left-0 top-0 z-[100] flex h-[100vh] w-[100vw] items-center justify-center pb-[100px] text-white",
                    )}
                >
                    <div className={clsx(`absolute left-0 top-0 h-[100vh] w-[100%]`)}></div>
                    <div className="z-4 relative flex flex-col items-center text-black-200 ">
                        <TabletSmartphoneIcon height={32} width={32} className="mb-4" strokeWidth={1.5} />
                        {isNotDesktop ? (
                            <>
                                <div className="ml-[-9px] mt-2 flex  max-w-[360px] gap-4  text-center text-[15px]">
                                    Composio is not available on mobile
                                </div>
                                <div className="mt-2 flex  max-w-[360px] gap-4  text-center text-[15px]">
                                    Please use the desktop version of Composio
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="ml-[-9px] mt-2 flex  max-w-[360px] gap-4  text-center text-[15px]">
                                    We are currently working on supporting smaller screen sizes.{" "}
                                </div>
                                <div className="mt-2 flex  max-w-[360px] gap-4  text-center text-[15px]">
                                    Please use a larger screen in the meantime
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};
