import Fuse from "fuse.js";
import { useCallback, useMemo } from "react";
import { z } from "zod";
import { ZActionItem, ZTriggerItem } from "~/dataProcessor/api/api";

export const useTriggerOrActionFuseSearch = ({
    dataToSearch,
}: {
    dataToSearch: (z.infer<typeof ZTriggerItem> | z.infer<typeof ZActionItem>)[];
}) => {
    const fuseInstance = useMemo(() => {
        const data =
            dataToSearch.map((item: z.infer<typeof ZTriggerItem> | z.infer<typeof ZActionItem>) => ({
                ...item,
                formatted_name: item.name,
            })) || [];

        const fuse = new Fuse(data, {
            keys: [
                {
                    name: "formatted_name",
                    weight: 6,
                },
                {
                    name: "description",
                    weight: 1,
                },
            ],
            threshold: 0.5,
            includeScore: true,
            distance: 70,
            minMatchCharLength: 1,
        });

        return fuse;
    }, [dataToSearch]);

    const searchData = useCallback(
        (search: string) => {
            return search
                ? fuseInstance
                      .search(search) // @ts-ignore
                      .sort((a: unknown, b: unknown) => a.score - b.score) // @ts-ignore
                      .map((result: unknown) => result.item)
                : dataToSearch;
        },
        [fuseInstance],
    );

    return {
        searchData,
        fuseInstance,
    };
};
