import md5 from "md5";
import { ToolTipBox } from "~/design-system/atom/tooltip";

const noop = () => {};

export const ProfileGravatar = ({
    member,
    direction = "top",
}: {
    member: {
        email?: string | undefined;
        name?: string | undefined;
    };
    direction?: "top" | "bottom";
}) => {
    return (
        <ToolTipBox align="center" side={direction} content={<>{member?.email}</>} className="w-fit" onOpenChange={noop}>
            <img
                src={
                    member?.email
                        ? `https://www.gravatar.com/avatar/${md5((member?.email as string)?.trim().toLowerCase())}?d=identicon`
                        : ""
                }
                alt={member?.email! || undefined}
                className="mr-2 h-6 w-6 rounded-full"
            />
        </ToolTipBox>
    );
};

export default ProfileGravatar;
