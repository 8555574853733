import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { CopyIcon, EyeIcon, EyeOffIcon } from "lucide-react";
import router, { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { z } from "zod";
import { ZTriggerInstanceItem, changeTriggerStatus } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { Button } from "~/design-system/atom/button";
import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { PaginationComponent } from "~/ui/components/custom/PagninationComponent";
import { Table } from "~/ui/components/custom/Table";
import { copyText } from "~/utils/dom";
import { formatActionName, showFirstAndLastNLetters } from "~/utils/string";
import { RightSideContent } from "../../containers/base/base";
import { ActionButton } from "../../containers/common/ActionButton";
import { useTriggerInstanceData } from "./hooks";

const noop = () => {};

export const PaginationFilter = ({ totalPage = "" }: { totalPage: string | number }) => {
    const { query } = useRouter();

    const updateQuery = UpdateQuery();
    const showDisabled = query.showDisabled === "true";

    return (
        <div className="mt-4 flex items-center justify-between text-[14px] text-black-200">
            <div className="flex items-center gap-2">
                <ToggleButton
                    title={showDisabled ? "Hide inactive" : "Show inactive"}
                    onClick={() => {
                        updateQuery("showDisabled", !showDisabled ? "true" : "false");
                    }}
                    isActive={showDisabled}
                />
            </div>
            <div className="flex items-center gap-2">
                <PaginationComponent
                    currentPage={Number(query.page) || 1}
                    totalPages={Number(totalPage) || 1}
                    onChange={(page) => {
                        updateQuery("page", page.toString());
                    }}
                />
            </div>
        </div>
    );
};

export const ShowInactiveButtonWQuery = () => {
    const { query } = useRouter();
    const showDisabled = query.showDisabled === "true";

    const updateQuery = UpdateQuery();

    return (
        <ToggleButton
            title={showDisabled ? "Hide inactive" : "Show inactive"}
            onClick={() => {
                updateQuery("showDisabled", !showDisabled ? "true" : "false");
            }}
            isActive={showDisabled}
        />
    );
};

const ToggleButton = ({ title, onClick, isActive }: { title: string; onClick: () => void; isActive: boolean }) => {
    return (
        <div
            className="flex flex-1 cursor-pointer items-center gap-2 overflow-hidden rounded-[20px] border border-grey-200 bg-[#fff] px-4 py-2 text-black-600 hover:border-grey-600 hover:bg-[#ffffffc9]"
            onClick={onClick}
        >
            <div className="mt-[2px] text-[13px] font-[500] leading-[100%]">{title}</div>
            {!isActive ? <EyeOffIcon className="h-[14px] w-[14px]" color="#000" /> : <EyeIcon className="h-[14px] w-[14px]" />}
        </div>
    );
};

export const TriggersScreen = () => {
    const { query } = useRouter();

    const { triggerData } = useTriggerInstanceData();

    const triggerDataById = useMemo(() => {
        //@ts-ignore
        return triggerData?.triggers?.find((trigger) => trigger.triggerName === query.name) || [];
    }, [triggerData]);

    return (
        <RightSideContent
            header={
                <div className="w-full">
                    <div>
                        <span className="min-w-[120px] text-black-200">
                            {formatActionName(triggerDataById?.triggerName || "Active triggers")}
                        </span>
                    </div>
                </div>
            }
        >
            <div className={clsx(css``)}>
                <div className={"w-full  px-8 pb-10"}>
                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                        <TriggerInstanceRenderer />
                    </ErrorBoundary>
                </div>
            </div>
        </RightSideContent>
    );
};
export const TriggerInstanceRenderer = () => {
    const router = useRouter();
    const { query } = router;
    const { triggerData: data, isLoading, isError, isSuccess } = useTriggerInstanceData();

    const updateQuery = UpdateQuery();

    useEffect(() => {
        if (!router.isReady) return;

        if (!query.page) updateQuery("page", "1");
    }, [router.query]);

    const headerRenderer = () => {
        const tableHeaders = ["Trigger ID", "Entity ID", "Last Updated", "Trigger Name", "Config", "Actions"];

        return tableHeaders.map((header, index) => (
            <div
                key={index}
                className={clsx("font-gilroy text-[12px] font-[500]", index === tableHeaders?.length - 1 ? "flex justify-end pr-4" : "")}
            >
                {header}
            </div>
        ));
    };

    const rowRenderer = useCallback((row: z.infer<typeof ZTriggerInstanceItem>) => {
        return <Row triggerInstance={row} />;
    }, []);

    return (
        <div className="h-full w-full">
            <PaginationFilter totalPage={data?.pageInfo.totalPages || ""} />
            <Table
                gridCSS={gridBox}
                rows={data?.triggers as z.infer<typeof ZTriggerInstanceItem>[]}
                state={isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "none"}
                noResultText={
                    <div className="flex flex-col items-center justify-center gap-[12px]">
                        <div className="text-[17px] font-[600] text-black-200">No active triggers enabled</div>
                        <div className="text-[14.5px] font-[400] text-black-600">
                            Triggers let you perform actions on events from connected providers.
                        </div>
                        <div className="text-[14.5px] font-[400] text-black-600">
                            Enable triggers on integrations or connected accounts to see them here.
                        </div>
                    </div>
                }
                headerRenderer={headerRenderer}
                rowRenderer={rowRenderer}
                onRowClick={(row) => {
                    // eslint-disable-next-line no-console
                    router.push(`/logs?search=${row.id}`);
                }}
                rowContainerHeight={window.innerHeight - 174}
                rowHeight={50}
            />
        </div>
    );
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 100px 80px 140px 140px 320px 1fr;
    gap: 16px;
    width: 100%;
`;

const Row = ({ triggerInstance }: { triggerInstance: z.infer<typeof ZTriggerInstanceItem> }) => {
    const { toast } = useToast();
    const [isEnabled, setIsEnabled] = useState(false);

    const triggerInstanceRef = useRef();

    // Define the mutation using useMutation hook
    const { mutate } = useMutation(changeTriggerStatus, {
        onError: () => {
            toast({
                title: "Error",
                description: "Failed to update trigger status",
                variant: "destructive",
            });

            // Revert the switch to its original state in case of error
            setIsEnabled(!isEnabled);
        },
    });

    useEffect(() => {
        if (!triggerInstanceRef.current) {
            //@ts-ignore
            triggerInstanceRef.current = triggerInstance;
            setIsEnabled(triggerInstance.disabledAt === null);
        }
    }, [triggerInstance]);

    const handleStatusChange = (enabled: boolean) => {
        setIsEnabled(enabled);
        // Call the mutation function
        mutate({
            triggerInstanceId: triggerInstance.id,
            enabled,
        });
    };

    const [isCopied, setIsCopied] = useState(false);

    return (
        <>
            <div
                className="flex w-fit items-center gap-1 text-[13px] font-[500] text-black-600"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <ToolTipBox
                    side="right"
                    align="center"
                    content={isCopied ? "✅ Copied!" : "Copy ID"}
                    onOpenChange={(e) => {
                        if (!e) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <div
                        className="flex items-center gap-1"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(triggerInstance.id);
                            setIsCopied(true);
                        }}
                    >
                        <span>{showFirstAndLastNLetters(triggerInstance.id)}</span>
                        <CopyIcon className="cursor-pointer" height={12} width={12} />
                    </div>
                </ToolTipBox>
            </div>
            <div className="h-[18px] overflow-hidden text-ellipsis whitespace-nowrap text-[13px]">
                {triggerInstance?.connection?.clientUniqueUserId || "--"}
            </div>
            <div className="h-[18px] overflow-hidden text-ellipsis whitespace-nowrap text-[13px]">
                {new Date(triggerInstance.updatedAt).toLocaleString()}
            </div>
            <ToolTipBox content={triggerInstance.triggerName} side="bottom">
                <div className="h-[18px] overflow-hidden text-ellipsis whitespace-nowrap text-[13px]">{triggerInstance.triggerName}</div>
            </ToolTipBox>
            <div
                className="h-[18px] overflow-hidden text-ellipsis whitespace-nowrap text-[13px]"
                title={JSON.stringify(triggerInstance.triggerConfig)}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {JSON.stringify(triggerInstance.triggerConfig)}
            </div>
            <div
                className="flex items-center justify-end gap-1 pr-3"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Switch
                    id="airplane-mode"
                    checked={isEnabled}
                    onCheckedChange={(e) => {
                        handleStatusChange(e);
                    }}
                />
                <Button
                    variant={"plain"}
                    size={28}
                    className="ml-2 bg-[white]"
                    onClick={() => router.push(`/trigger_logs?search=${triggerInstance.id}`)}
                >
                    View Logs
                </Button>
                <ActionButton triggerInstanceId={triggerInstance.id} />
            </div>
        </>
    );
};
