import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { CopyIcon, SearchIcon } from "lucide-react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { QUERY_KEYS } from "~/constants/keys";
import { ZActionItem, getActions } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { Button } from "~/design-system/atom/button";
import { Input } from "~/design-system/atom/input";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { Table } from "~/ui/components/custom/Table";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { useConnectionInfo } from "~/ui/screens/connections";
import { copyText } from "~/utils/dom";
import { If } from "~/utils/reactComponent";
import { formatActionName, upperCaseAll } from "~/utils/string";
import { useTriggerOrActionFuseSearch } from "../../../dataProcessor/hooks/useTriggerOrActionFuseSearch";
import { RightSideContent } from "../../containers/base/base";
import { SearchBox, ShowCustomActionCode, useAppInfo } from "../appScreen/appScreen";
import { E2EFlowWizard } from "../appScreen/modules/wizard";
import { CodeButton } from "../integrationPage";

export const ActionRenderer = () => {
    const router = useRouter();

    const { query } = router;

    const connectorInfo = useGetConnectorInfo(query.connectorId as string);
    const connectionInfo = useConnectionInfo(query.connectionId as string);

    const appName = useMemo(() => {
        //@ts-ignore
        return connectionInfo?.data?.appUniqueId || connectorInfo?.data?.appName;
    }, [connectionInfo, connectorInfo]);

    const {
        data: actionsData = [],
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: QUERY_KEYS.getActionQueryKey(appName, query),
        queryFn: () =>
            getActions({
                page: Number(query.page || 1),
                appName: appName || undefined,
                usecase: query.usecase as string,
            }),
        staleTime: 600000,
        enabled: !!appName,
    });

    const updateQuery = UpdateQuery();

    useEffect(() => {
        if (router.isReady && !query.page) {
            updateQuery("page", "1");
        }
    }, [router.isReady, updateQuery]);

    const headerRenderer = useCallback(() => {
        const tableHeaders = ["Name", "Enum", "Description", ""];

        return tableHeaders.map((header, index) => (
            <div
                key={index}
                className={clsx("font-gilroy text-[12px] font-[500]", index === tableHeaders.length - 1 ? "flex justify-end pr-4" : "")}
            >
                {header}
            </div>
        ));
    }, []);

    const rowRenderer = useCallback((row: z.infer<typeof ZActionItem>) => <ActionRow actionData={row} appName={appName} />, []);

    const search = useMemo(() => (query.search as string) || "", [query.search]);

    //@ts-ignore
    const { searchData } = useTriggerOrActionFuseSearch({
        // @ts-ignore
        dataToSearch: actionsData?.items || [],
    });

    const rows = useMemo(() => {
        if (!actionsData) return [];

        //@ts-ignore
        let data = actionsData?.items || [];

        if (query.tag) {
            data = data.filter((item: z.infer<typeof ZActionItem>) => item.tags?.includes(query.tag as string));
        }

        if (search) data = searchData(search);

        return data as z.infer<typeof ZActionItem>[];
    }, [isLoading, search, query.tag]);

    const [, setOpenAction] = useAtom(openActionAtom);

    const { appInfo } = useAppInfo(appName);

    const isCustomApp = appInfo?.categories?.includes("custom");

    return (
        <div className="h-full w-full">
            <div className="pt-2">
                {/* @ts-ignore */}
                <ActionNTriggerFilterBar isCustomApp={isCustomApp} isAction={true} actions={actionsData?.items || []} />
            </div>
            <Table
                gridCSS={gridBox}
                rows={rows}
                state={isSuccess ? "success" : isLoading ? "loading" : "error"}
                noResultText="No action found"
                headerRenderer={headerRenderer} //@ts-ignore
                rowRenderer={rowRenderer}
                onRowClick={(row) => {
                    setOpenAction(row.name);
                }}
                rowContainerHeight={window.innerHeight - 174}
                rowHeight={50}
            />
        </div>
    );
};

export const ActionNTriggerFilterBar = ({
    isAction = false,
    actions = [],
    isCustomApp = false,
    appName,
}: {
    isAction: boolean;
    actions: z.infer<typeof ZActionItem>[];
    isCustomApp: boolean;
    appName?: string;
}) => {
    const router = useRouter();
    const updateQuery = UpdateQuery();

    const { query } = router;

    useEffect(() => {
        if (!router.isReady) return;

        updateQuery("search", "");
    }, [router.isReady]);

    const listOfTags = useMemo(() => {
        if (actions.length === 0) return [];

        // @ts-ignore
        const tags = [];

        for (const item of actions) {
            // @ts-ignore
            if (item.tags) {
                // @ts-ignore
                for (const tag of item.tags) {
                    tags.push(tag);
                }
            }
        }

        // @ts-ignore
        return [
            {
                name: "All",
                value: "all",
            },
            ...Array.from(new Set(tags)).map((tag) => ({
                name: tag,
                value: tag,
            })),
        ];
    }, [actions]);

    const updateQueryFn = UpdateQuery();

    const [open, setShowOpen] = useState(false);

    return (
        <div className="mt-3 flex items-center justify-between text-[13px] text-black-300">
            <div className="flex items-center gap-3">
                <div className="mr-1 min-w-[fit-content] text-[13px]">Filter by: </div>
            </div>
            {open && <ShowCustomActionCode setOpen={() => setShowOpen(false)} appName={appName || ""} />}
            <div className={clsx("flex gap-2 justify-end", listOfTags.length > 1 ? "w-[550px]" : "w-[450px]")}>
                <If condition={!!appName}>
                    <CodeButton variant="black" text="Add custom action" setShowCode={() => setShowOpen(true)} />
                </If>
                <If condition={listOfTags.length > 1}>
                    <SimpleDropdown
                        // @ts-ignore
                        // @ts-ignore

                        options={listOfTags}
                        onChange={(value) => {
                            if (value === "all") {
                                updateQueryFn("tag", "");
                            } else {
                                updateQueryFn("tag", value as string);
                            }
                        }}
                        value={(query.tag as unknown as string) || ""}
                        placeholder="Filter by tag"
                    />
                </If>
                {isAction ? (
                    <SearchBox isCustomApp={isCustomApp} />
                ) : (
                    <Input
                        className="min-w-[300px]"
                        placeholder="Search by name, app name, connection"
                        type="text"
                        size={32}
                        onChange={(e) => {
                            updateQuery("search", e.target.value);
                        }}
                        defaultValue={query.search || ""}
                        icon={<SearchIcon height={14} width={14} color="grey" />}
                    />
                )}
            </div>
        </div>
    );
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 300px 100px 1fr 200px;
    gap: 10px;
    width: 100%;
`;

const openActionAtom = atom<string | null>(null);

const ActionRow = ({ actionData, appName }: { actionData: z.infer<typeof ZActionItem>; appName: string }) => {
    const router = useRouter();
    const isAppPage = router.pathname === "/app/[appId]";

    const [isCopied, setIsCopied] = useState(false);
    const [openAction, setOpenAction] = useAtom(openActionAtom);

    const open = openAction === actionData.name;

    const { query } = router;

    return (
        <>
            {open && (
                <E2EFlowWizard
                    initialData={{
                        currentStep: 3,
                        first: {
                            appName: appName,
                        },
                        second: {
                            integrationID: query.connectorId as string,
                        },
                        third: {
                            connectedAccountId: query.connectionId as string,
                            actionName: actionData.enum as string,
                        },
                    }}
                    setOpen={() => {
                        setOpenAction(null);
                    }}
                />
            )}
            <div
                className="h-[20px] max-w-[full] overflow-hidden overflow-x-auto text-[14px] font-[500] text-black-600"
                title={actionData.display_name || ""}
            >
                {formatActionName(actionData.display_name || "")}
            </div>
            <div className="w-fit text-[13px]">
                <ToolTipBox
                    align="center"
                    side="right"
                    content={isCopied ? "✅ Copied " + upperCaseAll(actionData.name) : "Copy " + upperCaseAll(actionData.name)}
                    onOpenChange={(e) => {
                        if (!e) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <span
                        className="flex cursor-pointer items-center gap-2 text-[12px]"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(upperCaseAll(actionData?.name)!);
                            setIsCopied(true);
                        }}
                    >
                        Enum
                        <CopyIcon height={12} width={12} strokeWidth={1.6} />
                    </span>
                </ToolTipBox>
            </div>
            <div className="word-wrap h-[20px] overflow-hidden text-[13px]" title={actionData.description || ""}>
                {actionData.description}
            </div>
            <div className="flex w-full items-center justify-end gap-1 pr-3">
                <If condition={!isAppPage}>
                    <Button
                        size={28}
                        variant={"plain"}
                        onClick={() => {
                            setOpenAction(actionData.name);
                        }}
                        className=" mt-[auto] w-[fit-content]  rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[4000] tracking-[.3px] text-black-200"
                    >
                        <div className="mt-[2px] text-[13px] font-[500]">Run</div>
                    </Button>
                </If>
            </div>
        </>
    );
};

ActionRow.whyDidYouRender = {
    logOnDifferentValues: true,
    customName: "Menu",
};

export default React.memo(function ActionsScreen() {
    return (
        <RightSideContent
            header={
                <div className="w-full">
                    <div>
                        <span className="min-w-[208px]">Actions</span>
                    </div>
                </div>
            }
        >
            <div className="h-full px-8 pb-10 pt-0">
                <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                    <ActionRenderer />
                </ErrorBoundary>
            </div>
        </RightSideContent>
    );
});
