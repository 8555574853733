import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { DeleteIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { deleteTriggerInstance } from "~/dataProcessor/api/api";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { Button } from "~/design-system/atom/button";
import { DialogDescription, DialogHeader, DialogTitle } from "~/design-system/atom/dialog";
import { toast } from "~/design-system/atom/use-toast";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { useTriggerInstanceData } from "../../screens/triggers/hooks";

const noop = () => {};

export const ActionButton = ({ triggerInstanceId }: { triggerInstanceId: string }) => {
    const [open, setOpen] = useState(false);
    const { refetch } = useTriggerInstanceData();

    const handleDeleteion = useMutation(deleteTriggerInstance, {
        onSuccess: () => {
            toast({
                title: "Success",
                description: "Trigger instance was deleted successfully",
            });
            setOpen(false);
            refetch();
        },
        onError: (error: Error) => {
            toast({
                title: "Error",
                description: error?.message,
                variant: "destructive",
            });
        },
    });

    const handleDelete = () => {
        handleDeleteion.mutate(triggerInstanceId);
    };

    return (
        <>
            {open && (
                <DeletePopup
                    handleDelete={handleDelete}
                    setOpen={() => {
                        setOpen(false);
                    }}
                />
            )}
            <ActionButtonBox
                setOpen={() => {
                    setOpen(true);
                }}
            />
        </>
    );
};
export const ActionButtonBox = ({ setOpen }: { setOpen: (e: boolean) => void }) => {
    return (
        <div
            onClick={() => {
                setOpen(true);
            }}
            className="cursor-pointer px-[4px]"
        >
            <Trash2Icon height={14} width={14} />
        </div>
    );
};
export const DeletePopup = ({
    setOpen,
    handleDelete,
    header = "Confirm Permanent Deletion",
    description = "Are you sure you want to permanently delete this record? Please confirm your decision.",
}: {
    setOpen: (e: boolean) => void;
    handleDelete: () => void;
    header?: string;
    description?: string;
}) => {
    return (
        <DialogBoxCenter
            open={true}
            setOpen={() => {
                setOpen(false);
            }}
            className={clsx(" max-h-[90vh] w-[580px] overflow-y-scroll bg-[#fff] px-8 py-6", scrollBarStyle)}
        >
            <DialogHeader>
                <DialogTitle className="mt-[6px] flex flex-col   leading-none">
                    <div className=" mb-3 font-avenirn text-[17px] font-[600] text-black-200">{header}</div>
                    <div className=" text-black-6000 text-[13px] font-[400] leading-[23px]">{description}</div>
                </DialogTitle>
            </DialogHeader>
            <DialogDescription className="!mt-[8px]   font-gilroy text-[13px] tracking-[.35px] text-black-300">
                <Button
                    variant="black"
                    size={40}
                    className={clsx(
                        "ml-0 h-[36px] min-w-[110px] max-w-[fit-content]",
                        css`
                            background: #dd4545;
                            border: 1px solid #b73333;
                        `,
                    )}
                    onClick={() => handleDelete()}
                >
                    <div className="items-between flex items-center gap-2">
                        Delete
                        <DeleteIcon height={14} width={14} />
                    </div>
                </Button>
            </DialogDescription>
        </DialogBoxCenter>
    );
};
