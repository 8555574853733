import { ExternalLinkIcon } from "lucide-react";

export const Link = ({
    children,
    isExternal = false,
    className,
    ...props
}: {
    children: React.ReactNode;
    className?: string;
    isExternal?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
    return (
        <a
            target={isExternal ? "_blank" : "_self"}
            className={`cursor-pointer text-[12px] font-[400] text-[#944ecd] underline hover:text-[#8123CD] ${className}`}
            {...props}
        >
            {children}
            {isExternal && <ExternalLinkIcon className="ml-[4px] mt-[-2px] inline-block h-[12px] w-[12px]" strokeWidth={1.5} />}
        </a>
    );
};
