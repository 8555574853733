import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import { ZConnectionInfoData, getConnectionInfo } from "~/dataProcessor/api/api";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { TabBarStrip } from "~/ui/components/custom/TabBarStrip";
import { RightSideContent } from "~/ui/containers/base/base";
import { LoadingState } from "~/ui/containers/base/emptyStates";
import { ActionRenderer } from "~/ui/screens/actions";
import { If } from "~/utils/reactComponent";
import { DeleteConnectionButtonV2 } from "../connectedAccountList";
import { TriggerRenderer } from "../triggers";
import { useTriggerData, useTriggerInstanceData } from "../triggers/hooks";
import { TriggerInstanceRenderer } from "../triggers/triggerPage";
import { ConnectionInfoBlock } from "./ConnectionPopOver";

export const useConnectionInfo = (connectionID: string) => {
    const { data, isSuccess, isError, isLoading, refetch } = useQuery(
        ["connection", connectionID],
        () =>
            getConnectionInfo({
                connectionID,
            }),
        {
            enabled: !!connectionID,
            staleTime: 1000 * 60 * 5,
            refetchOnMount: "always",
        },
    );

    return {
        data,
        isSuccess,
        isError,
        isLoading,
        refetch,
    };
};

export default function ConnectionScreen() {
    const router = useRouter();
    const { query } = router;
    const { data: connectorInfo } = useGetConnectorInfo(query.connectorId as string);
    const [sections, setSections] = useState<string[]>(["connection info", "actions", "triggers", "trigger instances"]);
    const { triggerData, isLoading: isTriggerLoading } = useTriggerData(connectorInfo?.appName!);
    const { triggerData: triggerInstanceData, isLoading: isTriggerInstanceLoading } = useTriggerInstanceData();

    useEffect(() => {
        if (!isTriggerLoading && !triggerData?.length) {
            setSections((prev) => prev.filter((section) => section !== "triggers"));
        }

        if (!isTriggerInstanceLoading && !triggerInstanceData?.triggers?.length) {
            setSections((prev) => prev.filter((section) => section !== "trigger instances"));
        }
    }, [isTriggerLoading, isTriggerInstanceLoading]);

    const { connectionId } = query;
    const { data: connectionInfo, isSuccess } = useConnectionInfo(query.connectionId as string);
    const [selectedSection, setSelectedSection] = useState(sections[0]);
    const hasTabSection = useRef(false);

    useEffect(() => {
        if (router.isReady && !hasTabSection.current) {
            const tab = router.query.tab as string;

            if (tab) {
                setSelectedSection(tab);
                hasTabSection.current = true;
            }
        }
    }, [router]);

    const isLoading = !isSuccess || isTriggerInstanceLoading || isTriggerLoading;

    return (
        <>
            <RightSideContent header={`Connected account`}>
                <div className="h-full px-8 pt-7 text-black-400">
                    {/* @ts-ignore */}
                    {isLoading ? (
                        <LoadingState height={window?.innerHeight - 200} text="Fetching connection..." />
                    ) : (
                        <>
                            <div className="relative mt-2">
                                <TabBarStrip
                                    sections={sections || []}
                                    selectedSection={selectedSection!}
                                    setSelectedSection={setSelectedSection}
                                />
                                <div
                                    className="absolute right-[0]"
                                    style={{
                                        top: "4px",
                                    }}
                                >
                                    <DeleteConnectionButtonV2 connectionId={connectionId as string} />
                                </div>
                            </div>
                            <If condition={selectedSection === "connection info"}>
                                <div className="h-full pt-2">
                                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                        <ConnectionInfoBlock
                                            connectionParams={(connectionInfo as unknown as any)?.connectionParams || {}}
                                            connectionId={connectionId as string}
                                            connectionInfo={connectionInfo! as z.infer<typeof ZConnectionInfoData>}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </If>
                            <If condition={selectedSection === "actions"}>
                                <div className="h-full pt-0">
                                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                        <ActionRenderer />
                                    </ErrorBoundary>
                                </div>
                            </If>
                            <If condition={selectedSection === "triggers"}>
                                <div className="h-full pt-2">
                                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                        <TriggerRenderer />
                                    </ErrorBoundary>
                                </div>
                            </If>
                            <If condition={selectedSection === "trigger instances"}>
                                <div className="h-full pt-2">
                                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                                        <TriggerInstanceRenderer />
                                    </ErrorBoundary>
                                </div>
                            </If>
                        </>
                    )}
                </div>
            </RightSideContent>
        </>
    );
}
