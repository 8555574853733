import copy from "copy-text-to-clipboard";

export function copyText(text: string, element: HTMLElement | null = null) {
    // @ts-ignore
    if (!!element) {
        element?.focus?.();
        // @ts-ignore
        element?.setSelectionRange?.(0, text.length);
    }

    return copy(text);
}

export const DEVICE = {
    IS_MOBILE: typeof window !== "undefined" && window.innerWidth < 768,
    IS_TABLET: typeof window !== "undefined" && window.innerWidth < 1024,
    IS_LARGER_THAN_TABLET: typeof window !== "undefined" && window.innerWidth > 1024,
};
