import clsx from "clsx";
import { SearchIcon } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "~/design-system/atom/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "~/design-system/atom/select";
import { If } from "~/utils/reactComponent";

export const SimpleDropdown = ({
    options = [],
    onChange,
    value,
    placeholder = "Select value",
    className = "",
    size = 32,
}: {
    options: {
        name: string;
        value: string | boolean | number;
    }[];
    onChange: (value: string | boolean | number) => void;
    value: string | boolean | number;
    className?: string;
    placeholder?: string;
    size?: 32 | 36 | 40;
    rightIcon?: React.ReactNode;
}) => {
    const selectedValue = options?.find((option) => option.value === value)?.name || placeholder;

    const [search, setSearch] = useState("");

    const showSearch = options.length > 10;
    const inputRef = useRef<HTMLInputElement>(null);

    const filteredOptions = useMemo(
        () => options.filter((option) => option?.name?.toLowerCase().includes(search.toLowerCase())),
        [search, options],
    );

    const hiddenItems = useMemo(
        () =>
            options.map((option) => ({
                ...option,
                isVisible: option?.name?.toLowerCase().includes(search.toLowerCase()),
            })),
        [search, options],
    );
    useEffect(() => {
        if (inputRef?.current) {
            inputRef?.current?.focus();
        }
    }, [filteredOptions]);

    return (
        <Select
            // @ts-ignore
            value={value}
            onValueChange={(value) => {
                onChange(value);
            }} // @ts-ignore
        >
            <SelectTrigger
                className={clsx(
                    "relativ  w-[200px]    overflow-hidden text-[13px] text-black-300",
                    `
                h-[${size}px]
            `,
                    className,
                )}
            >
                <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-left">{selectedValue}</div>
            </SelectTrigger>
            <SelectContent className="z-[200] border-grey-200 bg-white">
                {showSearch && <InputDropdown search={search} setSearch={setSearch} inputRef={inputRef} />}
                <SelectGroup>
                    {hiddenItems.map((option) => (
                        <SelectItem
                            // @ts-ignore
                            value={option.value}
                            // @ts-ignore
                            key={option.value}
                            className={clsx({
                                hidden: !option.isVisible,
                            })}
                        >
                            {option.name}
                        </SelectItem>
                    ))}
                </SelectGroup>
                <If condition={filteredOptions.length === 0}>
                    <div className="px-3 py-2 text-[13px] text-black-300">No options found</div>
                </If>
            </SelectContent>
        </Select>
    );
};

export const InputDropdown = React.memo(
    ({
        search,
        setSearch,
        inputRef,
    }: {
        search: string;
        setSearch: (search: string) => void;
        inputRef: React.RefObject<HTMLInputElement>;
    }) => {
        return (
            <div className="border-b-[.5px] border-grey-300 px-3 pb-2 pt-2">
                <Input
                    size={32}
                    placeholder="Search"
                    className="rounded-[8px] !border-grey-600"
                    value={search}
                    onChange={(e) => {
                        e.stopPropagation();
                        setSearch(e.target.value);
                        console.log("search", search);
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation(); // Prevent dropdown from closing
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    rightIcon={<SearchIcon className="h-3 w-3" strokeWidth={1.5} />}
                    ref={inputRef}
                />
            </div>
        );
    },
);
