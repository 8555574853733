import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { InfoIcon } from "lucide-react";
import * as React from "react";

import { cn } from "~/utils/cn";
import { debounce } from "~/utils/js";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            "z-[1000] overflow-hidden rounded-[10px] border border-grey-200 bg-[#fff] px-3 py-1.5 text-[12px] text-grey-300 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className,
        )}
        {...props}
    />
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export const ToolTipBox = ({
    children = <InfoIcon height={14} width={14} />,
    content,
    side = "top",
    align = "start",
    onOpenChange,
    isCopyableContent,
    className,
    sideOffset = 8,
    isDefaultOpen = false,
}: {
    children?: React.ReactNode;
    content: string | React.ReactNode;
    side?: "top" | "right" | "bottom" | "left";
    align?: "start" | "center" | "end";
    onOpenChange?: (e: boolean) => void;
    isCopyableContent?: boolean;
    className?: string;
    sideOffset?: number;
    isDefaultOpen?: boolean;
}) => {
    const [isOpen, setIsOpen] = React.useState(isDefaultOpen);
    const debouncedOnOpenChange = React.useMemo(() => debounce((value: boolean) => onOpenChange?.(value), 1000), [onOpenChange]);

    React.useEffect(() => {
        debouncedOnOpenChange(isOpen);
        return () => {};
    }, [isOpen, debouncedOnOpenChange]);
    return (
        <TooltipProvider delayDuration={200}>
            <Tooltip open={!isCopyableContent ? isOpen : undefined} onOpenChange={setIsOpen}>
                <TooltipTrigger
                    className={cn(className)}
                    // onMouseOver={() => {
                    //     setIsOpen(true);
                    // }}
                >
                    {children}
                </TooltipTrigger>
                <TooltipContent
                    sideOffset={sideOffset}
                    className="z-[1000] font-gilroy text-[13px] !font-[400] text-black-500"
                    side={side}
                    align={align}
                >
                    {content}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
