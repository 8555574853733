import { css } from "@emotion/css";
import clsx from "clsx";
import { ChevronRightIcon, CopyIcon, ExternalLinkIcon, HelpCircleIcon } from "lucide-react";
import Link from "next/link";
import React, { useMemo, useRef, useState } from "react";
import { logoutUser } from "~/dataProcessor/api/api";
import { useApiKey } from "~/dataProcessor/hooks/user";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "~/design-system/atom/dropdown-menu";
import { Input } from "~/design-system/atom/input";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { eraseCookie, getCookie, setCookie } from "~/utils/cookie";
import { copyText } from "~/utils/dom";
import { debounce } from "~/utils/js";
import { getGravatarUrl } from "~/utils/user";

export const MainContentTopBar = ({
    header,
    headerLinks,
}: {
    header?: string | React.ReactNode;
    headerLinks?: {
        link: string;
        text: string;
        icon: React.ReactNode;
    }[];
}) => {
    return (
        <div
            className={clsx(
                "sticky z-[10] flex h-[42px] min-w-[100%] items-center justify-between border-b-[.5px]  border-b-[#DBDBDB] bg-[#fff] px-6 pr-2",
                css`
                    position: sticky;
                    position: -webkit-sticky;
                    top: 0; /* required */
                    width: (100vw - 280px + 16px);
                `,
            )}
        >
            <div className="flex w-full flex-1 items-center font-avenirn text-[12.4px] font-[500] tracking-[0.3px] text-[#2c2c2c] ">
                <IconBadge icon="🧰" />
                <Link href="/" className=" font-[500] underline-offset-4 hover:underline">
                    Home
                </Link>
                {!!headerLinks?.length ? (
                    <div className="flex items-center">
                        {headerLinks.map((link) => (
                            <div key={link.text} className="flex items-center gap-1">
                                <ChevronRightIcon
                                    height={12}
                                    width={12}
                                    strokeWidth={1.2}
                                    className="ml-[2px] mr-[0px]"
                                    color="#2c2c2c80"
                                />
                                <Link href={link.link}>
                                    <div className="flex items-center gap-1 hover:underline">
                                        {link.icon}
                                        <span>{link.text}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : !!header ? (
                    <>
                        <ChevronRightIcon height={12} width={12} strokeWidth={1.2} className="mx-[2px] mr-[3px]" color="#2c2c2c80" />
                        <span>{header}</span>
                    </>
                ) : null}
            </div>
            <div className="absolute left-[50%] top-[6px] flex translate-x-[-50%] items-center"></div>
            <div className="ml-[6px] mr-4 flex items-center ">
                <DocsAndSupport />
                <ProfileBadge />
            </div>
        </div>
    );
};

export const APIKeyBadge = () => {
    const apiKey = useApiKey();
    const [isCopied, setIsCopied] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    const [isMasked, setIsMasked] = useState(true);
    const debouncedSetIsMasked = useMemo(() => debounce((value: boolean) => setIsMasked(value), 100), []);

    return (
        <Input
            ref={ref}
            onMouseOver={() => debouncedSetIsMasked(false)}
            onMouseOut={() => debouncedSetIsMasked(true)}
            icon={
                <div
                    className={clsx(
                        "!ml-[-16px] mr-1 flex h-[30px] w-[66px] items-center justify-center overflow-hidden border-[.5px] border-r-[#D8D8D8] bg-[#fff] pt-[1px] text-center text-[12px] font-[500]",
                        apiKey ? "opacity-100" : "opacity-0",
                        css`
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                            color: #000;
                        `,
                    )}
                >
                    🔑 API Key
                </div>
            }
            value={isMasked ? "*********" : apiKey || ""}
            rightIcon={
                <ToolTipBox sideOffset={20} content={isCopied ? "Copied to clipboard ✓" : "Copy API key"} align="center" side="bottom">
                    <div
                        className={clsx(
                            "!important rounded-4px absolute right-[-4px] top-[-4.5px] flex flex h-[30px] w-[30px] cursor-pointer items-center items-center justify-center justify-center border-[.5px] border-l-[#D8D8D8] bg-[#fff] text-[#fff]",
                            apiKey ? "opacity-100" : "opacity-0",
                            css`
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                            `,
                        )}
                        onClick={() => {
                            copyText(apiKey || "");
                            setIsCopied(true);
                            setTimeout(() => {
                                setIsCopied(false);
                            }, 1000);

                            // select the text
                            ref.current?.select();
                        }}
                    >
                        <CopyIcon height={12} width={12} strokeWidth={1.4} className="" color="#000" />
                    </div>{" "}
                </ToolTipBox>
            }
            placeholder="API key"
            height={32}
            className={clsx(
                "mr-2 h-[30px] w-[180px] overflow-hidden rounded-[10px] pl-[72px]",
                css`
                    text-align: ${isMasked ? "center" : "left"};
                    ${isMasked &&
                    `
                                padding-top: 19px;
                                font-size: 15px;
                                padding-left: 48px !important;
                            `}
                `,
            )}
        />
    );
};

export const IconBadge = ({ icon }: { icon: string }) => {
    return (
        <div
            aria-hidden="true"
            className={css`
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                word-break: keep-all;
                font-family: var(--font-emoji);
                width: 20px;
                height: 20px;
                background-color: rgba(90, 92, 95, 0.02);
                margin-right: 8px;
                padding: 3px;
                font-size: 9px !important;
                border: 0.5px solid #8f8f8f6b;
                border-radius: 6px;
            `}
            data-type="emoji"
        >
            {icon}
        </div>
    );
};

export const DocsAndSupport = ({ height }: { height?: string }) => {
    return (
        <>
            <APIKeyBadge />
            <Link href="https://docs.composio.dev" target="_blank">
                <div
                    className={clsx(
                        " mr-1 flex items-center gap-[6px] pr-4 text-[13px] hover:underline",
                        linkBox({
                            height: height || "30px",
                        }),
                        docsLink,
                    )}
                >
                    <span className="mt-[2.2px]">Docs</span>
                    <ExternalLinkIcon color="#fff" height={13} width={13} strokeWidth={1.8} />
                </div>
            </Link>
            <Link href="https://dub.composio.dev/discord" target="_blank">
                <div
                    className={clsx(
                        "ml-1 mr-1 flex items-center gap-[6px] pr-4 text-[13px] hover:underline",
                        linkBox({
                            height: height || "28px",
                        }),
                    )}
                >
                    <HelpCircleIcon color="#404040" height={14} width={14} strokeWidth={1.8} />
                    <span className="mt-[1.5px]">Support</span>
                </div>
            </Link>
        </>
    );
};

const linkBox = (props: { height?: string }) => css`
    background: #f0f0f036;
    padding: 1px ${props.height ? "12px" : "8px"};
    height: ${props.height || "30px"};

    border: 0.5px solid #cacaca;
    color: #404040;
    border-radius: 8px;
    box-shadow:
        lch(0 0 0 / 0.02) 0px 4px 4px -1px,
        lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
    :hover {
        background: #f4f4f4;

        box-shadow:
            lch(0 0 0 / 0.05) 0px 4px 4px -1px,
            lch(0 0 0 / 0.05) 0px 1px 1px 0px !important;
    }
    cursor: default !important;
    transition: all 0.25s ease-in-out;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-family: "Gilroy", sans-serif;
`;

const docsLink = css`
    background: #1e1e1e !important;
    border: 0.5px solid #8f8f8f6b;
    color: #ececec !important;
    box-shadow:
        lch(0 0 0 / 0.02) 0px 4px 4px -1px,
        lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
    :hover {
        background: #292829 !important;
        box-shadow:
            lch(0 0 0 / 0.15) 0px 4px 4px -1px,
            lch(0 0 0 / 0.15) 0px 1px 1px 0px !important;
    }
    transition: all 0.25s ease-in-out;
    cursor: default !important;
    font-weight: 500;
`;

export const ProfileBadge = () => {
    // TODO: Add logout button
    const gravatarURL = useMemo(() => getGravatarUrl(), []);

    const useEmail = useMemo(() => {
        return getCookie("composio_email");
    }, []);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                {" "}
                <img
                    height={32}
                    width={32}
                    src={gravatarURL}
                    alt="Gravatar"
                    className={clsx(
                        " h-[24px] min-h-[24px] w-[24px] min-w-[24px] rounded-full opacity-[90] hover:opacity-70",
                        css`
                            border: 1px solid #3d3d3d;
                            box-shadow:
                                lch(0 0 0 / 0.02) 0px 4px 4px -1px,
                                lch(0 0 0 / 0.03) 0px 1px 1px 0px !important;
                        `,
                    )}
                />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-4" sideOffset={20}>
                <DropdownMenuLabel className="pr-4 !text-[11px] font-[400]  text-black-300">{useEmail}</DropdownMenuLabel>
                <DropdownMenuSeparator className="mx-2 bg-[#C7C7C7]" />
                <Link href="https://dub.composio.dev/discord" target="_blank">
                    <DropdownMenuItem>Join discord</DropdownMenuItem>
                </Link>
                <DropdownMenuItem
                    onClick={() => {
                        onLogout();
                    }}
                >
                    Logout
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export const onLogout = () => {
    const { location } = window;
    const { hostname, protocol } = location;

    logoutUser().finally(() => {
        setCookie("isLoggedIn", "false", 0);
        eraseCookie("composio_analytics_id");
        eraseCookie("composio_email");

        const logoutURLs = {
            localhost: "/",
            "qa.composio.dev": "https://qa.composio.dev/logout",
            "composio.dev": "https://composio.dev/logout",
        };

        window.location.href = logoutURLs[hostname as keyof typeof logoutURLs] || `${protocol}//${hostname}`;
    });
};

export const RightSideContent = ({
    header,
    headerLinks,
    children,
    showTopBar = true,
    className,
}: {
    header?: string | React.ReactNode;
    children: React.ReactNode;
    showTopBar?: boolean;
    className?: string;
    headerLinks?: {
        link: string;
        text: string;
        icon: React.ReactNode;
    }[];
}) => {
    return (
        <div
            className={clsx(
                " ml-[264px]  ",
                scrollBarStyle,
                css`
                    width: calc(100vw - 280px + 16px);
                    background: #fff;
                    margin-top: 10px;
                    position: fixed;
                    height: calc(100vh - 12px);
                    border-left: 1px solid #ebebeb;
                    border-top: 1px solid #ebebeb;

                    border-left: 0.5px solid #d1cfcf;
                    border-top: 0.5px solid #d1cfcf;

                    border-radius: 6px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    overflow-y: auto;

                    box-shadow:
                        lch(0 0 0 / 0.01) 0px 4px 4px 0px,
                        lch(0 0 0 / 0.02) 0px 4px 4px 0px !important;
                `,
                className,
            )}
            id="scroll-box"
        >
            {showTopBar && <MainContentTopBar header={header} headerLinks={headerLinks} />}
            {children}
        </div>
    );
};
