import * as SDKClient from "./services.gen";

export default {
    logs: SDKClient.LogsService,
    actionsV1: SDKClient.ActionsService,
    actionsV2: SDKClient.ActionsService,
    apiKeys: SDKClient.ApiKeysService,
    clientAuthService: SDKClient.AuthService,
    appConnector: SDKClient.IntegrationsService,

    app: SDKClient.AppsService,
    appLogin: SDKClient.LoginService,

    connections: SDKClient.ConnectionsService,
    metadata: SDKClient.MetadataService,
    team: SDKClient.TeamService,
    triggers: SDKClient.TriggersService,
    analytics: SDKClient.AnalyticsService,
    eventLogs: SDKClient.EventLogsService,
};
