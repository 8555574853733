import * as Sentry from "@sentry/nextjs";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import axios from "axios";
import posthog from "posthog-js";
import React from "react";
import { client } from "~/client/services.gen";
import { getCookie } from "~/utils/cookie";
import { axiosInstance } from "./dataProcessor/api/global";
import { BACKEND_URL } from "./utils/baseUrl";

export const switchProjectAction = (projectId: string) => {
    //@ts-ignore
    client.setConfig({
        baseURL: BACKEND_URL,
        withCredentials: true,
        headers: {
            "x-composio-project-id": projectId,
        },
    });

    axiosInstance.defaults.headers.common["x-composio-project-id"] = projectId;
    axios.defaults.headers.common["x-composio-project-id"] = projectId;
};
export async function bootstrap() {
    client.setConfig({
        baseURL: BACKEND_URL,
        withCredentials: true,
        //  headers: {
        //     "x-composio-project-id": "346de1e9-47eb-4df7-99c8-227298b42a7a",
        // },
    });

    const IS_DEV = process.env.NODE_ENV === "development";

    if (IS_DEV) {
        whyDidYouRender(React, {
            trackAllPureComponents: true,
        });
    }

    if (typeof window !== "undefined") {
        const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
        if (!posthogKey) {
            return;
        }
        //@ts-ignore
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
            api_host: `${window.location.origin}/ingest`,
            ui_host: "https://app.posthog.com",
            //@ts-ignore
            segment: window?.analytics, // Pass window.analytics here - NOTE: `window.` is important
            capture_pageview: false, // You want this false if you are going to use segment's `analytics.page()` for pageviews
            // When the posthog library has loaded, call `analytics.page()` explicitly.
            //@ts-ignore
            loaded: (posthog) => {
                if (process.env.NODE_ENV === "development") posthog.debug();

                //@ts-ignore
                return window?.analytics?.page();
            },
            persistence: "cookie",
        });

        const user = {
            email: getCookie("composio_email") as string | undefined,
        };

        Sentry.setUser(user);

        Sentry.init({
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            tracesSampleRate: 1.0,
        });
    }
}
