import { atom } from "jotai";

export const openActionAction = atom<string | null>(null);

export const openConnectorSetupAtom = atom(false);
export const DEFAULT_CONNECTOR_SETUP_CONFIG = {
    name: "",
    usingComposioAuth: true,
    selectedAuthScheme: null,
    // this should only be set if useComposioAuth is false
    connectorConfiguration: {},
    isUpdating: false,
    isAdvancedMode: false,
};

export const connectorSetupConfigAtom = atom<{
    name: string;
    usingComposioAuth: boolean;
    selectedAuthScheme: string | null;

    // this should only be set if useComposioAuth is false
    connectorConfiguration: Record<string, any>;
    isUpdating: boolean;
    isAdvancedMode: boolean;
}>(DEFAULT_CONNECTOR_SETUP_CONFIG);
