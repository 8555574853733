export const APP_CATEGORIES_LIST = [
    {
        title: "All",
        key: "all",
    },
    {
        title: "Your custom tools",
        key: "custom",
    },
    {
        title: "productivity",
        key: "productivity",
    },
    {
        title: "local",
        key: "local",
    },
    {
        title: "ticketing",
        key: "ticketing",
    },
    {
        title: "marketing",
        key: "marketing",
    },
    {
        title: "dev-tools",
        key: "dev-tools",
    },
    {
        title: "support",
        key: "support",
    },
    {
        title: "social",
        key: "social",
    },
    {
        title: "surveys",
        key: "surveys",
    },
    {
        title: "popular",
        key: "popular",
    },
    {
        title: "knowledge-base",
        key: "knowledge-base",
    },
    {
        title: "project management",
        key: "project management",
    },
    {
        title: "design",
        key: "design",
    },
    {
        title: "crm",
        key: "crm",
    },
    {
        title: "gaming",
        key: "gaming",
    },
    {
        title: "collaboration",
        key: "collaboration",
    },
    {
        title: "analytics",
        key: "analytics",
    },
];
