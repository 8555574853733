import axios from "axios";
import { BACKEND_URL } from "~/utils/baseUrl";

export const createConnector = (appKey: string, data: Record<string, unknown>) => {
    return axios
        .post(`${BACKEND_URL}/api/v1/integrations`, data, {
            withCredentials: true,
        })
        .then((res) => res.data);
};

export const updateConnector = (
    connectorId: string,
    data: {
        authConfig: any;
    },
) => {
    const { authConfig } = data;

    return axios
        .patch(
            `${BACKEND_URL}/api/v1/integrations/${connectorId}`,
            { authConfig },
            {
                withCredentials: true,
            },
        )
        .then((res) => res.data);
};

export const createConnectorPayload = ({
    appId,
    authSchemeType,
    useComposioAuth,
    forceNewIntegration,
    authConfig,
    name,
}: {
    appId: string;
    useComposioAuth: boolean;
    forceNewIntegration: boolean;
    authSchemeType: string;
    authConfig: Record<string, any>;
    name: string;
}) => {
    if (useComposioAuth) {
        return {
            name,
            appId,
            useComposioAuth: true,
            authConfig: Object.entries(authConfig).reduce((acc, [key, field]) => {
                // @ts-ignore
                acc[key] = field.value;
                return acc;
            }, {}),
            forceNewIntegration,
        };
    }

    return {
        name,
        appId,
        authScheme: authSchemeType,
        forceNewIntegration,
        authConfig: Object.entries(authConfig).reduce((acc, [key, field]) => {
            // @ts-ignore
            acc[key] = field.value;
            return acc;
        }, {}),
    };
};
