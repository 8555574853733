import clsx from "clsx";
import { CodeIcon } from "lucide-react";
import React, { useState } from "react";
import { CodeBlockComponent } from "~/design-system/atom/CodeBlockComponent";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { DialogDescription, DialogHeader, DialogTitle } from "~/design-system/atom/dialog";
import { upperCaseFirstLetter } from "~/utils/string";
import { scrollBarStyle } from "../constants/style/common";
import { SelectorButton } from "../screens/appScreen/modules/addConnectedAccount";

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "text",
                content: <div className="text-[13px] font-[500]">Create a connection for a user using our Python SDK</div>,
            },
            {
                type: "blockWizard",
                content: {
                    heading: "Code for creating a connection for a user",
                    blocks: [
                        {
                            heading: "Code for creating a connection for a user",
                            text: `
from composio import ComposioToolSet, App

def get_integration(integration_id):
    toolset = ComposioToolSet(api_key={{api_key}})
    integration = toolset.get_integration(id=integration_id)
    return integration

# Example usage
integration_id = {{integration_id}}
integration = get_integration(integration_id)
print(integration)
`,
                            language: "python",
                        },
                    ],
                },
            },
        ],
        javascript: [
            {
                type: "text",
                content: <div className="text-[13px] font-[500]">Get integration using Javascript SDK</div>,
            },
            {
                type: "blockWizard",
                content: {
                    heading: "Code for creating a connection for a user",
                    blocks: [
                        {
                            heading: "Code for creating a connection for a user",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const integration = await composio.integrations.get({
    integrationId: {{integration_id}}
})
`,
                            language: "javascript",
                        },
                    ],
                },
            },
        ],
    },
};

export const languageLogoMap = {
    python: {
        logo: "/assets/icon/python.svg",
        logoSize: 28,
        label: "Python",
    },
    javascript: {
        logo: "/assets/icon/js.svg",
        logoSize: 14,
        label: "JavaScript",
    },
};

export const CodeWithInstructionBlock = ({ data }: { data: typeof sampleCodeBlockData }) => {
    const options = Object.keys(data?.content || {});
    const [selectedOption, setSelectedOption] = useState(options[0]);

    // @ts-ignore
    const blocksToRender = data?.content[selectedOption] || [];

    return (
        <>
            <div className="flex gap-2">
                {options.map((option) => (
                    <SelectorButton
                        key={option}
                        isSelected={selectedOption === option}
                        setSelected={() => setSelectedOption(option)}
                        name={option}
                        logo={languageLogoMap[option as "python" | "javascript"].logo}
                        logoSize={languageLogoMap[option as "python" | "javascript"].logoSize}
                    />
                ))}
            </div>
            <div className="mt-4">
                {blocksToRender.map((block: Record<string, any>, index: number) => {
                    const { type, content } = block;

                    if (type === "blockWizard") {
                        // @ts-ignore
                        return (
                            <CodeBlockComponent
                                key={index}
                                // @ts-ignore
                                topBarContent={`${upperCaseFirstLetter(selectedOption)} Code`}
                                {...content}
                            />
                        );
                    }

                    return (
                        <div key={index} className="">
                            {content}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export const CodeWInstructionModal = ({
    setOpen,
    title = "Show Integration Code",
    data = sampleCodeBlockData,
}: {
    setOpen: (e: boolean) => void;
    title?: string;
    data?: typeof sampleCodeBlockData;
}) => {
    return (
        <DialogBoxCenter className="min-w-[700px] overflow-hidden bg-[#fafafa] px-0 pt-0" open={true} setOpen={setOpen}>
            <DialogHeader className="bg-[#fafafa] px-4">
                <DialogTitle className="flex items-center gap-2 px-4 text-[14.4px] font-[500]">
                    <CodeIcon height={18} width={18} />
                    {title}
                </DialogTitle>
            </DialogHeader>
            <DialogDescription className={clsx("mt-6 flex max-h-[80vh] flex-col gap-5 overflow-y-auto bg-[#fff] px-7", scrollBarStyle)}>
                <CodeWithInstructionBlock data={data} />
            </DialogDescription>
        </DialogBoxCenter>
    );
};

export const TabButton = ({ children, onClick, isSelected }: { children: React.ReactNode; onClick: () => void; isSelected: boolean }) => {
    return (
        <div
            className={clsx(
                "flex h-[30px] min-w-[60px] cursor-pointer items-center justify-center gap-2 rounded-[32px] border-[.5px] px-2 py-1 text-[12px] text-black-400",
                isSelected ? "border-[#b043ff] bg-[#b043ff38]" : "border-[#cacaca] bg-white hover:border-[#b043ff] hover:bg-[#b043ff38]",
            )}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
