import { css } from "@emotion/css";
import clsx from "clsx";
import { ArrowRightIcon } from "lucide-react";
import React, { useState } from "react";
import { DialogBoxSheet } from "~/design-system/atom/alert-dialog-sheet";
import { DialogHeader } from "~/design-system/atom/dialog";
import { Skeleton } from "~/design-system/atom/skeleton";
import { useAppInfo } from "../appScreen";

export const WizardBox = ({
    children,
    setOpen,
    currentStep,
    title,
    appName,
}: {
    children: React.ReactNode;
    setOpen: (e: boolean) => void;
    currentStep: number;
    title: string;
    appName: string;
}) => {
    const { appInfo } = useAppInfo(appName);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <DialogBoxSheet
            open={true}
            isAnimating={true}
            setOpen={() => {
                setOpen?.(false);
            }}
            className={clsx(
                "flex h-[90vh] min-h-[92vh] min-w-[85vw] flex-col justify-start !space-y-0 overflow-y-scroll !rounded-[40px] border-grey-600 bg-[#fff] px-0 py-0",
                css`
                    gap: 0px;
                `,
            )}
        >
            <DialogHeader
                className="bg-[#fff] !px-10 py-5 !pt-6"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="gap- flex items-center gap-[2px] font-cera text-[16px] font-[500] leading-none text-black-400">
                    <div>
                        <Skeleton className={clsx("mr-2 h-7 w-7 rounded-[8px] border-[1px]", isLoaded ? "hidden" : "block")} />
                        <img
                            src={appInfo?.logo}
                            alt={appName}
                            className={clsx("mr-2 h-7 w-7 rounded-[8px] border-[1px]", isLoaded ? "block" : "hidden")}
                            onLoad={() => setIsLoaded(true)}
                        />
                    </div>
                    {title}
                </div>
                <div className="!mt-4 flex items-center gap-2 text-[13px] tracking-[.3px]">
                    <div className="text-[13px] font-normal leading-none text-gray-800">
                        Step {currentStep}/{3} :
                    </div>
                    <div className={`font-normal leading-none ${currentStep === 1 ? "text-gray-800" : "text-gray-500"}`}>
                        Configure Integration
                    </div>
                    <ArrowRightIcon className="h-3 w-3 text-gray-500" />
                    <div className={`font-normal leading-none ${currentStep === 2 ? "text-gray-800" : "text-gray-500"}`}>
                        {" "}
                        Connect an account
                    </div>
                    <ArrowRightIcon className="h-3 w-3 text-gray-500" />
                    <div className={`font-normal leading-none ${currentStep === 3 ? "text-gray-800" : "text-gray-500"}`}>
                        {" "}
                        Execute tools
                    </div>
                </div>
            </DialogHeader>
            {children}
        </DialogBoxSheet>
    );
};
