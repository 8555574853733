import { css } from "@emotion/css";
import clsx from "clsx";
import { useAtom } from "jotai";
import { BookAIcon, CodeIcon, MoveLeftIcon, MoveRightIcon, PencilIcon, SaveIcon } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { CodeBlockComponent } from "~/design-system/atom/CodeBlockComponent";
import { Input } from "~/design-system/atom/input";
import { Link } from "~/design-system/atom/link";
import { Button } from "~/design-system/atom/newButton";
import { RadioGroup, RadioGroupItem } from "~/design-system/atom/radio-group";
import { Switch } from "~/design-system/atom/switch";
import { useToast } from "~/design-system/atom/use-toast";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { If } from "~/utils/reactComponent";
import { upperCaseFirstLetter } from "~/utils/string";
import { SelectorButton } from "../addConnectedAccount";
import { updateWizardFlowAtom } from "../wizard";
import { connectorSetupConfigAtom } from "./atom";
import { useSetupFormWizard } from "./hook";
import { FAQOptions } from "./ui/container/FAQOptions";
import { FormFieldRenderer } from "./ui/field";

export const CreateIntegrationPopover = ({ appKey }: { appKey: string }) => {
    const [, setWizardFlow] = useAtom(updateWizardFlowAtom);

    const onStatusChange = (status: { integrationId: string | undefined | null; status: string }) => {
        if (status.integrationId) {
            setWizardFlow({
                step: 2,
                data: {
                    second: {
                        integrationID: status.integrationId as string,
                    },
                },
            });
        }
    };

    const [showIntegrationCreationCode, setShowIntegrationCreationCode] = useState(false);

    return (
        <>
            <div
                className={clsx(
                    "grid  h-full grid-cols-7 border-t-[#64646434] py-[0px] font-gilroy text-[13px] text-[#ecececa2]",
                    scrollBarStyle,
                )}
            >
                <div className="col-span-4 pb-5 pl-11 pr-8 pt-5">
                    <SetupFormWizard appKey={appKey} afterConnectorBEChange={onStatusChange} />
                </div>
                <div className="col-span-3 border-l-[0.5px] border-l-[#c6c6c6] bg-[#fafafa] px-4 pt-5">
                    <If condition={showIntegrationCreationCode}>
                        <div
                            className="mb-4 flex items-center gap-2 text-[13px] text-black-400 underline"
                            onClick={() => setShowIntegrationCreationCode(false)}
                        >
                            <MoveLeftIcon height={14} width={14} strokeWidth={1.2} className="cursor-pointer" />
                            Go back
                        </div>
                        <CodeBlockSection setShowIntegrationCreationCode={setShowIntegrationCreationCode} appKey={appKey} />
                    </If>
                    <If condition={!showIntegrationCreationCode}>
                        <div className="ml-3">
                            <TabButton onClick={() => setShowIntegrationCreationCode(true)}>
                                <div className="flex items-center gap-2">
                                    <CodeIcon height={14} width={14} strokeWidth={1.5} />
                                    Create thru code
                                </div>
                            </TabButton>
                        </div>
                        <FAQOptions />
                    </If>
                </div>
            </div>
        </>
    );
};

export const TabButton = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => {
    return (
        <div
            className="mb-4 flex w-fit cursor-pointer items-center gap-2 rounded-[13px] border-[.5px] border-[#cacaca] bg-white px-3 py-[3px] text-[12px] text-black-400"
            onClick={onClick}
        >
            {children}
        </div>
    );
};

const createIntegrationSnippets = {
    javascript: [
        {
            heading: `Create Integration`,
            text: `import { Composio } from "composio-core";
// Initialize the Composio client
const composio = new Composio({{api_key}});

const app = await composio.apps.get({appKey: {{appId}}})

// Create a new integration
const integration = await composio.integrations.create({
    appId: app.appId,authConfig: {{authConfig}},
    authScheme: {{authScheme}},
    forceNewIntegration: true,
    name: {{name}},
    useComposioAuth: {{useComposioAuth}},
});
`,
            language: "javascript",
        },
    ],
    python: [
        {
            heading: `Create Integration`,
            text: `from composio import Composio

# Initialize the Composio client
composio = Composio(api_key={{api_key}})
app = composio.apps.get(name={{appId}})
# Create a new integration
integration = composio.integrations.create(
    app_id=app.appId,auth_config={{authConfig}},
    auth_mode={{authScheme}},
    force_new_integration=True,
    name={{name}},
    use_composio_auth={{useComposioAuth}}
)
`,
            language: "python",
        },
    ],
};

export const CodeBlockSection = ({ appKey }: { setShowIntegrationCreationCode: (value: boolean) => void; appKey: string }) => {
    const apiKey = useApiKey();
    const [connectorSetupConfig] = useAtom(connectorSetupConfigAtom);
    const [selectedLanguage, setSelectedLanguage] = useState<"python" | "javascript">("python");

    const codeSnippets = createIntegrationSnippets[selectedLanguage];

    const transformedCodeSnippets = useMemo(() => {
        const name = connectorSetupConfig?.name;
        const usingComposioAuth = connectorSetupConfig?.usingComposioAuth as boolean;
        let configApartFromScopes = {};

        for (const [key, value] of Object.entries(connectorSetupConfig?.connectorConfiguration || {})
            .filter(([key, value]) => !value.expected_from_customer)
            .filter(([key]) => key === "scopes" || !usingComposioAuth)) {
            // @ts-ignore
            configApartFromScopes = { ...configApartFromScopes, [key]: value.value };
        }

        const isPython = selectedLanguage === "python";

        const authScheme = connectorSetupConfig?.selectedAuthScheme;

        return codeSnippets?.map((snippet) => {
            const authConfig =
                Object.keys(configApartFromScopes).length > 0
                    ? `${isPython ? "\n    auth_config =" : "authConfig:"} ${JSON.stringify(configApartFromScopes)},`
                    : "";

            const text = snippet.text
                .replaceAll("{{name}}", `"${name}"`)
                .replaceAll("{{api_key}}", `"${apiKey}"`)
                .replaceAll("{{appId}}", `"${appKey}"`)
                .replaceAll("authConfig: {{authConfig}},", authConfig)
                .replaceAll("auth_config={{authConfig}},", authConfig)
                .replaceAll("{{authScheme}}", `"${authScheme}"`)
                .replaceAll("{{useComposioAuth}}", usingComposioAuth ? (isPython ? "True" : "true") : isPython ? "False" : "false");

            return {
                ...snippet,
                text: text,
                language: selectedLanguage,
            };
        });
    }, [codeSnippets, selectedLanguage, connectorSetupConfig]);

    return (
        <>
            <div className="mb-4 flex items-center gap-2">
                {["python", "javascript"].map((language, index) => (
                    <SelectorButton
                        logo={language === "python" ? "/assets/icon/python.svg" : "/assets/icon/js.svg"}
                        logoSize={language === "python" ? 28 : 14}
                        name={language}
                        setSelected={(name) => setSelectedLanguage(name as "python" | "javascript")}
                        isSelected={selectedLanguage === language}
                        key={index}
                    />
                ))}
            </div>
            <CodeBlockComponent topBarContent={"Code for creating a connection for a user"} blocks={transformedCodeSnippets} />
        </>
    );
};

export const EditableInput = ({ value, onChange }: { value: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className="flex h-[40px] w-[240px] items-center">
            {isEditing ? (
                <Input
                    className="ml-[-8px] mt-[0px] h-full w-[fit-content] w-full min-w-[120px] flex-auto gap-1.5 rounded-[8px] pl-[8px] font-[400]"
                    type="text"
                    placeholder="Enter name"
                    size={36}
                    value={value}
                    onEnterPress={() => setIsEditing(false)}
                    onChange={onChange}
                    onBlur={() => setIsEditing(false)}
                    autoFocus
                    rightIcon={<SaveIcon height={11} width={11} onClick={() => setIsEditing(true)} className="cursor-pointer" />}
                />
            ) : (
                <div className="flex h-full w-[240px] w-full cursor-pointer items-center gap-2 pt-[6px]" onClick={() => setIsEditing(true)}>
                    <div className="h-[19px] max-w-fit flex-grow border-b border-dashed border-gray-400 text-[13px] font-[400] underline-offset-[14px]">
                        {value}
                    </div>
                    <PencilIcon height={11} width={11} className="mt-[-4px] cursor-pointer" />
                </div>
            )}
        </div>
    );
};

export const labelForAuthScheme = (authScheme: string) => {
    const labels: Record<string, string> = {
        OAUTH2: "OAuth2",
        OAUTH1: "OAuth1",
        API_KEY: "API Key",
        BASIC_AUTH: "Basic Auth",
        BASIC_WITH_JWT: "Basic Auth with JWT",
        BEARER_TOKEN: "Bearer Token", // verify once
    };

    return labels[authScheme] || authScheme;
};

export const SetupFormWizard = ({
    appKey,
    connectorId,
    initAuthSchemeType,
    initAuthConfig,
    afterConnectorBEChange,
}: {
    appKey: string;
    connectorId?: string;
    initAuthSchemeType?: any;
    initAuthConfig?: any;
    afterConnectorBEChange?: (status: { integrationId: string | undefined | null; status: string }) => void;
}) => {
    const {
        updateInBackend: updateForm,
        setupConfig: {
            composioAuth: { value: usingComposioAuth, setValue: setUseComposioAuth },
            state: { isUpdating, isLoading },
            formFields,
            schemes,
            isComposioAuthAvailable,
            isExistingConnector,
            authSchemeType: { value: selectedAuthScheme, setValue: setAuthSchemeType },
            formState: { value: formState, setValue: setFormState },
        },
    } = useSetupFormWizard({
        appKey,
        connectorId,
        initAuthSchemeType,
        initAuthConfig,
        afterConnectorBEChange,
    });

    const authSchemeDescription = useMemo(() => {
        const descriptions: Record<string, string> = {
            OAUTH2: "OAuth2 - Secure, user-friendly login process for seamless authentication",
            OAUTH1: "OAuth 1.0a: Same as OAuth 2.0, but with more legacy support",
            API_KEY: "API keys: Your users will need to provide API keys",
            BASIC_AUTH: "Basic Auth: Username/password combo, secure over HTTPS.",
        };

        return schemes.map((scheme) => {
            return descriptions[scheme];
        });
    }, [schemes]);

    const scopeFormFields = formFields
        // @ts-ignore
        ?.filter((field: any) => field.name === "scopes" || field.name === "user_scopes")
        .map((field: any) => {
            return {
                ...field,
                label: (
                    <div className="flex items-center">
                        <span>Scopes</span>
                        <Link isExternal={true} target="_blank" className="ml-2" href="https://oauth.net/2/scope/">
                            Info
                        </Link>
                    </div>
                ),
                description: "Permissions to request while connecting a new account",
            };
        });

    const { toast } = useToast();

    // @ts-ignore
    const formFieldsWithoutScopes = formFields?.filter((field: any) => field.name !== "scopes" && field.name !== "user_scopes");
    const expectedInputFieldFromCustomer = formFieldsWithoutScopes?.filter((field: any) => field.expected_from_customer);
    const fieldsToEnterInIntegration = formFieldsWithoutScopes?.filter((field: any) => !field.expected_from_customer);

    if (isLoading) {
        return <div className="mt-4 text-[13px] font-[500] leading-none text-black-400">Loading...</div>;
    }

    return (
        <>
            <div className={clsx("flex w-full flex-col gap-9", isExistingConnector ? "pt-2" : "")}>
                <If condition={!isExistingConnector}>
                    <div className=" font-cera text-[15px] font-[400] leading-none text-black-400">
                        <EditableInput
                            value={formState.name}
                            onChange={(e) => setFormState((prev) => ({ ...prev, name: e.target.value }))}
                        />
                    </div>
                </If>
                <If condition={!connectorId}>
                    <div className="mt-[-8px] flex flex-col items-start justify-between gap-5">
                        <If condition={schemes.length === 1}>
                            <div className="flex w-full justify-between gap-2">
                                <div>
                                    <div className="font-cera text-[15px] font-[500] leading-none text-black-400">
                                        Way of authentication:
                                    </div>
                                    <div className="mt-2 text-left font-gilroy text-[12px] font-[400] text-black-800">
                                        {authSchemeDescription[0]}
                                    </div>
                                </div>
                                <div>
                                    <div className="font-cera text-[15px] font-[500] leading-none text-black-400">
                                        {labelForAuthScheme(schemes[0])}
                                    </div>
                                </div>
                            </div>
                        </If>
                        <If condition={schemes.length > 1}>
                            <div>
                                <div className="font-cera text-[15px] font-[500] leading-none text-black-400">
                                    Choose how to authenticate users
                                </div>
                                <div className=" mt-5 w-full">
                                    <RadioGroup
                                        value={selectedAuthScheme || undefined}
                                        onValueChange={setAuthSchemeType}
                                        className="grid w-full grid-cols-2 gap-4"
                                    >
                                        {schemes.map((scheme, index) => (
                                            <div key={scheme} className="flex items-start space-x-2">
                                                <RadioGroupItem value={scheme} id={`scheme-${scheme}`} />
                                                <div>
                                                    <label
                                                        htmlFor={`scheme-${scheme}`}
                                                        className="font-cera text-[15px] font-[500] leading-none text-black-400"
                                                    >
                                                        {labelForAuthScheme(scheme)}
                                                    </label>
                                                    <div className="mt-[3px] text-left font-gilroy text-[12px] font-[400] text-black-800">
                                                        {authSchemeDescription[index]}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </div>
                            </div>
                        </If>
                    </div>
                </If>
                <If condition={scopeFormFields?.length > 0}>
                    <div>
                        {scopeFormFields?.map((field: any) => (
                            <ScopeFormFieldRenderer
                                key={field.name}
                                layout="horizontal"
                                field={field}
                                label={field.label}
                                formState={formState}
                                setFormState={setFormState}
                                isExistingConnector={isExistingConnector}
                                usingComposioAuth={usingComposioAuth}
                            />
                        ))}
                    </div>
                </If>
                <If condition={!isExistingConnector && fieldsToEnterInIntegration?.length > 0}>
                    <div className="0 flex items-start justify-between font-cera text-[15px] font-[500] leading-none text-black-400">
                        <div>
                            <div className="flex items-center gap-2 ">
                                Use your own Developer App{" "}
                                <Link
                                    isExternal={true}
                                    target="_blank"
                                    className="mt-[2px]"
                                    href="https://www.youtube.com/watch?v=KT8ybowdyr0"
                                >
                                    Info
                                </Link>
                            </div>
                            <div className={clsx("mt-[10px] text-left font-gilroy text-[13px] font-[400] leading-[1.8] text-black-800")}>
                                For advanced users
                            </div>
                        </div>
                        <Switch
                            disabled={!isComposioAuthAvailable}
                            checked={!usingComposioAuth}
                            checkedColor="#8123CD"
                            className={clsx(
                                "h-[24px] w-[48px]",
                                css`
                                    span {
                                        width: 16px;
                                        height: 16px;
                                    }
                                    #switch-thumb {
                                        width: 16px;
                                        height: 16px;
                                        &[data-state="checked"] {
                                            transform: translateX(22px) !important;
                                        }
                                    }
                                `,
                            )}
                            onCheckedChange={(e) => {
                                setUseComposioAuth(!e);
                            }}
                        />
                    </div>
                </If>
            </div>
            <If condition={expectedInputFieldFromCustomer?.length > 0}>
                <div className="mt-5 flex flex-col items-start gap-3 rounded-[12px] border border-[#62a3b8] bg-[#f4fcff] p-4 py-4 text-[13px]  font-[400] leading-none text-black-400">
                    <div className="min-w-[fit-content] font-cera text-[14px] font-[500]">
                        Your users will need to provide following for creating a new connection
                    </div>
                    <div className="min-w-[fit-content] text-[13px] font-[400]">
                        {expectedInputFieldFromCustomer?.map((field: any) => field.displayName).join(", ")}
                    </div>
                </div>
            </If>
            <If condition={!usingComposioAuth && fieldsToEnterInIntegration?.length > 0}>
                <div className="mt-7 overflow-hidden rounded-[15px] border-[0.5px] border-solid border-[#c0c0c0]">
                    <div className=" flex items-center gap-3 border-b-[0.5px] border-b-[0.5px] border-solid border-[#c0c0c0] bg-[#fafafa] p-4 font-cera  text-[13px] font-[500] leading-none text-black-400">
                        <div className="min-w-[fit-content]">
                            {selectedAuthScheme === "OAUTH2"
                                ? "Enter Your App's Configuration Details"
                                : "Configure these fields to setups"}
                        </div>
                    </div>
                    <If condition={selectedAuthScheme === "OAUTH2"}>
                        <div className="flex items-center gap-3 border-b-[0.5px] border-b-[0.5px] border-solid border-[#c0c0c0] bg-[#fff] p-4 py-3 font-cera text-[13px] font-[400] leading-none text-black-400">
                            <div className="min-w-[fit-content]">
                                How to setup OAuth Developer App for{" "}
                                <Link
                                    isExternal={true}
                                    className="hover:underline"
                                    href={`https://www.google.com/search?q=${upperCaseFirstLetter(appKey)} Developer OAuth App Setup`}
                                >
                                    {upperCaseFirstLetter(appKey)}
                                </Link>
                                ?
                            </div>
                        </div>
                    </If>
                    <div className={clsx("grid grid-cols-2 gap-4", "!bg-[#fff] p-[19px] ")}>
                        {/* @ts-ignore */}
                        {fieldsToEnterInIntegration?.map((field: any) => {
                            return (
                                <ScopeFormFieldRenderer
                                    key={field.name}
                                    layout="vertical"
                                    field={field}
                                    formState={formState}
                                    setFormState={setFormState}
                                    isExistingConnector={isExistingConnector}
                                    usingComposioAuth={usingComposioAuth}
                                />
                            );
                        })}
                    </div>
                </div>
            </If>
            <div className="mt-3 flex items-center justify-end">
                <Button
                    size={36}
                    className="flex h-[36px] max-w-[fit-content] gap-2 rounded-[10px] px-4 text-[14px]"
                    onClick={updateForm}
                    disabled={isUpdating}
                >
                    {isUpdating ? "Saving..." : isExistingConnector ? "Update Configuration" : "Create Integration"}
                    <MoveRightIcon height={14} width={14} />
                </Button>
            </div>
            <If condition={!isExistingConnector}>
                <TipBox />
            </If>
        </>
    );
};

export const TipBox = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);

    const items = [
        {
            title: "Add your own tools",
            description: "Add your own tools to the integration to extend its functionality.",
            href: "https://docs.composio.dev/introduction/foundations/components/integrations/custom-integration#custom-tools-using-openapi-spec",
        },
        {
            title: "You can use the integration for just auth or execution or both",
            description: "Many developers use Composio just for Auth Management",
            href: "https://docs.composio.dev/introduction/foundations/components/integrations/custom-integration#custom-tools-using-openapi-spec",
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [items.length]);

    useEffect(() => {
        if (carouselRef.current) {
            const { scrollWidth } = carouselRef.current;
            const itemWidth = scrollWidth / items.length;

            carouselRef.current.scrollTo({
                left: currentIndex * itemWidth,
                behavior: "smooth",
            });
        }
    }, [currentIndex, items.length]);

    return (
        <div className="mt-[28px] overflow-hidden rounded-[16px] border-[0.5px] border-[#216dc4] bg-[#5ec7ff03] px-6  pb-4 pt-5 text-[#0c5973]">
            <div
                ref={carouselRef}
                className="flex overflow-x-hidden"
                style={{
                    width: "100%",
                }}
            >
                {items.map((item, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                        <div className="flex items-center gap-2">
                            <BookAIcon height={16} width={16} />
                            <h3 className="font-cera text-[14px] font-[500] leading-none">{item.title}</h3>
                        </div>
                        <p className="mt-2 text-[13px]">{item.description}</p>
                        <div className="mt-2 flex items-center gap-2">
                            <Link isExternal={true} className="text-[13px] leading-none text-[#8123CD]" href={item.href}>
                                Learn More
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-4 flex justify-center">
                {items.map((_, index) => (
                    <button
                        key={index}
                        className={`mx-1 h-[6px] w-[6px] rounded-full ${index === currentIndex ? "bg-[#216dc4]" : "bg-[#216dc499]"}`}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export const ScopeFormFieldRenderer = ({
    field,
    formState,
    setFormState,
    isExistingConnector,
    usingComposioAuth,
    layout,
    label,
}: {
    field: any;
    formState: any;
    setFormState: any;
    isExistingConnector: boolean;
    usingComposioAuth: boolean;
    layout: "horizontal" | "vertical";
    label?: string;
}) => {
    const value = formState.connectorConfiguration?.[field.name]?.value || "";
    const error = formState.connectorConfiguration?.[field.name]?.error || "";

    const isDisabled = () => {
        if (isExistingConnector) {
            return !field.name.includes("scopes");
        }
        return !!value?.includes("***");
    };

    return (
        <FormFieldRenderer
            key={field.name}
            placeholder={
                usingComposioAuth && field.name !== "scopes" && field.name !== "user_scopes"
                    ? `${field.displayName} - Already set`
                    : field.displayName
            } // @ts-ignore
            label={label}
            layout={layout}
            type="string"
            isRequired={usingComposioAuth ? false : field.required || false}
            description={field.description || ""}
            expected_from_customer={field.expected_from_customer || false}
            value={value}
            isExistingConnector={isExistingConnector}
            error={error}
            name={field.name}
            choices={field.choices || []}
            setValue={(value: string) => {
                // @ts-ignore
                setFormState((prev) => ({
                    ...prev,
                    connectorConfiguration: {
                        ...prev.connectorConfiguration,
                        [field.name]: {
                            ...prev.connectorConfiguration[field.name],
                            value: value,
                            required: !!field?.required,
                            choices: field.choices,
                            expected_from_customer: field.expected_from_customer || false,
                        },
                    },
                }));
            }}
            disabled={isDisabled()}
        />
    );
};
