import { css } from "@emotion/css";
import clsx from "clsx";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useEffect, useMemo, useRef } from "react";
import { Input } from "~/design-system/atom/input";

export const PaginationComponent = ({
    currentPage,
    totalPages,
    onChange,
    height = 32,
}: {
    currentPage: number;
    totalPages: number;
    onChange: (page: number) => void;
    height?: 32 | 36;
}) => {
    const isNextDisabled = currentPage === totalPages;
    const isPrevDisabled = currentPage === 1;
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = currentPage.toString();
        }
    }, [currentPage]);

    const totalPageValue = useRef(totalPages);

    const memoedTotalPages = useMemo(() => {
        if (totalPages > 1) {
            totalPageValue.current = totalPages;
            return totalPages;
        }

        return totalPageValue.current;
    }, [totalPages]);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= memoedTotalPages) {
            onChange(newPage);
        } else {
            if (inputRef.current) inputRef.current.value = currentPage.toString();

            alert("Please enter a valid page number");
        }
    };

    useEffect(() => {
        // on right arrow key increase the page
        const functionToExecute = (e: KeyboardEvent) => {
            const isFromSameElement = inputRef.current && (inputRef.current.contains(e.target as Node) || inputRef.current === e.target);

            if (!isFromSameElement) {
                return;
            }

            const currentPageFromURL = parseInt(new URLSearchParams(window.location.search).get("page") || "1");

            if (e.ctrlKey || e.metaKey) {
                return;
            }

            if (e.key === "ArrowRight") {
                handlePageChange(currentPageFromURL + 1);
            }

            // on left arrow key decrease the page
            else if (e.key === "ArrowLeft") {
                handlePageChange(currentPageFromURL - 1);
            }
        };

        document.addEventListener("keydown", functionToExecute);

        return () => {
            document.removeEventListener("keydown", functionToExecute);
        };
    }, [currentPage, totalPages]);

    return (
        <div className="flex flex-1 items-center gap-1 overflow-hidden rounded-[12px] border border-grey-200 bg-[#fff] px-2 pr-0 text-black-600">
            <div className="min-w-[fit-content] flex-1 pt-[1.5px] text-[13px]">Page</div>
            <Input
                ref={inputRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handlePageChange(Number(e.currentTarget.value));
                    }
                }}
                onBlur={(e) => {
                    handlePageChange(Number(e.currentTarget.value));
                }}
                placeholder=""
                className={`h-[${height}px] w-[32px] rounded-[0px] !border-[0px] !border-b-0 !border-t-0 bg-[white] px-0  !pl-0 !pt-[14px] text-center text-[14px] text-black-400 focus:ring-[#00000000]`}
            />
            <div className="min-w-[fit-content] pt-[2px] text-[14px] ">{`of ${memoedTotalPages}`}</div>
            <div className="flex gap-[-4px]">
                <div
                    className={clsx(
                        btnCSS,
                        isPrevDisabled && "cursor-not-allowed",
                        css`
                            height: ${height}px;
                        `,
                    )}
                    onClick={() => !isPrevDisabled && onChange(currentPage - 1)}
                >
                    <ChevronLeftIcon height={16} width={16} />
                </div>
                <div
                    className={clsx(
                        btnCSS,
                        `height: ${height}px;`,
                        isNextDisabled && "cursor-not-allowed",
                        css`
                            height: ${height}px;
                        `,
                    )}
                    onClick={() => !isNextDisabled && onChange(currentPage + 1)}
                >
                    <ChevronRightIcon height={16} width={16} />
                </div>
            </div>
        </div>
    );
};
const btnCSS = css`
    :hover {
        background-color: #ffffff09;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
`;
