import { css } from "@emotion/css";
import { SelectGroup } from "@radix-ui/react-select";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { ChevronRightIcon, CopyIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { ZTriggerItem } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { useAllIntegrations } from "~/dataProcessor/hooks/integrations";
import { Button } from "~/design-system/atom/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/design-system/atom/select";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useGetConnectorInfo } from "~/pages/connector/[connectorId]";
import { LoadingIcon } from "~/ui/components/base/loadingIcon";
import { PaginationComponent } from "~/ui/components/custom/PagninationComponent";
import { Table } from "~/ui/components/custom/Table";
import { copyText } from "~/utils/dom";
import { If } from "~/utils/reactComponent";
import { formatActionName, upperCaseAll } from "~/utils/string";
import { useTriggerOrActionFuseSearch } from "../../../dataProcessor/hooks/useTriggerOrActionFuseSearch";
import { ActionNTriggerFilterBar } from "../actions";
import { useConnectionInfo } from "../connections";
import { useTriggerData } from "./hooks";
import { TriggerPopup } from "./setupPopup";

export const FilterBar = ({ totalPage = 10 }) => {
    const router = useRouter();
    const { query } = router;

    const updateQuery = UpdateQuery();

    const { data: { items: connectors } = {} } = useAllIntegrations();

    const isConnectorPage = router.pathname === "/connector/[connectorId]";

    return (
        <div className="mt-3 flex items-center justify-between text-[15px] text-[#e0e0e080]">
            Filter by
            <div className="flex items-center gap-2">
                {!isConnectorPage && (
                    <Select
                        onValueChange={(value) => {
                            updateQuery("connectorId", value);
                        }}
                    >
                        <SelectTrigger className="w-[200px]">
                            <SelectValue placeholder="Select live tool" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {connectors?.map((connector: any) => (
                                    <SelectItem value={connector.id} key={connector.id}>
                                        {connector.name}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                )}
                <PaginationComponent
                    currentPage={Number(query.page) || 1}
                    totalPages={Number(totalPage) || 1}
                    onChange={(page) => {
                        updateQuery("page", page.toString());
                    }}
                />
            </div>
        </div>
    );
};

export const TriggerRenderer = () => {
    const router = useRouter();
    const { query } = router;

    const connectorInfo = useGetConnectorInfo(query.connectorId as string);
    let appName = connectorInfo?.data?.appName;

    const connectionInfo = useConnectionInfo(query.connectionId as string);

    if (connectionInfo?.data?.appUniqueId) {
        appName = connectionInfo?.data?.appUniqueId;
    }

    const { triggerData, isLoading, isError, isSuccess } = useTriggerData(appName as unknown as string);

    const updateQuery = UpdateQuery();

    useEffect(() => {
        if (!router.isReady) return;

        if (!query.page) updateQuery("page", "1");
    }, [router.query]);

    const headerRenderer = useCallback(() => {
        const tableHeaders = ["Name", "Enum", "Description", "Active triggers", ""];

        return tableHeaders.map((header, index) => (
            <div
                key={index}
                className={clsx("font-gilroy text-[12px] font-[500]", index === tableHeaders?.length - 1 ? "flex justify-end pr-4" : "")}
            >
                {header}
            </div>
        ));
    }, []);

    const rowRenderer = useCallback((row: z.infer<typeof ZTriggerItem>) => {
        return <TriggerRow triggerData={row} />;
    }, []);

    const search = useMemo(() => (query.search as string) || "", [query.search]);

    const { searchData } = useTriggerOrActionFuseSearch({
        dataToSearch: triggerData,
    });

    const filteredTriggerData = useMemo(() => {
        return search ? searchData(search) : triggerData;
    }, [search, searchData, triggerData]);

    const [, setOpenTrigger] = useAtom(openTriggerAtom);

    return (
        <div className="h-full w-full ">
            <ActionNTriggerFilterBar isCustomApp={false} isAction={false} actions={[]} />
            <Table
                gridCSS={gridBox}
                rows={filteredTriggerData}
                state={isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "none"}
                noResultText="No triggers found"
                headerRenderer={headerRenderer}
                rowRenderer={rowRenderer}
                onRowClick={(row) => {
                    setOpenTrigger(row.name);
                }}
                rowContainerHeight={window.innerHeight - 174}
                rowHeight={50}
            />
        </div>
    );
};

export const gridBox = css`
    display: grid;
    grid-template-columns: 280px 100px 1fr 100px 200px;
    gap: 10px;
    width: 100%;
`;

const openTriggerAtom = atom<string | null>(null);

const TriggerRow = ({ triggerData }: { triggerData: z.infer<typeof ZTriggerItem> }) => {
    const router = useRouter();
    const isAppPage = router.pathname === "/app/[appId]";

    const [isCopied, setIsCopied] = useState(false);

    const [openTrigger, setOpenTrigger] = useAtom(openTriggerAtom);
    const [triggerLoadingStatus, setTriggerLoadingStatus] = useState(null as unknown as string);
    const open = openTrigger === triggerData.name;

    return (
        <>
            {open && (
                <TriggerPopup
                    setOpen={() => {
                        setOpenTrigger(null);
                    }}
                    triggerName={triggerData.enum}
                    setLoadingStatus={setTriggerLoadingStatus as any}
                />
            )}
            <div
                className="h-[20px] max-w-[full] overflow-hidden overflow-x-auto text-[14px] font-[500] text-black-600"
                title={triggerData.display_name || ""}
            >
                {formatActionName(triggerData.display_name)}
            </div>
            <div
                className="w-fit text-[13px]"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <ToolTipBox
                    align="center"
                    side="right"
                    content={isCopied ? "✅ Copied " + upperCaseAll(triggerData.name) : "Copy " + upperCaseAll(triggerData.name)}
                    onOpenChange={(e) => {
                        if (!e) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <span
                        className="flex cursor-pointer items-center gap-2 text-[12px]"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(upperCaseAll(triggerData?.name)!);
                            setIsCopied(true);
                        }}
                    >
                        Enum
                        <CopyIcon height={12} width={12} strokeWidth={1.6} />
                    </span>
                </ToolTipBox>
            </div>
            <div className="word-wrap h-[20px] overflow-hidden text-[13px]">{triggerData.description?.slice(0, 120)}</div>
            <div className="text-[13px] font-[600] text-black-600">{triggerData.count}</div>
            <div className="flex items-center justify-end gap-1 pr-3">
                <If condition={!isAppPage}>
                    <>
                        <If condition={(triggerData?.count || 0) > 0}>
                            <Button
                                size={28}
                                variant={"plain"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    router.push(`/trigger/${triggerData.name}`);
                                }}
                                className=" mt-[auto] flex w-[fit-content] min-w-[60px] gap-[2px]  rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[400] tracking-[.3px] text-black-200"
                            >
                                {triggerLoadingStatus === "loading" ? (
                                    <LoadingIcon height={12} width={12} />
                                ) : (
                                    <>
                                        <div className="mt-[2px] text-[13px] font-[500]">Open</div>
                                        <ChevronRightIcon height={12} width={12} />
                                    </>
                                )}
                            </Button>
                        </If>
                        <Button
                            size={28}
                            variant={"plain"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenTrigger(triggerData.name);
                            }}
                            className=" mt-[auto] w-[fit-content]  rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[13px] font-[400] tracking-[.3px] text-black-200"
                        >
                            <div className="mt-[2px] text-[13px] font-[500]">Add</div>
                        </Button>
                    </>
                </If>
            </div>
        </>
    );
};
