import { css } from "@emotion/css";
import clsx from "clsx";

export const TabBarStrip = ({
    sections,
    selectedSection,
    setSelectedSection,
    className,
}: {
    sections: string[];
    selectedSection: string;
    setSelectedSection: (section: string) => void;
    className?: string;
}) => {
    return (
        <div className={clsx("flex w-full items-center", className)}>
            <div
                className={clsx(
                    "flex min-w-[fit-content] items-center gap-[4px] rounded-[12px] border-[.5px] border-[#e6e6e6]  bg-[#f4f4f580] p-[4px] px-[4px]",
                )}
            >
                {sections?.map((section, i) => {
                    const isSelected = section === selectedSection;

                    return (
                        <div
                            key={i}
                            onClick={() => setSelectedSection(section)}
                            className={clsx(
                                " flex h-[28px] min-w-[fit-content] cursor-pointer  justify-center rounded-[10px] px-2 py-[4px] pt-[2px] pt-[4.5px] text-[13.5px] capitalize",
                                css`
                                    border: 0.5px solid transparent !important;
                                    box-shadow: none;
                                    font-weight: 500 !important;

                                    ${isSelected &&
                                    ` 
                                    border: .5px solid #C7C7C7 !important; background: #fff; 
                                box-shadow: lch(0 0 0 / 0.04) 0px 4px 4px -1px, lch(0 0 0 / 0.05) 0px 1px 1px 0px !important;
    
                                `}

                                    color: #0c0c0c;
                                    :hover {
                                        background: #fff;
                                        border: 0.5px solid #c7c7c7 !important;
                                    }

                                    transition: all 0.2s ease-in-out;
                                `,
                            )}
                        >
                            {section}
                        </div>
                    );
                })}
            </div>
            <hr className="h-[.5px] w-full border-t-[.5px] border-[#64646434]" />
        </div>
    );
};
