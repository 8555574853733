import { css } from "@emotion/css";
import Form from "@rjsf/core";
import { FieldProps, RegistryFieldsType, RJSFSchema, UiSchema, WidgetProps } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { UploadCloudIcon, XIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Input } from "~/design-system/atom/input";
import { Textarea } from "~/design-system/atom/textarea";
import { useToast } from "~/design-system/atom/use-toast";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";

export const ReactForm = ({
    schema,
    formData,
    setFormData,
}: {
    schema: RJSFSchema;
    formData: string;
    setFormData: (e: string) => void;
}) => {
    const formattedSchema = React.useMemo(() => {
        if (!schema) return null;

        const formattedSchema = {
            ...(typeof schema === "object" && schema),
            properties: Object.fromEntries(
                //@ts-ignore
                Object.entries(schema.properties).map(([key, value]: [string, Record<string, unknown>]) => [
                    key,
                    {
                        ...value,
                        ...(value.type === "object" && {
                            $id: `/schemas/object`,
                        }),
                    },
                ]),
            ),
        };

        for (const [key, value] of Object.entries(formattedSchema.properties)) {
            //@ts-ignore
            if (value?.anyOf && value?.anyOf[0]?.type === "boolean") {
                //@ts-ignore
                delete formattedSchema.properties[key].anyOf;
                //@ts-ignore
                formattedSchema.properties[key].type = "boolean";
            }
        }

        return formattedSchema;
    }, [schema]);

    if (!formattedSchema) return null;

    return (
        <Form
            //@ts-ignore
            schema={formattedSchema}
            //@ts-ignore
            validator={validator}
            onChange={(e) => {
                const { required } = e.schema;
                const { properties, description, type, ...data } = e.formData;

                const typeIsNotObjectOrArray = type !== "object" && type !== "array";
                setFormData(
                    JSON.stringify({
                        ...data,
                        required,
                        ...(typeIsNotObjectOrArray &&
                            type && {
                                type,
                            }),
                    }),
                );
            }} //@ts-ignore
            uiSchema={uiSchema}
            formData={JSON.parse(!!formData ? formData : "{}")}
            fields={customFields}
        />
    );
};

const CustomTextArea = (props: FieldProps) => {
    const {
        id,
        schema: { title, description, type },
        required,
        readonly,
        disabled,
        autofocus,
        value,
        onChange,
    } = props;

    const toast = useToast();

    if (title?.toLowerCase().includes("file")) {
        return <CustomFileUpload {...props} />;
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        try {
            const { value } = event.target;

            if (value.length === 0) {
                onChange(null);
                return;
            }

            if (type === "object" || type === "array") {
                onChange(JSON.parse(value));
            }

            if (type === "array") {
                onChange(JSON.parse(value));
            }
        } catch {
            toast.toast({
                title: "Invalid JSON",
                description: "Please enter a valid JSON",
                variant: "destructive",
            });
        }
    };

    return (
        <div className="form-group">
            <label htmlFor={id} className="control-label">
                {title} : [{type}]{required ? "*" : null}
            </label>
            <p id="root_title__description" className="field-description">
                {description}
            </p>
            <Textarea
                id={id}
                className="form-control min-h-[148px] border-[#232323] border-grey-300 bg-[#fff] pr-3 text-black-400 placeholder:text-[#acacac] focus:border-[#6e1bd8]"
                value={value}
                required={required}
                readOnly={readonly}
                disabled={disabled}
                autoFocus={autofocus}
                onBlur={handleChange}
            />
        </div>
    );
};

const uiSchema: UiSchema = {
    "ui:widget": "textarea",
    age: {
        "ui:widget": "textarea",
    },
};

const CustomString = function (props: WidgetProps) {
    const [value, setValue] = useState(props.value || props?.schema?.default);

    useEffect(() => {
        props.onChange(value);
    }, [value, props]);

    if (props.name.toLowerCase().includes("date")) {
        return <CustomDate {...props} />;
    }

    return (
        <Input
            className="bg-[transparent]" //@ts-ignore
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
        />
    );
};

const CustomFileUpload = function (props: FieldProps) {
    const { toast } = useToast();
    const {
        id,
        schema: { title, description, type },
        required,
        name,
    } = props;

    const [hasUploadedFileName, setHasUploadedFileName] = useState("");

    return (
        <>
            <label htmlFor={id} className="control-label">
                {title} : [{type}]{required ? "*" : null}
            </label>
            <p id="root_title__description" className="field-description">
                {description}
            </p>
            <div>
                <label htmlFor="file-upload" className="custom-file-upload">
                    <UploadCloudIcon height={12} width={12} /> Upload {name}
                </label>
                <input
                    id="file-upload"
                    type="file"
                    onChange={async (e) => {
                        if (e.target.files && e.target.files.length === 1) {
                            const fileContent = await new Promise((resolve) => {
                                const reader = new FileReader();

                                reader.onload = (event) => {
                                    if (event.target?.result) {
                                        resolve(btoa(event.target.result as string));
                                    }
                                };

                                //@ts-ignore
                                reader.readAsBinaryString(e.target.files[0]);
                            });

                            props.onChange({
                                //@ts-ignore
                                name: e.target.files[0].name,
                                content: fileContent,
                            });

                            //@ts-ignore
                            setHasUploadedFileName(e.target.files[0].name);
                        } else {
                            toast({
                                title: "Error",
                                description: "Select only one valid file",
                                variant: "destructive",
                            });
                        }
                    }}
                />
                {!!hasUploadedFileName && (
                    <div className="flex items-center gap-2">
                        <div>{hasUploadedFileName}</div>
                        <div>
                            <XIcon
                                height={12}
                                width={12}
                                onClick={() => {
                                    setHasUploadedFileName("");
                                    props.onChange(null);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const CustomDate = function (props: WidgetProps) {
    const [value, setValue] = useState(props.value || props?.schema?.default);

    useEffect(() => {
        props.onChange(value);
    }, [value, props]);

    return (
        <Input
            className="bg-[transparent]"
            placeholder="YYYY-MM-DD"
            type="datetime-local" //@ts-ignore
            value={value}
            onChange={(e) => {
                const formattedDate = new Date(e.target.value).toISOString();
                setValue(formattedDate);
                props.onChange(formattedDate);
            }}
        />
    );
};

const CustomBoolean = function (props: WidgetProps) {
    return (
        <>
            <label className="control-label">
                {props.schema.title}
                <span className="text-[red]">{props.required ? "*" : null}</span>
            </label>
            <p className="field-description">{props.schema.description}</p>
            <div>
                <SimpleDropdown
                    options={[
                        {
                            name: "True",
                            value: "true",
                        },
                        {
                            name: "False",
                            value: "false",
                        },
                    ]}
                    onChange={(e) => {
                        props.onChange(e);
                    }}
                    value={props.value}
                />
            </div>
        </>
    );
};

const customFields: RegistryFieldsType = {
    //@ts-ignore
    StringField: CustomString,
    //@ts-ignore
    BooleanField: CustomBoolean,
    //@ts-ignore
    IntegerField: CustomString,
    ArrayField: CustomTextArea,
    "/schemas/object": CustomTextArea,
};

export const jsonFormData = css`
    margin-top: 20px;
    #root__title {
        display: none;
    }
    input {
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 12px;
        width: 100%;
        height: 32px;
    }
    textarea {
    }
    .form-group {
        display: flex;
        flex-direction: column;
        label {
            margin-bottom: 6px;
        }
        margin-bottom: 12px;
    }
    #root__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
        color: #e0e0e0;
    }
    .control-label {
        margin: 12px 0 0px 0;
        font-size: 14px;
        font-weight: 500;
        color: #0c0c0c;
    }

    #root_archive {
        margin-bottom: 20px;
    }
    .btn {
        display: none;
    }
    .required {
        color: red;
    }

    .field-description {
        margin-bottom: 12px;
        line-height: 150%;
    }
`;
