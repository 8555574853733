import { ParsedUrlQuery } from "node:querystring";

export const QUERY_KEYS = {
    getAnalyticsQueryKey: (timePeriod: string) => ["analytics", timePeriod],
    USER_METADATA: ["USER_METADATA"],
    PLAIN_LOGS_QUERY_KEY: ["logs"],
    GET_PROJECTS_QUERY_KEY: ["projects"],
    GET_TRIGGER_WEBHOOK_URL_QUERY_KEY: ["getTriggerWebHookURL"],
    GET_EVENT_WEBHOOK_URL_QUERY_KEY: ["getEventWebHookURL"],
    GET_WEBHOOK_SECRET_QUERY_KEY: ["getWebhookSecret"],
    getAppTriggersQueryKey: (appName: string) => ["app", appName, "triggers"],
    getAppActionsQueryKey: (appName: string, usecase: string) => ["app", appName, "actions", usecase],
    getVerifyQueryKey: (token: string) => ["VERIFY_TOKEN", token],
    getProviderLoginQueryKey: (query: ParsedUrlQuery) => ["PROVIDER_LOGIN", query],
    getLogsQueryKey: (
        cursor: string | null,
        connectorId: string | string[] | undefined,
        time: string | string[] | undefined,
        status: string | string[] | undefined,
        search: string | string[] | undefined,
        entityId: string | string[] | undefined,
        logsType: string | string[] | undefined,
        sessionId: string | string[] | undefined,
    ) => ["logs", cursor, entityId, connectorId, time, status, search, logsType, sessionId],
    getActionQueryKey: (appName: any, query: ParsedUrlQuery) => ["actions", appName, query.page || "1", query.usecase],
    getAppScreenQueryKey: (key: string) => ["appScreen", key],
    getAppListQueryKey: (page: number, category: string | null | undefined) => ["apps", page, category],
    getIntegrationQueryKey: (appName: string | null) => ["integrations", appName],
    getTriggerDataQueryKey: (query: ParsedUrlQuery) => [
        "triggers",
        query.page || "1",
        query.name || "",
        query.connectorId || "",
        query.connectionId || "",
        query.showDisabled || "false",
    ],
    getTriggerQueryKey: (appName: string | null, query: ParsedUrlQuery) => ["triggers", appName, query.page || "1"],
    getConnectionsQueryKey: (query: ParsedUrlQuery) => [
        "connections",
        query.page || "1",
        query.connectorId,
        query.status,
        query.showDisabled,
        query.entityId,
    ],
    getAccountIntegrationQueryKey: (connectorId: string) => ["connectorInfo", connectorId],
    getAppIntegrationQueryKey: (appKey: string) => ["app", appKey],
};
