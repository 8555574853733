import { PlusIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useState } from "react";
import { z } from "zod";
import { ZIntegrationItemSchema } from "~/dataProcessor/api/api";
import { Button } from "~/design-system/atom/newButton";
import { E2EFlowWizard } from "~/ui/screens/appScreen/modules/wizard";

export const AddAccountButton = ({
    connectorData,
    size = 32,
    isMountedAtTop = false,
}: {
    connectorData: z.infer<typeof ZIntegrationItemSchema>;
    buttonVariant?: "default" | "plain";
    size?: 32 | 40;
    isMountedAtTop?: boolean;
}) => {
    const { query } = useRouter();
    const [show, setShow] = useState(query.addConnectorWizard === "true" && isMountedAtTop);

    return (
        <>
            <div
                className="flex  items-center gap-3"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Button
                    variant={"default"}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setShow(true);
                    }}
                    size={size}
                    className=" flex  items-center justify-center rounded-[10px]  "
                >
                    <PlusIcon className="mr-1" height={14} width={14} />
                    Add account
                </Button>
            </div>
            {show && (
                <E2EFlowWizard
                    initialData={{
                        first: {
                            appName: connectorData?.appName || "",
                        },
                        second: {
                            integrationID: connectorData?.id || null,
                        },
                        third: {
                            connectedAccountId: null,
                            actionName: null,
                        },
                        currentStep: 2,
                    }} // @ts-ignore
                    setOpen={(e) => {
                        setShow(e);
                    }}
                />
            )}
        </>
    );
};
