import { useQuery } from "@tanstack/react-query";
import sdk from "~/client/sdk";
import { getDuration } from "~/utils/duration";

export const useUser = () => {
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: ["user"],
        queryFn: () => sdk.clientAuthService.getUserInfo().then((res) => res.data),
        staleTime: getDuration({
            days: 1,
        }),
    });

    return {
        user: data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};

export const useApiKey = () => {
    const { user } = useUser();

    // @ts-ignore
    return user?.apiKey?.key || undefined;
};
