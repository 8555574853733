import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { QUERY_KEYS } from "~/constants/keys";
import { getTriggerInstance, getTriggers } from "~/dataProcessor/api/api";

export const useTriggerInstanceData = () => {
    const { query } = useRouter();
    const showDisabled = query.showDisabled === "true";
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        // @ts-ignore
        queryKey: QUERY_KEYS.getTriggerDataQueryKey(query),
        queryFn: () =>
            getTriggerInstance({
                page: Number(query?.page || 1),
                triggerNames: query.name ? [query.name as string] : undefined,
                connectorId: query.connectorId ? [query.connectorId as string] : undefined,
                connectionId: query.connectionId ? [query.connectionId as string] : undefined,
                showDisabled: showDisabled,
            }),
        retry: false,
        refetchOnWindowFocus: true,
    });

    return {
        triggerData: data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};

export const useTriggerData = (appName: string) => {
    const { query } = useRouter();
    const {
        data: triggerData = [],
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.getTriggerQueryKey(appName as unknown as string, query),
        queryFn: () =>
            getTriggers({
                page: Number(query?.page || 1),
                appName: appName || undefined,
                // @ts-expect-error
                integrationId: query.connectorId as string | null,
            }),
        retry: true,
    });
    return {
        triggerData,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};
