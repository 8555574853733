import { css } from "@emotion/css";
import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { CheckCircle2Icon, MoveLeftIcon, XCircleIcon } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import JsonView from "react18-json-view";
import remarkGfm from "remark-gfm";
import { z } from "zod";
import { ZSingleTriggerResDTO, getConnections, getTriggerInfo } from "~/dataProcessor/api/api";
import { axiosInstance } from "~/dataProcessor/api/global";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { Button } from "~/design-system/atom/button";
import { DialogDescription, DialogHeader, DialogTitle } from "~/design-system/atom/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "~/design-system/atom/select";
import { useToast } from "~/design-system/atom/use-toast";
import { LoadingIconPlain } from "~/ui/components/base/loadingIcon";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { ReactForm, jsonFormData } from "~/ui/containers/form";
import { If } from "~/utils/reactComponent";

const TRIGGER_STATUS = {
    TRIGGER_ALREADY_EXISTS: "Trigger already exists",
};

export const setTriggerData = ({ actionName, connectionID, input }: { actionName: string; input: unknown; connectionID: string }) => {
    const url = `${process.env.NEXT_PUBLIC_API_URL}/api/v1/triggers/enable/${connectionID}/${actionName}`;

    return axiosInstance.post(
        url,
        {
            verifyHost: process.env.NEXT_PUBLIC_API_URL,
            triggerConfig: input,
        },
        {
            withCredentials: true,
        },
    );
};

export const TriggerPopup = ({
    setOpen,
    triggerName,
    setLoadingStatus,
}: {
    setOpen: (e: boolean) => void;
    open?: boolean;
    triggerName: string;
    setLoadingStatus: (status: "loading" | "error" | "completed") => void;
}) => {
    const { data: triggerData, isLoading, isError } = useQuery(["triggerInfo", triggerName], () => getTriggerInfo(triggerName));

    useEffect(() => {
        if (setLoadingStatus) {
            if (isLoading) {
                setLoadingStatus("loading");
            } else if (isError) {
                setLoadingStatus("error");
            } else {
                setLoadingStatus("completed");
            }
        }
    }, [isLoading, isError, setLoadingStatus]);

    if (isLoading || isError) {
        return null;
    }

    return (
        <DialogBoxCenter
            open={true}
            setOpen={setOpen.bind(this)}
            className={clsx(" max-h-[70vh] min-w-[580px] overflow-y-scroll bg-[#fff] ", scrollBarStyle)}
        >
            <DialogHeader>
                <DialogTitle className="font-avenir mt-1 flex gap-2 text-[15px] font-[600] leading-none  text-black-200">
                    Setup trigger {triggerData?.name}
                </DialogTitle>
            </DialogHeader>
            <DialogDescription className="  font-gilroy text-[13px] text-black-200">
                <Description triggerData={triggerData} input={JSON.stringify(triggerData?.config || "{}", null, 2)} />
            </DialogDescription>
        </DialogBoxCenter>
    );
};

export const TriggerInfoPopup = ({ setOpen, triggerData }: { setOpen: (e: boolean) => void; open?: boolean; triggerData: any }) => {
    return (
        <DialogBoxCenter
            open={true}
            setOpen={setOpen.bind(this)}
            className={clsx(" max-h-[90vh] min-h-[740px] min-w-[640px] overflow-y-scroll bg-[#101010] ", scrollBarStyle)}
        >
            <DialogHeader>
                <DialogTitle className="font-avenir mt-1 flex gap-2 text-[17px] font-[600] leading-none tracking-[.35px] text-[#e0e0e0]">
                    Log info
                </DialogTitle>
            </DialogHeader>
            <DialogDescription className="!mt-[20px]  font-gilroy text-[13px] tracking-[.35px] text-[#ecececa2]">
                <div className="max-w-[620px] overflow-x-scroll">
                    <JsonView src={triggerData} />
                </div>
            </DialogDescription>
        </DialogBoxCenter>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Description = ({ triggerData: triggerData, input }: { triggerData: z.infer<typeof ZSingleTriggerResDTO>; input: any }) => {
    const [state, setState] = useState<"DRAFT" | "SUCCESS" | "ERROR">("DRAFT");
    const initJSONINput = useMemo(() => {
        const { title: _title, ...rest } = JSON.parse(input);
        return rest;
    }, [input]);

    const { query } = useRouter();

    const connectorId = query?.connectorId;

    const [formData, setFormData] = useState(JSON.stringify(initJSONINput, null, 2));
    const { toast } = useToast();

    const { data } = useQuery(["actions", "connection", triggerData.appName, "status"], () =>
        getConnections({
            appName: triggerData.appName,
            status: "ACTIVE",
            showDisabled: false,
            ...(connectorId && {
                connectorId: connectorId as string,
            }),
        }),
    );

    const [selectedConnectedId, setSelectedConnectedId] = useState((query?.connectionId as string) || data?.items?.[0]?.id);

    useEffect(() => {
        if ((data?.items?.length ?? 0) > 0) {
            setSelectedConnectedId((query?.connectionId as string) || data?.items?.[0]?.id);
        }
    }, [data]);

    const {
        mutate: sendTestRequest,
        error,
        isLoading,
    } = useMutation({
        mutationFn: setTriggerData,
        onSuccess: (data) => {
            if (data?.data?.isNew === false) {
                toast({
                    title: "Error",
                    description: TRIGGER_STATUS.TRIGGER_ALREADY_EXISTS,
                    variant: "destructive",
                });
                return;
            }
            setState("SUCCESS");
        },
        onError: () => {
            setState("ERROR");
        },
    });

    const triggerInstruction = useMemo(() => {
        return triggerData?.instructions?.replace("\n    ", "").replaceAll("    ", "");
    }, [triggerData]);

    const router = useRouter();

    const isConnectionPage = router.pathname === "/connection/[connectionId]";

    const hasKeys = Object.keys(triggerData?.config?.properties || {}).length > 0;

    if (state === "SUCCESS" || state === "ERROR") {
        const isError = state === "ERROR";
        return (
            <div className="w-full">
                <div className="flex  flex-col gap-2 rounded-[24px] py-2">
                    <div>
                        {state === "SUCCESS" ? (
                            <CheckCircle2Icon className="h-[32px] w-[32px]" color="#56c121" />
                        ) : (
                            <XCircleIcon className="h-[32px] w-[32px]" color="#cc2353" />
                        )}
                    </div>
                    <div className=" mt-4 text-[17px] font-bold leading-none text-black-200">
                        {state === "SUCCESS" ? "Trigger created successfully" : "Trigger creation failed"}
                    </div>
                    <div className="mt-1 text-[14px] leading-[200%] text-black-300">
                        {state === "SUCCESS"
                            ? "Trigger is successfully created."
                            : "Failed to create trigger. Please look at the error message below and try again. "}
                    </div>

                    {isError && (
                        <pre className="mt-3 w-full max-w-[513px] overflow-scroll whitespace-pre-wrap break-words rounded-lg border border-gray-400 bg-gray-100 px-4 py-2 font-mono text-xs leading-relaxed text-gray-700">
                            {/* @ts-ignore */}
                            {error?.response?.data?.message || error?.message}
                        </pre>
                    )}
                    <div
                        className="mt-4 flex cursor-pointer items-center gap-2 hover:underline"
                        onClick={() => {
                            setState("DRAFT");
                        }}
                    >
                        <MoveLeftIcon height={16} width={16} strokeWidth={1.4} />
                        <div>Setup new trigger</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="mt-4">
                {data?.items?.length === 0 && (
                    <div className="my-2 w-full rounded-[12px] border-[1px] border-[#bb5770] bg-[#ff285e34] p-[10px] text-center text-black-200">
                        No connected account for this integration. Please add a new connection.
                    </div>
                )}
                <If condition={!isConnectionPage}>
                    <div className="flex items-center  justify-between pt-2 ">
                        <div className="font-avenir text-[13px] font-[600] leading-none tracking-[.35px] text-black-200">
                            Select connected account
                        </div>
                        <Select
                            onValueChange={(e) => {
                                setSelectedConnectedId(e);
                            }}
                            value={selectedConnectedId}
                        >
                            <SelectTrigger className="w-[320px]">
                                <SelectValue placeholder="Select connection" />
                            </SelectTrigger>
                            <SelectContent className="z-[200]">
                                <SelectGroup>
                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                    {data?.items?.map((item: any) => (
                                        <SelectItem value={item.id} key={item.id} className="">
                                            {item.id}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </If>
                <div className={clsx(`mb-0  text-left text-[14px] font-[600]  text-black-200`, isConnectionPage ? "mt-62" : "mt-6")}>
                    Instructions
                </div>
                <div className="mt-2 max-w-[512px]">
                    <Markdown remarkPlugins={[remarkGfm]} className={"markdown"}>
                        {triggerInstruction}
                    </Markdown>
                </div>
                {hasKeys && (
                    <>
                        <div className="mb-0 mt-8 text-left text-[14px] font-[600]  text-black-200">Inputs required for the trigger</div>
                        <div className="text-left">
                            <div className={clsx("mb-6  mt-2 w-full items-center justify-end gap-6 text-left leading-none", jsonFormData)}>
                                <ReactForm
                                    schema={triggerData?.config}
                                    setFormData={setFormData}
                                    formData={formData}
                                    // @ts-ignore
                                    initData={initJSONINput}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="mt-0 flex w-full items-center justify-end gap-4 leading-none">
                    <Button
                        className="ml-[auto] mt-1 h-[36px]  w-[fit-content]  px-4 "
                        variant={"default"}
                        disabled={isLoading}
                        onClick={() => {
                            if (!selectedConnectedId) {
                                toast({
                                    title: "Error",
                                    description: "Please select a connection",
                                    variant: "destructive",
                                });
                                return;
                            }

                            try {
                                sendTestRequest({
                                    actionName: triggerData.enum || "",
                                    input: JSON.parse(formData || "{}"),
                                    connectionID: selectedConnectedId,
                                });
                            } catch {
                                toast({
                                    title: "Invalid JSON",
                                    description: "Please validate JSON",
                                    variant: "destructive",
                                });
                            }
                        }}
                    >
                        {isLoading ? <LoadingIconPlain height={16} width={16} strokeWidth={1.4} /> : "Enable Trigger"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export const TagStatus = ({ status }: { status: "SUCCESS" | "ERROR" }) => {
    const statusColors = {
        SUCCESS: {
            bg: "#63ff6613",
            border: "#63ff6672",
        },
        ERROR: {
            bg: "#ff639213",
            border: "#ff638070",
        },
    };

    const colorScheme = statusColors[status || "SUCCESS"];

    return (
        <div
            className={clsx(
                `flex items-center gap-2 rounded-[16px] px-3 py-1 pt-[4px] text-[11px] font-[600] text-[#ffffff]`,
                css`
                    background-color: ${colorScheme?.bg};
                    border: 1px solid ${colorScheme?.border};
                `,
            )}
        >
            <div className={` text-[#fff]`}>{status}</div>
        </div>
    );
};
