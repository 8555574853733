import { css } from "@emotion/css";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { motion } from "framer-motion";
import { BlocksIcon, PlusSquareIcon } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
//@ts-ignore
import useWindowSize from "react-use/lib/useWindowSize";
// @ts-ignore
import { ArrowUpRight } from "lucide-react";
// @ts-ignore
import { VariableSizeGrid as Grid } from "react-window";
import { z } from "zod";
import { QUERY_KEYS } from "~/constants/keys";
import { getApps, ZAppInfoSchema, ZAppSchema } from "~/dataProcessor/api/apps";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { Error, LoadingState, NoResult } from "~/ui/containers/base/emptyStates";
import { APP_CATEGORIES_LIST } from "./constants";
import { FilterBar } from "./filterBar";

export const useApps = ({ page, category, suspense = false }: { page: number; category?: string | null; suspense?: boolean }) => {
    const {
        data: { items: appsData = [], totalPages = 1 } = {},
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: QUERY_KEYS.getAppListQueryKey(page, category),
        queryFn: () =>
            getApps({
                category,
            }),
        retry: false,
        staleTime: 10 * 60 * 1000,
        suspense: suspense,
    });

    return {
        data: {
            items: appsData,
            totalPages,
        },
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};

export const AppList = () => {
    const { query } = useRouter();
    const category = query.category as string | undefined;
    const search = query.search as string | undefined;
    const page = query.page ? Number(query.page) : 1;

    const {
        data: { items: appsData = [] },
        isLoading,
        isError,
        isSuccess,
    } = useApps({
        page,
    });

    const [betaApps, prodApps] = useMemo(() => {
        const betaApps = appsData.filter((app) => app.name.toLowerCase().endsWith("beta"));
        const prodApps = appsData
            .filter((app) => !app.name.toLowerCase().endsWith("beta"))
            .sort((a: any, b: any) => b.meta.triggersCount + b.meta.actionsCount - (a.meta.triggersCount + a.meta.actionsCount));

        return [betaApps, prodApps];
    }, [appsData]);

    const { width, height } = useWindowSize();

    const [columnCount, setColumnCount] = useState(() => {
        if (typeof window === "undefined") return 3;

        return window.innerWidth < 768 ? 2 : window.innerWidth < 1000 ? 3 : 3;
    });

    useEffect(() => {
        const handleResize = () => {
            const newColumnCount = window.innerWidth < 768 ? 2 : window.innerWidth < 1100 ? 2 : window.innerWidth < 1400 ? 3 : 4;
            setColumnCount(newColumnCount);
            //@ts-ignore
            gridRef.current?.resetAfterColumnIndex(0, true);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const gridRef = useRef();

    const columnWidth = useCallback(
        () => (typeof window === "undefined" ? 290 : (window.innerWidth - 300) / columnCount),
        [columnCount, width],
    );

    const rowHeight = useCallback(() => 240, []);

    const allApps = useMemo(() => [...prodApps, ...betaApps], [prodApps, betaApps]);

    const data = useMemo(() => {
        const filteredApps = category === "all" || category == null ? allApps : allApps.filter((app) => app.categories?.includes(category));

        const list = search
            ? allApps.filter((app) => {
                  return (
                      app.name.toLowerCase().includes(search.toLowerCase()) ||
                      app.description.toLowerCase().includes(search.toLowerCase()) || // @ts-ignore
                      app.categories?.join(" ").toLowerCase().includes(search.toLowerCase())
                  );
              })
            : filteredApps;

        return [
            {
                name: "add_custom_tool",
                type: "add_new_tools_card",
            },
            ...list,
        ];
    }, [allApps, search, category]);

    const rowCount = useMemo(() => Math.ceil(data.length / columnCount), [data, columnCount]);

    const containerWidth = useMemo(() => {
        return width - 290 || 1400;
    }, [width]);

    const containerHeight = useMemo(() => {
        // @ts-ignore
        return height - 68 || 700;
    }, [height]);

    const appCountByCategory = useMemo(() => {
        const appCount = allApps.reduce(
            (acc, app) => {
                // @ts-ignore
                app.categories?.forEach((category) => {
                    if (!acc[category]) acc[category] = 0;

                    acc[category]++;
                });
                return acc;
            },
            {} as Record<string, number>,
        );

        return {
            all: allApps?.length,
            ...appCount,
        };
    }, [allApps]);

    return (
        <>
            <FilterBar categoriesList={APP_CATEGORIES_LIST} appCountByCategory={appCountByCategory} />
            <div className="w-full px-6 pr-0" id="container">
                {isSuccess && data.length > 0 && (
                    <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
                        <Grid
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            height={containerHeight}
                            rowCount={rowCount}
                            rowHeight={rowHeight}
                            overscanRowCount={5}
                            width={containerWidth}
                            ref={gridRef}
                            className={clsx(
                                scrollBarStyle,
                                css`
                                    margin-top: 49px;
                                    padding-top: 40px;
                                `,
                            )}
                        >
                            {/* {Cell} */}
                            {(props: { rowIndex: number; columnIndex: number; style: React.CSSProperties }) => {
                                const index = props.rowIndex * columnCount + props.columnIndex;

                                if (!data[index]) {
                                    return <div style={props.style} key={index}></div>;
                                }

                                return (
                                    <div
                                        style={{
                                            ...props.style,
                                            paddingRight: 20,
                                            paddingBottom: 10,
                                            paddingTop: props.rowIndex === 0 ? 20 : 10,
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <AppBox appData={data[index] as unknown as z.infer<typeof ZAppInfoSchema>} key={index} />
                                    </div>
                                );
                            }}
                        </Grid>
                    </ErrorBoundary>
                )}
                {isLoading && <LoadingState height={containerHeight - 100} text="Fetching tools..." />}
                {isError && <Error height={containerHeight} />}
                {/* @ts-ignore */}
                {!isLoading && !isError && data.length === 0 && <NoResult height={containerHeight} text="No tools found" />}
            </div>
        </>
    );
};

const AppBox = ({ appData }: { appData: Pick<z.infer<typeof ZAppSchema>, "items">["items"][0] }) => {
    // Auth only apps have 0 triggers and 0 actions
    const isAuthOnlyApp = !appData?.meta?.actionsCount && !appData?.meta?.triggersCount;

    // @ts-ignore TODO: Need to fix types - Apoorv
    const supportedAuthModes = appData?.auth_schemes?.map((scheme) => scheme.mode) || [];

    //@ts-ignore
    if (appData.type === "add_new_tools_card") {
        return (
            <Link href="/custom_tools">
                <div
                    className={clsx(
                        "relative  flex h-full cursor-pointer flex-col justify-between rounded-[20px] border-[1px] border-[#D5D5D5] bg-[#fdfdfd] px-5 py-5 text-[#d1d1d1a7] hover:border-[#28282891]",
                        css`
                            box-shadow:
                                lch(0 0 0 / 0.02) 0px 0px 3px -1px,
                                lch(0 0 0 / 0.02) 0px 0px 1px 1px;
                            &:hover {
                                background-color: #fff;
                                border-color: #954fb7;
                            }
                        `,
                    )}
                >
                    <div>
                        <PlusSquareIcon height={28} width={28} color="#9537de" strokeWidth={1.7} />
                        <div className="mt-5 text-[16px] font-[600] leading-none text-black-200">Setup a New Tool</div>
                        <div className="mt-3 text-[15px] text-black-500">
                            Convert your APIs into a Tool,
                            <br />
                            or ask Composio to add a new Tool
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    return (
        <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
            <Link href={`/app/${appData.key}`}>
                <motion.div
                    className={clsx(
                        "relative  flex h-full cursor-pointer flex-col justify-between rounded-[20px] border-[1px] border-[#D5D5D5] bg-[#fdfdfd] px-5 py-5 text-[#d1d1d1a7] hover:border-[#28282891]",
                        css`
                            box-shadow:
                                lch(0 0 0 / 0.02) 0px 0px 3px -1px,
                                lch(0 0 0 / 0.02) 0px 0px 1px 1px;
                            &:hover {
                                background-color: #fff;
                                border-color: #954fb7;
                            }
                        `,
                    )}
                    whileHover={{
                        y: -1,
                    }}
                >
                    <div className="w-full">
                        <div className="flex items-center gap-[8px]">
                            <div
                                className={clsx(
                                    "flex h-6 w-6 items-center justify-center  uppercase text-pink-200",
                                    css`
                                        background-image: url("${appData.logo}");

                                        :hover {
                                            box-shadow:
                                                lch(0 0 0 / 0.02) 0px 0px 3px -1px,
                                                lch(0 0 0 / 0.02) 0px 0px 1px 1px;
                                        }
                                    `,
                                    roundedImage,
                                )}
                            ></div>
                            <div className=" font-avenirn text-[15px] font-[600] leading-none text-black-200 ">
                                {appData.name.charAt(0).toUpperCase() + appData.name.slice(1)}
                            </div>
                        </div>
                        <p className="mb-1 mt-3 overflow-hidden text-ellipsis text-[12.5px] font-[400] leading-[170%] text-grey-600 ">
                            {appData.description.length > 100 ? `${appData.description.substring(0, 100)}...` : appData.description}
                        </p>
                    </div>
                    <div>
                        <div className="flex justify-between">
                            <div className="flex flex-wrap gap-2 text-[11px] text-black-300">
                                {appData.categories
                                    ?.filter((category) => category !== "default")
                                    .slice(0, 2)
                                    .map((category) => (
                                        <div
                                            className="rounded-[40px] border border-[#e0e0e0] bg-[#a7a7a712] px-[6px] py-[4px] text-[11px] font-[500] leading-none text-[#616161]"
                                            key={category}
                                        >
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </div>
                                    ))}
                            </div>
                            <div className="flex flex-wrap gap-2 text-[11px] text-black-300 justify-end">
                                {supportedAuthModes.map((mode: string) => (
                                    <div
                                        className="rounded-[40px] border border-[#e0e0e0] bg-[#a7a7a712] px-[6px] py-[4px] text-[11px] font-[500] leading-none text-[#616161] h-fit"
                                        key={mode}
                                    >
                                        {mode.charAt(0).toUpperCase() + mode.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {isAuthOnlyApp ? (
                            <p className="text-purple-600 underline mt-4 text-sm mb-0">
                                Create Custom Action
                                <ArrowUpRight className="ml-1 inline h-4 w-4 text-purple-600" />
                            </p>
                        ) : (
                            <>
                                <div className="mb-0 mt-4 flex w-full gap-2 text-black-400">
                                    <div className="flex items-center gap-1 text-[13px]">
                                        <BlocksIcon height={14} width={14} color="#F6804D" />
                                        <div>
                                            <strong className="font-[600]">{appData.meta.actionsCount}</strong>
                                            {` `}
                                            actions
                                        </div>
                                    </div>
                                    <div className="ml-2 flex items-center gap-1 text-[13px]">
                                        <BlocksIcon height={14} width={14} color="#5B4DF6" />
                                        <div>
                                            <strong className="font-[600]">{appData.meta.triggersCount}</strong>
                                            {` `}
                                            triggers
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </motion.div>
            </Link>
        </ErrorBoundary>
    );
};

const roundedImage = css`
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;
