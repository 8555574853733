import { css } from "@emotion/css";
import clsx from "clsx";
// @ts-ignore
import { cloneDeep } from "lodash";
import { SearchIcon, XIcon } from "lucide-react";
import { useRouter } from "next/router";
import React, { useCallback, useMemo, useState } from "react";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Input } from "~/design-system/atom/input";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { borderAndShadowCSS } from "~/ui/constants/style/common";
import { CodeWInstructionModal } from "~/ui/containers/codeModal";
import { capitalizeFirstLetter } from "~/utils/js";
import { CodeButton } from "../integrationPage";

interface CategoryItem {
    title: string;
    key: string;
}

interface FilterBarProps {
    categoriesList: CategoryItem[];
    appCountByCategory: Record<string, number>;
}

export const FilterBar: React.FC<FilterBarProps> = React.memo(({ categoriesList, appCountByCategory }) => {
    const router = useRouter();
    const updateQuery = UpdateQuery();
    const { search, category = "all" } = router.query;

    const hasCustomCategory = useMemo(() => Object.keys(appCountByCategory).includes("custom"), [appCountByCategory]);

    const categorySorted = useMemo(() => {
        return hasCustomCategory ? ["all", "custom", "popular", "productivity"] : ["all", "popular", "productivity", "ticketing"];
    }, [hasCustomCategory]);

    const filteredCategoryList = useMemo(() => {
        return categoriesList
            .filter((category) => categorySorted.includes(category.key))
            .sort((a, b) => categorySorted.indexOf(a.key) - categorySorted.indexOf(b.key));
    }, [categoriesList, categorySorted, appCountByCategory]);

    const renderCategoryItem = useCallback(
        (categoryItem: CategoryItem) => {
            const isActive = categoryItem.key === category;
            const categoryCount = appCountByCategory[categoryItem.key];

            return (
                <div
                    onClick={() => updateQuery("category", categoryItem.key)}
                    key={categoryItem.key}
                    className={clsx(
                        "flex cursor-pointer items-center rounded-[10px] border-[.7px] border-[#D2D2D2] bg-[#fff] !px-[9px] py-[4px] text-[13px] font-[500] tracking-[.3px] text-black-400 hover:border-[#954fb7] hover:bg-[#faefff]",
                        isActive && "!border-[#954fb7] !bg-[#faefff] text-[#541971]",
                        borderAndShadowCSS,
                        css`
                            ${isActive &&
                            `
                            border-color: #954fb7 !important;
                        `}
                        `,
                    )}
                >
                    <span className="mt-[2px]">{capitalizeFirstLetter(categoryItem.title || "")}</span>
                    <div className="ml-1 flex h-[20px] w-[24px] items-center justify-center rounded-[8px] border-[0.5px] border-[#d0cdcd] bg-[#fff] text-[11px] font-[600] text-black-600">
                        {categoryCount || "-"}
                    </div>
                </div>
            );
        },
        [category, appCountByCategory, updateQuery],
    );

    const renderDropdown = useCallback(() => {
        return (
            <SimpleDropdown
                options={
                    categoriesList
                        .filter((category) => appCountByCategory[category.key])
                        .filter((category) => !categorySorted.includes(category.key))
                        .map((category) => ({
                            name: `${capitalizeFirstLetter(
                                category.title.replaceAll("_", " ").replaceAll("-", " "),
                            )} ${appCountByCategory[category.key] ? `(${appCountByCategory[category.key]})` : ""}`,
                            value: category.key,
                            rightIcon: appCountByCategory[category.key] ? (
                                <span className="text-[10px] text-[#616161]">{appCountByCategory[category.key]}</span>
                            ) : null,
                        })) || []
                }
                onChange={(value) => updateQuery("category", value)}
                value={(category as string) || ""}
                size={32}
                placeholder="Other category"
                className="w-[140px]"
            />
        );
    }, [categoriesList, appCountByCategory, category, updateQuery]);

    const renderSearchInput = useCallback(
        () => (
            <Input
                className={clsx(
                    "min-w-[300px]",
                    "flex cursor-pointer items-center rounded-[10px] border-[.5px] border-[#D2D2D2] bg-[#fff] !px-[24px] py-[2px] py-[4px] pl-[28px] !text-[13px] text-[13px] !font-[500] font-[500] tracking-[.1px] tracking-[.3px] text-black-400 text-black-400 hover:border-[#954fb7]",
                    inputCSS,
                )}
                placeholder="Search by category or name"
                type="text"
                size={32}
                onChange={(e) => updateQuery("search", e.target.value)}
                rightIcon={
                    !!search && (search as string)?.length > 0 ? (
                        <XIcon
                            height={13}
                            width={13}
                            color="grey"
                            strokeWidth={1.6}
                            fill={"#6E6E6E"}
                            className="ml-[-8px] cursor-pointer"
                            onClick={() => updateQuery("search", "")}
                        />
                    ) : null
                }
                value={search || ""}
                icon={<SearchIcon strokeWidth={1.6} height={13} width={13} color="#6E6E6E" className="ml-[-5px]" />}
            />
        ),
        [search, updateQuery],
    );

    return (
        <div
            className={clsx(
                "flex w-full items-center gap-2 border-b-[.5px] border-b-[#DBDBDB] px-6 py-[8px]",
                css`
                    background-color: #fff;
                    position: fixed;
                    width: calc(100vw - 266px);
                    z-index: 10;
                `,
            )}
        >
            <div className="flex gap-2">{filteredCategoryList.slice(0, 4).map(renderCategoryItem)}</div>
            <div className="flex gap-4">{renderDropdown()}</div>
            <div className="ml-auto flex items-center gap-2">
                <ShowAppsCode />
                {renderSearchInput()}
            </div>
        </div>
    );
});

const inputCSS = css`
    box-shadow:
        lch(0 0 0 / 0.02) 0px 0px 4px -1px,
        lch(0 0 0 / 0.02) 0px 0px 2px 2px;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding-left: 28px !important;
    letter-spacing: 0.1px;
    ::placeholder {
        color: #545454 !important;
    }
`;

FilterBar.displayName = "FilterBar";

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch apps",
                            text: `from composio import ComposioToolSet, App

toolset = ComposioToolSet(api_key={{api_key}})
apps = toolset.get_apps()
print(apps)
`,
                            language: "python",
                        },
                    ],
                },
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch apps",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const apps = await composio.apps.list()
`,
                            language: "javascript",
                        },
                    ],
                },
            },
        ],
    },
};

const ShowAppsCode = () => {
    const [showCode, setShowCode] = useState(false);

    const apiKey = useApiKey();

    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-ignore
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const { blocks } = languaeItem.content;

                if (!blocks) continue;

                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`);
                }
            }
        }

        return data;
    }, [apiKey]);

    return (
        <div>
            <CodeButton setShowCode={setShowCode} />
            {showCode && (
                <CodeWInstructionModal title="Fetch apps with Our SDKs" data={transformedData} setOpen={() => setShowCode(false)} />
            )}
        </div>
    );
};
