import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "~/constants/keys";
import { getClientIntegrations } from "~/dataProcessor/api/api";
import { getDuration } from "~/utils/duration";

export const useAllIntegrations = (page = 1, appName: string | null = null) => {
    const { data, isLoading, isError, isSuccess, refetch } = useQuery({
        queryKey: QUERY_KEYS.getIntegrationQueryKey(appName),
        queryFn: () => getClientIntegrations(page, appName!, true),
        staleTime: getDuration({
            days: 1,
        }),
        retry: true,
        retryOnMount: false,
        refetchOnMount: false,
    });

    return {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    };
};
