import { atom, useAtom } from "jotai";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { ANALYTICS_EVENTS } from "~/constants/analytics";
import { upperCaseFirstLetter } from "~/utils/string";
import { ANALYTICS } from "~/utils/tracking";
import { WizardBox } from "../components/WizardBox";
import { ConnectedAccountSetup } from "./addConnectedAccount";
import { CreateIntegrationPopover } from "./createIntegration/CreateIntegrationPopover";
import { RunActionPopup } from "./executeAction";

const noop = () => {};

export type E2EFlowWizardProps = {
    first: {
        appName: string;
    };
    second: {
        integrationID: string | null;
    };
    third: {
        connectedAccountId: string | null;
        actionName: string | null;
    };
    currentStep: number;
};

const DEFAULT_VALUE: E2EFlowWizardProps = {
    first: {
        appName: "",
    },
    second: {
        integrationID: null,
    },
    third: {
        connectedAccountId: null,
        actionName: null,
    },
    currentStep: 1,
};

export const wizardFlowAtoms = atom<E2EFlowWizardProps>(DEFAULT_VALUE);

export const updateWizardFlowAtom = atom(
    null,
    (
        get,
        set,
        arg: {
            step?: number;
            data?: Partial<E2EFlowWizardProps>;
        },
    ) => {
        const { step, data } = arg;

        set(wizardFlowAtoms, (prev) => ({
            ...prev,
            ...(step !== undefined && {
                currentStep: step,
            }),
            ...data,
        }));
    },
);

export const FlowWizard = ({ initialData, setOpen }: { initialData: E2EFlowWizardProps; setOpen: () => void }) => {
    const [wizardFlow, setWizardFlow] = useAtom(wizardFlowAtoms);
    const { currentStep } = wizardFlow;

    const router = useRouter();

    const isStartFromAppScreenAndHasIntegration = useMemo(() => {
        return initialData.currentStep === 2 && !!router.query.appId;
    }, [initialData.currentStep, router.query.appId]);

    useEffect(() => {
        setWizardFlow(initialData);
        return () => {
            setWizardFlow(DEFAULT_VALUE);
        };
    }, []);

    useEffect(() => {
        if (!wizardFlow.first.appName) return;

        ANALYTICS.track(ANALYTICS_EVENTS.E2E_FLOW_WIZARD_VIEWED, {
            appName: wizardFlow.first.appName,
            step: currentStep,
            initialStep: initialData.currentStep,
            extraData: {
                ...wizardFlow,
            },
            startedFrom: initialData.currentStep,
        });

        return () => {
            ANALYTICS.track(ANALYTICS_EVENTS.E2E_FLOW_WIZARD_DISMISSED, {
                appName: wizardFlow.first.appName,
                step: currentStep,
                initialStep: initialData.currentStep,
                extraData: {
                    ...wizardFlow,
                },
            });
        };
    }, [wizardFlow]);

    const title = useMemo(() => {
        if (currentStep === 1) {
            return `Connect ${upperCaseFirstLetter(wizardFlow.first.appName)} App`;
        }

        if (currentStep === 2) {
            return "Connect an account";
        }

        return "Execute tools";
    }, [currentStep, wizardFlow.first.appName]);

    if (!wizardFlow?.first?.appName) return null;

    return (
        <WizardBox
            setOpen={(e) => {
                // @ts-ignore
                setOpen(e);
            }}
            currentStep={currentStep}
            title={title}
            appName={wizardFlow.first.appName}
        >
            {currentStep === 1 && <CreateIntegrationPopover appKey={wizardFlow.first.appName} />}
            {currentStep === 2 && (
                <ConnectedAccountSetup
                    connectorId={wizardFlow.second.integrationID!}
                    appName={wizardFlow.first.appName}
                    appLogo={""}
                    isStartFromAppScreenAndHasIntegration={isStartFromAppScreenAndHasIntegration}
                    setOpen={setOpen}
                    // Provide a function for setOpen
                />
            )}
            {currentStep === 3 && ( // @ts-ignore
                <RunActionPopup
                    actionName={wizardFlow.third.actionName!} // @ts-ignore
                    connectedAccountId={wizardFlow.third.connectedAccountId!}
                    appName={wizardFlow.first.appName}
                    setOpen={setOpen}
                />
            )}
        </WizardBox>
    );
};

export const E2EFlowWizard = React.memo(FlowWizard);
