import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import clsx from "clsx";
import { atom, useAtom } from "jotai";
import { ArrowRight, InfoIcon, PlusIcon, UserCircle2Icon } from "lucide-react";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
// @ts-ignore
import { ShimmerTitle } from "react-shimmer-effects";

import { QUERY_KEYS } from "~/constants/keys";
import { getConnectorInfo } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { useAllIntegrations } from "~/dataProcessor/hooks/integrations";
import { useUser } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { CodeBlockComponent } from "~/design-system/atom/CodeBlockComponent";
import { DialogFooter } from "~/design-system/atom/dialog";
import { Input } from "~/design-system/atom/input";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { SimpleDropdown } from "~/ui/components/custom/dropdown/simple";
import { useConnections } from "~/ui/screens/connectedAccountList";
import { BACKEND_URL } from "~/utils/baseUrl";
import { If } from "~/utils/reactComponent";
import { showNLetters, upperCaseFirstLetter } from "~/utils/string";

import { updateWizardFlowAtom } from "../wizard";

export const getRedirectURLFromBE = async (appName: string, connectorId: string, data: any, userEntityId: string) => {
    return axios
        .post(
            `${BACKEND_URL}/api/v1/connectedAccounts`,
            {
                integrationId: connectorId,
                data,
                userUuid: userEntityId,
            },
            {
                withCredentials: true,
            },
        )
        .then((res) => res.data);
};

export const getConnectionStatus = async (connectionId: string) => {
    return axios
        .get(`${BACKEND_URL}/api/v1/connectedAccounts/${connectionId}`, {
            withCredentials: true,
        })
        .then((res) => res.data);
};

export const CODE_SNIPPETS = {
    python: {
        getIntegration: `from composio import ComposioToolSet, App
toolset = ComposioToolSet(api_key="{{api_key}}")

integration = toolset.get_integration(id="{{integration id}}")
# Collect auth params from your users
print(integration.expectedInputFields)
`,
        createConnection: `connection_request = toolset.initiate_connection(
    integration_id=integration.id,connected_account_params={{your_data}},
    entity_id="{{user_entity_id}}",
)

# Redirect step require for OAuth Flow
print(connection_request.redirectUrl)
print(connection_request.connectedAccountId)
`,
    },
    javascript: {
        getIntegration: `import { OpenAIToolSet } from 'composio-core';
const toolset = new OpenAIToolSet({ apiKey: '{{api_key}}' });
const composio = toolset.client;

const integration = await composio.integrations.get({integrationId: '{{integration id}}'});
const expectedInputFields = await toolset.getExpectedParamsForUser({integrationId: integration.id});
// Collect auth params from your users
console.log(expectedInputFields);`,
        createConnection: `const connectedAccount = await composio.connectedAccounts.initiate({
    integrationId: integration.id,
    entityId: '{{user_entity_id}}',
data:{{your_data}}
});

// connected account properties:
// connectionStatus (string), connectedAccountId (string), redirectUrl (string | null)
console.log(connectedAccount.redirectUrl);`,
    },
};

export const entityIdAtom = atom<string>("default");

export const ConnectedAccountSetup = ({
    setOpen,
    connectorId,
    appName,
    appLogo,
    isStartFromAppScreenAndHasIntegration,
}: {
    setOpen: (e: boolean) => void;
    open?: boolean;
    connectorId: string;
    appName: string;
    appLogo: string;
    isStartFromAppScreenAndHasIntegration: boolean;
}) => {
    const { user } = useUser();

    // @ts-ignore
    const apiKey = user?.apiKey?.key || undefined;

    const [, setWizardFlow] = useAtom(updateWizardFlowAtom);

    const onStatusChange = (status: { connectedAccountId: string | undefined | null; status: string }) => {
        if (status.connectedAccountId) {
            setWizardFlow({
                step: 3,
                data: {
                    third: {
                        connectedAccountId: status.connectedAccountId as string,
                        actionName: null,
                    },
                },
            });
        }
    };

    const goToFirstStep = () => {
        // @ts-ignore
        setWizardFlow({
            step: 1,
            data: {
                first: {
                    appName: appName,
                },
            },
        });
    };

    const switchIntegration = (value: string) => {
        setWizardFlow({
            step: 2,
            data: {
                second: {
                    integrationID: value as string,
                },
            },
        });
    };

    const [stateInfo, setStateInfo] = React.useState<{
        index: number;
        data: any;
    }>({
        index: 0,
        data: {},
    });

    const updateQuery = UpdateQuery();
    const queryClient = useQueryClient();
    const { query } = useRouter();

    const [formState, setFormState] = useAtom(formValueAtom);
    const toast = useToast();

    const { data, isSuccess, isError } = useQuery({
        queryKey: QUERY_KEYS.getAccountIntegrationQueryKey(connectorId),
        queryFn: () => getConnectorInfo({ connectorId }),
        retry: false,
    });

    const [connectionInfo, setConnectionInfo] = React.useState<{
        connectionStatus: string | null;
        connectedAccountId: string | null;
        redirectUrl: string | null;
    }>({
        connectionStatus: null,
        connectedAccountId: null,
        redirectUrl: null,
    });

    const getRedirectURL = (connectorId: string, userEntityId: string) => {
        const payload = Object.entries(formState.formValues).reduce((acc, [key, field]) => {
            // @ts-ignore
            acc[key] = field.value;
            return acc;
        }, {});

        return getRedirectURLFromBE(appName, connectorId, payload, userEntityId);
    };

    const apiReqCounterRef = useRef(0);

    const updateForm = async (userEntityId: string) => {
        if (connectionInfo.connectionStatus === "INITIATED") {
            toast.toast({
                title: "Connection already initiated",
                description: "Please wait for the connection to be initiated",
            });
            return;
        }

        const newFormValues: Record<string, any> = Object.entries(formState.formValues).reduce(
            (acc, [key, field]) => {
                if (field.required && !field.value) {
                    acc[key] = { ...field, error: "This field is required" };
                } else {
                    acc[key] = field;
                }
                return acc;
            },
            {
                ...formState.formValues,
            },
        );

        setFormState((prev) => ({ ...prev, formValues: newFormValues }));

        if (Object.values(newFormValues).some((field) => field.error)) {
            return;
        }

        if (["API_KEY", "BASIC", "BEARER_TOKEN", "BASIC_WITH_JWT"].includes(data?.authScheme!)) {
            getRedirectURL(connectorId, userEntityId)
                .then((res) => {
                    onStatusChange({ connectedAccountId: res.connectedAccountId, status: res.status });
                    updateQuery("addConnectorWizard", "false");
                    setConnectionInfo({
                        connectionStatus: "active",
                        connectedAccountId: res.connectedAccountId,
                        redirectUrl: null,
                    });
                    return;
                })
                .catch((err) => {
                    toast.toast({
                        variant: "destructive",
                        title: "Error",
                        description: err.response?.data?.message || err.message,
                    });
                });

            return;
        }

        try {
            const redirectUrlInfo = await getRedirectURL(connectorId, userEntityId);
            setConnectionInfo(redirectUrlInfo);

            const windowObj = window.open(redirectUrlInfo.redirectUrl, "_blank");

            const interval = setInterval(() => {
                getConnectionStatus(redirectUrlInfo.connectedAccountId).then((res) => {
                    if (res.status !== "INITIATED") {
                        onStatusChange({ connectedAccountId: redirectUrlInfo.connectedAccountId, status: res.status });
                        clearInterval(interval);
                        // @ts-ignore
                        windowObj?.close();
                        // Revalidate the connected accounts
                        queryClient.invalidateQueries(QUERY_KEYS.getConnectionsQueryKey(query));
                    }
                });

                apiReqCounterRef.current += 1;

                if (apiReqCounterRef.current > 60) {
                    setStateInfo({ index: 1, data: { status: "failed" } });
                    clearInterval(interval);
                }
            }, 2000);
        } catch (e: any) {
            toast.toast({
                variant: "destructive",
                title: "Error",
                description: e.message,
            });
        }
    };

    const [selectedLanguage, setSelectedLanguage] = useState("python");

    const { data: integrationData, isLoading: isIntegrationLoading } = useQuery({
        queryKey: QUERY_KEYS.getAccountIntegrationQueryKey(connectorId),
        queryFn: () => getConnectorInfo({ connectorId }),
        retry: false,
    });

    const [entityId, setEntityId] = useAtom(entityIdAtom);
    const transformedCodeSnippet = useMemo(() => {
        // Step 2: Create object from expected input fields
        const object = integrationData?.expectedInputFields?.reduce(
            (acc, field) => {
                // @ts-ignore
                acc[field.name] = formState.formValues[field.name]?.value || field.default || `your ${field.name}`;
                return acc;
            },
            {} as Record<string, string>,
        );

        // Step 3: Get code snippets for selected language
        const snippets = CODE_SNIPPETS[selectedLanguage as keyof typeof CODE_SNIPPETS];
        const integrationCode = snippets.getIntegration
            .replace("{{integration id}}", connectorId)
            .replace("{{api_key}}", apiKey || "your_api_key");

        let createConnectionCode = snippets.createConnection
            .replace("{{integration id}}", connectorId)
            .replace("{{api_key}}", apiKey || "your_api_key")
            .replace("{{user_entity_id}}", entityId);

        const isJS = selectedLanguage === "javascript";
        if (isJS) {
            createConnectionCode = createConnectionCode.replace(
                `\ndata:{{your_data}}`,
                Object.keys(object || {}).length === 0
                    ? ""
                    : `
    data:${JSON.stringify(object, null, 2).replace("}", "  }")}`,
            );
        } else {
            createConnectionCode = createConnectionCode.replace(
                `connected_account_params={{your_data}},`,
                Object.keys(object || {}).length === 0
                    ? ""
                    : `\n    connected_account_params=${JSON.stringify(object, null, 2).replace("}", "  }")},`,
            );
        }

        // Step 4: Replace placeholders in code snippets
        return {
            getIntegration: integrationCode,
            createConnection: createConnectionCode,
        };
    }, [selectedLanguage, apiKey, integrationData, formState.formValues, connectorId, entityId]);

    return (
        <React.Fragment>
            <div className={clsx("grid  grid-cols-7 gap-10 border-t-[#64646434] py-[0px] pb-10 font-gilroy text-[13px] text-[#ecececa2]")}>
                <div className="col-span-3 pl-10 pt-8">
                    <div className="mb-2 mt-1 font-cera text-[16px] font-[500] text-black-500">
                        Create a connected account to execute the action
                    </div>

                    <If condition={isStartFromAppScreenAndHasIntegration}>
                        <div className="flex min-w-full items-center gap-2">
                            <div className="mt-[12px] min-w-full overflow-hidden rounded-[16px] border-[0.5px] border-[#216dc4] bg-[#5ec7ff03] px-5 py-4 text-[#0c5973]">
                                <div className="text-[16px] font-[600]">Using existing integration for {appName}</div>
                                <div className="mt-2 flex items-center gap-2 text-[14px]">
                                    To create a new integration, click button below.
                                </div>
                                <div className="mt-4 flex justify-between">
                                    <Button
                                        size={32}
                                        variant={"black"}
                                        onClick={() => {
                                            goToFirstStep();
                                        }}
                                    >
                                        <PlusIcon className="mr-1" height={16} width={16} strokeWidth={1.2} />
                                        New integration
                                    </Button>
                                    <SelectIntegration
                                        appName={appName}
                                        onChange={(value) => {
                                            switchIntegration(value);
                                        }}
                                        selectedValue={connectorId}
                                    />
                                </div>
                            </div>
                        </div>
                    </If>

                    <div className="mt-1 flex flex-col gap-2">
                        <FAQItem step={1} title="Retrieve the integration " isOpen={true} onClick={() => {}}>
                            <div className="mt-[-4px] text-[14px]">Use the integration ID to fetch integration details</div>
                        </FAQItem>

                        <FAQItem step={2} title="Create a connected account for your user" isOpen={true} onClick={() => {}}>
                            <div className="mb-1 mt-[-4px] text-[14px]">Python & Javascript code to connect a new account 👉</div>
                            <div>
                                {stateInfo.index === 0 ? (
                                    <SetupFormWizard
                                        connectorId={connectorId}
                                        updateForm={updateForm}
                                        appName={appName}
                                        setOpen={setOpen}
                                    />
                                ) : (
                                    <ConnectionStatus
                                        connectionInfo={connectionInfo}
                                        stateInfo={stateInfo}
                                        setOpen={setOpen.bind(this)}
                                        appLogo={appLogo}
                                    />
                                )}
                            </div>
                        </FAQItem>
                    </div>
                </div>

                <div className="col-span-4  flex flex-col gap-6 bg-[#fff] px-7 !pt-[28px]  pr-10">
                    <div className="flex items-center gap-3">
                        <div className="text-sm font-medium text-gray-700">Language:</div>
                        <div className="flex items-center gap-2">
                            <SelectorButton
                                logo="/assets/icon/python.svg"
                                logoSize={28}
                                isSelected={selectedLanguage === "python"}
                                setSelected={setSelectedLanguage}
                                name="python"
                            />
                            <SelectorButton
                                logo="/assets/icon/js.svg"
                                logoSize={14}
                                isSelected={selectedLanguage === "javascript"}
                                setSelected={setSelectedLanguage}
                                name="javascript"
                            />
                        </div>
                    </div>

                    <CodeBlockComponent
                        topBarContent={"Code for creating a connection for a user"}
                        blocks={[
                            {
                                heading: "🔍 Get the integration",
                                text: transformedCodeSnippet.getIntegration || "",
                                language: selectedLanguage,
                            },
                            {
                                heading: "🚀 Initiate a New Connection",
                                text: transformedCodeSnippet.createConnection || "",
                                language: selectedLanguage,
                            },
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export const SelectorButton = ({
    isSelected,
    setSelected,
    name,
    logo = null,
    logoSize,
}: {
    isSelected: boolean;
    setSelected: (e: string) => void;
    name: string;
    logo?: string | null;
    logoSize?: number;
}) => {
    const isPython = useMemo(() => {
        return name === "python";
    }, [name]);

    return (
        <div
            className={`flex h-[30px] min-w-[60px] cursor-pointer items-center justify-center gap-2 rounded-[32px] border-[.5px] px-2 py-1 text-black-400 hover:border-[#b043ff] hover:bg-[#b043ff38] ${
                isSelected ? "border-[#b043ff] bg-[#b043ff38]" : " border-grey-500"
            }`}
            onClick={() => setSelected(name)}
        >
            <If condition={!!logo}>
                <img src={logo!} alt="logo" width={logoSize} height={logoSize} className={isPython ? "ml-[-4px]" : ""} />
            </If>
            <div className={`font-gilroy text-[13.5px] text-[13px] font-[500] tracking-[0.05px] ${isPython ? "ml-[-10px]" : ""}`}>
                {upperCaseFirstLetter(name)}
            </div>
        </div>
    );
};

export const SelectIntegration = ({
    appName,
    onChange,
    selectedValue,
}: {
    appName: string;
    onChange: (value: string) => void;
    selectedValue: string;
}) => {
    const { data: { items: connectors } = {} } = useAllIntegrations(1, appName);
    return (
        <SimpleDropdown
            options={
                connectors?.map((connector: any) => ({
                    name: `${connector.name}`,
                    value: connector.id,
                })) as {
                    name: string;
                    value: string;
                }[]
            }
            onChange={(value) => {
                // @ts-ignore
                onChange(value);
            }}
            value={selectedValue}
            size={32}
            placeholder="Tool"
            className="w-[160px]"
        />
    );
};

export const FAQItem = ({
    title,
    children,
    isOpen,
    onClick,
    step,
}: {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    onClick: () => void;
    step: number;
}) => {
    return (
        <div className="border-b-[0.5px] border-gray-200 py-6">
            <div className="flex w-full items-center gap-3 text-left" onClick={onClick}>
                <span className="font-cera text-[16px] font-[500] text-black-500 ">
                    <span className="mr-2">{step}.</span> {title}
                </span>
            </div>
            {isOpen && <div className="ml-[25px] mt-2 text-[15px] leading-[2] tracking-[0.15px] text-black-400">{children}</div>}
        </div>
    );
};

const ConnectionStatus = ({
    stateInfo,
    setOpen,
    appLogo,
    connectionInfo,
}: {
    stateInfo: any;
    setOpen: (e: boolean) => void;
    appLogo: string;
    connectionInfo: any;
}) => {
    const { refetch } = useConnections();
    const router = useRouter();
    return (
        <div>
            <DialogFooter className="mt-2 flex w-full justify-start">
                <div className="flex gap-2">
                    <Button
                        className="h-[40px] flex-1 rounded-[12px] px-4 text-[#fff]"
                        variant={"black"}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                            router.push(`/connection/${connectionInfo.connectedAccountId}?tab=triggers`);
                        }}
                    >
                        Try triggers
                    </Button>
                    <Button
                        className="h-[40px] flex-1 rounded-[12px] px-4"
                        variant={"default"}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                            router.push(`/connection/${connectionInfo.connectedAccountId}?tab=actions`);
                        }}
                    >
                        Try actions
                    </Button>
                </div>
            </DialogFooter>
        </div>
    );
};

const formValueAtom = atom<{
    formValues: Record<string, { value: string; required: boolean; error?: string }>;
}>({
    formValues: {},
});

const SetupFormWizard = ({
    connectorId,
    updateForm,
    appName,
}: {
    connectorId: string;
    updateForm: (e: any) => void;
    appName: string;
    setOpen: (e: boolean) => void;
}) => {
    const [entityId, setEntityId] = useAtom(entityIdAtom);
    const { data, isLoading } = useQuery({
        queryKey: QUERY_KEYS.getAccountIntegrationQueryKey(connectorId),
        queryFn: () => getConnectorInfo({ connectorId }),
        retry: false,
    });

    const { query } = useRouter();

    const [formState, setFormState] = useAtom(formValueAtom);

    useEffect(() => {
        const newFormValues = data?.expectedInputFields?.reduce((acc, field) => {
            //@ts-ignore
            return { ...acc, [field.name]: { value: field.default || "", required: field.required || false } };
        }, {});

        setFormState((prev) => ({ ...prev, formValues: newFormValues ? newFormValues : prev.formValues, yaml: "" }));
    }, [data]);

    const hasExpectedInputField = data?.expectedInputFields?.length || 0;

    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            className=" py-5 pt-3"
        >
            {hasExpectedInputField && !isLoading ? (
                <>
                    <div className="text-[13px] text-[15px] font-[500] text-black-400">
                        {hasExpectedInputField ? `Fill auth params to connect` : `Try from Dashboard`}
                    </div>
                    <div className="flex flex-wrap gap-4 pb-6 pt-3">
                        {data?.expectedInputFields?.map((field: any) => {
                            return (
                                <FormFieldRenderer
                                    title={field.displayName || field.display_name}
                                    placeholder={`Enter ${field.displayName || field.display_name}`}
                                    type={field?.type || "string"}
                                    description={field.description || ""}
                                    isRequired={field.required || false}
                                    value={formState.formValues?.[field.name]?.value || ""}
                                    error={formState.formValues?.[field.name]?.error}
                                    setValue={(value: string) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            formValues: {
                                                ...prev.formValues,
                                                [field.name]: {
                                                    value,
                                                    required: !!field?.required,
                                                },
                                            },
                                        }))
                                    }
                                    key={field.name}
                                />
                            );
                        })}
                    </div>
                </>
            ) : null}

            {isLoading && <ShimmerTitle line={2} gap={10} variant={"secondary"} />}

            {!isLoading && (
                <div className="mt-0 flex w-full">
                    <div className="flex flex-col  gap-2">
                        <div className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                                <div className="text-[13px] ">Your User's ID</div>{" "}
                                <ToolTipBox
                                    content={
                                        <div>
                                            This is your user ID. You can find it in the URL when you are logged in to the app.
                                            <br /> For example, if your email is sam@openai.com, your user ID would be sam%40openai.com.
                                        </div>
                                    }
                                >
                                    <InfoIcon className="h-3 w-3 " />
                                </ToolTipBox>
                            </div>
                            <Input
                                value={entityId}
                                onChange={(e) => {
                                    setEntityId(e.target.value);
                                }}
                                placeholder="Enter entity ID"
                                size={36}
                                icon={<UserCircle2Icon className="ml-[-6px] h-4 w-4 " strokeWidth={1.2} color="#343434" />}
                                className="w-[240px]"
                            />
                        </div>

                        <Button
                            className=" mt-3 h-[40px] w-[fit-content] min-w-[120px] gap-2 rounded-[12px] px-4 text-[14px] font-[600]"
                            variant={"default"}
                            onClick={() => updateForm(entityId)}
                            size={40}
                        >
                            Try connecting {entityId}'s {showNLetters(appName, 16)} <ArrowRight className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export const FormFieldRenderer = ({
    type,
    value,
    setValue,
    placeholder,
    isRequired,
    error,
    description,
    title = "",
}: {
    type: string;
    value: string;
    setValue: (value: string) => void;
    placeholder: string | undefined;
    isRequired: boolean;
    description: string;
    error?: string;
    title?: string;
}) => {
    if (type == "string") {
        return (
            <div>
                <div className="mb-[2px] text-left text-[13px] font-[500] text-black-200">
                    {title}
                    {isRequired && <span className="ml-1  text-[#ff5b81] ">*</span>}
                </div>
                <div className="mb-3 text-left text-[12px] text-black-400">
                    <ShowToolTipBoxIfMoreThan60Chars description={description} />
                </div>
                <Input
                    className=" w-[240px] flex-auto gap-1.5"
                    type="text"
                    placeholder={placeholder}
                    size={40}
                    value={value}
                    onChange={(e) => {
                        e.stopPropagation();
                        setValue(e.target.value);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
                <div className="ml-1  mt-2 text-[12px]  text-[#ff5b81]">{error}</div>
            </div>
        );
    }
};

export const ShowToolTipBoxIfMoreThan60Chars = ({ description, n = 60 }: { description: string; n?: number }) => {
    if (description.length > n) {
        return (
            <ToolTipBox content={<div className="max-w-[400px]">{description}</div>}>
                <div className="flex items-center gap-1">
                    <span>{description.substring(0, 57) + "..."}</span> <InfoIcon className="h-3 w-3 " />
                </div>
            </ToolTipBox>
        );
    }
    return description;
};
