import React, { useCallback } from "react";

interface IfProps {
    condition: boolean;
    children: React.ReactNode | (() => React.ReactNode);
}

export const If: React.FC<IfProps> = ({ condition, children }) => {
    const convertToFunction = useCallback(() => {
        return condition ? (typeof children === "function" ? children() : children) : null;
    }, [children, condition]);

    return <>{convertToFunction()}</>;
};
