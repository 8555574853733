import { css } from "@emotion/css";
import clsx from "clsx";
// @ts-ignore
import hljs from "highlight.js";
// @ts-ignore
import javascript from "highlight.js/lib/languages/javascript";
// @ts-ignore
import python from "highlight.js/lib/languages/python";
// @ts-ignore
import shell from "highlight.js/lib/languages/shell";
import { ChevronRightIcon, ChevronUpIcon, CopyIcon, TerminalIcon } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "./use-toast";

// Register languages
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("python", python);
hljs.registerLanguage("shell", shell);

interface Block {
    heading: string;
    text: string;
    language: string;
    isCollapsed?: boolean;
}

interface CodeBlockComponentProps {
    text?: string;
    className?: string;
    language?: string;
    blocks?: Block[];
    topBarContent?: string;
}

export const CodeBlockComponent: React.FC<CodeBlockComponentProps> = ({ text, language, className, blocks = [], topBarContent }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [collapsedIndexes, setCollapsedIndexes] = useState<number[]>([]);

    useEffect(() => {
        setCollapsedIndexes(blocks.map((block, index) => (block.isCollapsed ? index : -1)).filter((index) => index !== -1));
    }, [blocks]);

    const hashOfText = useMemo(() => {
        return text?.split("")?.reduce((acc, char) => {
            const charCode = char.charCodeAt(0);
            return ((acc << 5) - acc + charCode) | 0;
        }, 0);
    }, [text]);

    const blocksToRender = useMemo(() => {
        if (!blocks.length) {
            return [
                {
                    heading: "",
                    text,
                    language,
                    hash: hashOfText,
                },
            ];
        }

        return blocks.map((block) => ({
            ...block,
            hash: block.text.split("").reduce((acc, char) => {
                const charCode = char.charCodeAt(0);
                return ((acc << 5) - acc + charCode) | 0;
            }, 0),
        }));
    }, [blocks, text, language, hashOfText]);

    const isIndexCollapsed = useCallback(
        (index: number) => {
            return collapsedIndexes.includes(index);
        },
        [collapsedIndexes],
    );

    useEffect(() => {
        for (const [index, block] of blocksToRender.entries()) {
            const codeElement = document.getElementById(`code-block-text-${index}-${block.hash}-${isIndexCollapsed(index)}`);

            if (codeElement && block.language && block.text) {
                try {
                    // Remove data-highlighted attribute
                    codeElement.removeAttribute("data-highlighted");
                    const highlightedCode = hljs.highlight(block.text, {
                        language: block.language,
                    });

                    codeElement.innerHTML = highlightedCode.value;

                    hljs.highlightBlock(codeElement);
                } catch {
                    codeElement.textContent = block.text; // Fallback to plain text
                }
            }
        }
    }, [blocksToRender, collapsedIndexes.length]);

    const { toast } = useToast();

    const onCopy = () => {
        navigator.clipboard.writeText(blocksToRender.map((block) => block.text).join("\n"));
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
        toast({
            title: "Copied!",
            description: "Code copied to clipboard",
            variant: "success",
        });
    };

    return (
        <div
            id={`code-block-${language}`}
            className={clsx("overflow-hidden rounded-[16px] border-[.5px] border-gray-400 bg-[#FCFCFC]", shadowCss, className)}
        >
            <div className="flex justify-between rounded-t-lg border-b-[.5px] border-gray-300 px-5 py-[8px]">
                <div className="flex items-center gap-1">
                    <TerminalIcon className="h-4 w-4 text-black-500" strokeWidth={1.5} />
                    <div className="font-cera text-[13px] font-[400] tracking-[0.05px] !text-black-400">{topBarContent}</div>
                </div>
                <ToolTipBox content={isCopied ? "Copied!" : "Copy code to clipboard"} side="left" align="center">
                    <div className="flex cursor-pointer items-center gap-1 text-[13px] text-black-500" onClick={onCopy}>
                        <CopyIcon className="h-3 w-3" /> Copy
                    </div>
                </ToolTipBox>
            </div>
            {blocksToRender.map((block, index) => (
                <CodeBlockFragment
                    // @ts-ignore

                    // @ts-ignore
                    // @ts-ignore
                    // @ts-ignore

                    block={block}
                    index={index}
                    collaspsedIndexes={collapsedIndexes}
                    setCollapsedIndexes={setCollapsedIndexes}
                    key={index}
                />
            ))}
        </div>
    );
};

export const CodeBlockFragment = ({
    block,
    index,
    collaspsedIndexes,
    setCollapsedIndexes,
}: {
    block: Block;
    index: number;
    collaspsedIndexes: number[];
    setCollapsedIndexes: (indexes: number[]) => void;
}) => {
    const isCollapsed = collaspsedIndexes?.includes(index);

    return (
        <React.Fragment key={index}>
            {block.heading && (
                <div className="flex select-none items-center gap-0 bg-[#fff] pb-1 pt-[12px] text-black-600">
                    <hr className="w-[32px] border-t-[.5px] border-gray-300" />
                    <div
                        className=" flex min-w-[fit-content] cursor-pointer items-center gap-2 rounded-[12px] border-[.5px] border-gray-300 bg-gray-50 px-3 py-[2px] font-cera text-[13px] font-[400]"
                        onClick={() => {
                            if (collaspsedIndexes.includes(index)) {
                                setCollapsedIndexes(collaspsedIndexes.filter((i) => i !== index));
                            } else {
                                setCollapsedIndexes([...collaspsedIndexes, index]);
                            }
                        }}
                    >
                        {index + 1}. {block.heading}{" "}
                        {isCollapsed ? (
                            <ChevronRightIcon className="h-[12px] w-[12px] text-black-500" />
                        ) : (
                            <ChevronUpIcon className="h-[12px] w-[12px] text-black-500" />
                        )}
                    </div>
                    <hr className="w-full border-t-[.5px] border-gray-300" />
                </div>
            )}
            {!isCollapsed && (
                <pre>
                    <code
                        // @ts-ignore

                        // @ts-ignore
                        // @ts-ignore
                        // @ts-ignore

                        id={`code-block-text-${index}-${block.hash}-${isCollapsed}`}
                        className={`hljs language-${block.language}`}
                    >
                        {block.text}
                    </code>
                </pre>
            )}
        </React.Fragment>
    );
};

const shadowCss = css`
    box-shadow:
        lch(0 0 0 / 0.04) 0px 4px 4px -1px,
        lch(0 0 0 / 0.03) -4px 0px 0px 0px !important;
`;
