import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
// @ts-ignore
import cloneDeep from "lodash/cloneDeep";
import { CopyIcon, Trash2Icon } from "lucide-react";
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { z } from "zod";
import { ZIntegrationItemSchema, deleteConnector } from "~/dataProcessor/api/api";
import { UpdateQuery } from "~/dataProcessor/hooks/global/router";
import { useAllIntegrations } from "~/dataProcessor/hooks/integrations";
import { useApiKey } from "~/dataProcessor/hooks/user";
import { Button } from "~/design-system/atom/button";
import { Link } from "~/design-system/atom/link";
import { Switch } from "~/design-system/atom/switch";
import { ToolTipBox } from "~/design-system/atom/tooltip";
import { useToast } from "~/design-system/atom/use-toast";
import { ErrorBoundary } from "~/ui/components/base/errorBoundary";
import { PaginationComponent } from "~/ui/components/custom/PagninationComponent";
import { Table } from "~/ui/components/custom/Table";
import { CodeWInstructionModal } from "~/ui/containers/codeModal";
import { DeletePopup } from "~/ui/containers/common/ActionButton";
import ProfileGravatar from "~/ui/containers/common/ProfileGravatar";
import { copyText } from "~/utils/dom";
import { formatActionName, showFirstAndLastNLetters } from "~/utils/string";
import { CodeButton, setIsActiveStatus } from "../integrationPage";

const noop = () => {};

export default function LiveToolsList({ appName }: { appName?: string }) {
    const router = useRouter();
    const page =
        parseInt(router.query.page as string, 10) || parseInt(new URL((window as any).location!)?.searchParams?.get("page")!, 10) || 1;

    const updateQuery = UpdateQuery();

    const { data: { totalPages, items: connectors } = {}, isLoading, isError, isSuccess, refetch } = useAllIntegrations(page, appName);

    React.useEffect(() => {
        refetch();
    }, [page]);

    const headerRenderer = React.useCallback(() => {
        const tableHeaders = ["Tool", "Integration ID", "Connected accounts", "Created by", ""];

        return tableHeaders.map((header, index) => (
            <div
                key={index}
                className={clsx("font-gilroy text-[12px] font-[500]", index === tableHeaders.length - 1 ? "flex justify-end pr-4" : "")}
            >
                {header}
            </div>
        ));
    }, []);

    const rowRenderer = React.useCallback((connectorData: z.infer<typeof ZIntegrationItemSchema>) => {
        return <Row connectorData={connectorData} />;
    }, []);

    return (
        <ErrorBoundary fallback={<div>{`Something went wrong. We'll work on getting this fixed.`}</div>}>
            <>
                <div className="mt-[0px] flex items-center">
                    <div className="pt-2 text-[13px] font-[400] text-black-600">Integrations</div>
                    <div className="ml-auto mt-2 flex items-center gap-2">
                        <ShowIntegrationCode />
                        <PaginationComponent
                            currentPage={Number(page) || 1}
                            totalPages={Number(totalPages) || 1}
                            onChange={(page) => updateQuery("page", page.toString())}
                            height={32}
                        />
                    </div>
                </div>
                <Table
                    gridCSS={gridBox}
                    rows={connectors!}
                    state={isLoading ? "loading" : isError ? "error" : isSuccess ? "success" : "none"}
                    noResultText="No tools setup yet"
                    headerRenderer={headerRenderer}
                    rowRenderer={rowRenderer}
                    rowContainerHeight={window.innerHeight - 116}
                    rowHeight={50}
                    emptyStateClass="pt-10"
                    containerClass="rounded-bl-none rounded-br-none"
                    onRowClick={(row: z.infer<typeof ZIntegrationItemSchema>) => {
                        router.push(`/connector/${row.id}`);
                    }}
                />
            </>
        </ErrorBoundary>
    );
}

export const sampleCodeBlockData = {
    content: {
        python: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch integrations",
                            text: `from composio import ComposioToolSet, App

toolset = ComposioToolSet(api_key={{api_key}})
integrations = toolset.get_integrations(##Insert your code here##)
print(integrations)
`,
                            language: "python",
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <Link className="text-[15px]" href="https://docs.composio.dev/patterns/Auth/Integrations" isExternal={true}>
                            docs
                        </Link>{" "}
                        to learn more about integrations
                    </div>
                ),
            },
        ],
        javascript: [
            {
                type: "blockWizard",
                content: {
                    heading: "Code for getting all apps",
                    blocks: [
                        {
                            heading: "Fetch integrations",
                            text: `import {Composio} from "composio-core";
// get composio client
const composio = new Composio({apiKey: {{api_key}}});

const integrations = await composio.integrations.list({##Insert your code here##})
`,
                            language: "javascript",
                        },
                    ],
                },
            },
            {
                type: "content",
                content: (
                    <div className="mt-6 text-[15px] font-[400]">
                        Visit{" "}
                        <Link className="text-[15px]" href="https://docs.composio.dev/patterns/Auth/Integrations" isExternal={true}>
                            docs
                        </Link>{" "}
                        to learn more about integrations
                    </div>
                ),
            },
        ],
    },
};

const ShowIntegrationCode = () => {
    const [showCode, setShowCode] = useState(false);

    const apiKey = useApiKey();

    const { query } = useRouter();

    const appId = query.appId as string;
    const transformedData = useMemo(() => {
        // transform data of sampleCodeBlockData
        const data = cloneDeep(sampleCodeBlockData);

        for (const language of Object.keys(data.content)) {
            // @ts-ignore
            for (const languaeItem of data.content[language]) {
                //replace blocks with api key and integration id
                const { blocks } = languaeItem.content;

                if (!blocks) continue;

                for (const block of blocks) {
                    block.text = block.text.replace("{{api_key}}", `"${apiKey}"`);
                    let insertContent = ``;

                    if (language === "python") {
                        if (appId) insertContent += `\n  app="${appId}",`;
                    } else {
                        if (appId) insertContent += `\n  appName: "${appId}",`;
                    }

                    block.text = block.text.replace("##Insert your code here##", insertContent.length > 0 ? `${insertContent}\n` : "");
                }
            }
        }

        return data;
    }, [apiKey, appId]);

    return (
        <div>
            <CodeButton setShowCode={setShowCode} />
            {showCode && (
                <CodeWInstructionModal title="Get integrations with Our SDKs" data={transformedData} setOpen={() => setShowCode(false)} />
            )}
        </div>
    );
};

export const Row = ({ connectorData }: { connectorData: z.infer<typeof ZIntegrationItemSchema> }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isChecked, setIsChecked] = useState(connectorData.enabled);

    const { toast } = useToast();
    const router = useRouter();

    return (
        <>
            <div className="flex items-center gap-3">
                <div
                    className={clsx(
                        " h-5 w-5 ",
                        css`
                            background-image: url("${connectorData.logo}");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        `,
                    )}
                ></div>{" "}
                <div className="text-[13px] font-[500] ">{formatActionName(connectorData?.name || "")}</div>
            </div>
            <div className="w-fit text-[12px] font-[400] text-black-600">
                <ToolTipBox
                    side="right"
                    align="center"
                    content={isCopied ? "✅ Copied!" : "Copy ID"}
                    onOpenChange={(e) => {
                        if (!e) {
                            setIsCopied(false);
                        }
                    }}
                >
                    <span
                        className="flex cursor-pointer items-center gap-2"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            copyText(connectorData.id);
                            setIsCopied(true);
                        }}
                    >
                        {showFirstAndLastNLetters(connectorData.id)}
                        <CopyIcon height={12} width={12} strokeWidth={1.4} />
                    </span>
                </ToolTipBox>
            </div>
            <div className="text-[12px] font-[600] text-black-600">{connectorData._count.connections}</div>
            <div className="w-fit pl-6 text-[12px] font-[600] text-black-600">
                <ProfileGravatar
                    direction={"bottom"}
                    member={{
                        name: connectorData.member.name,
                        email: connectorData.member.email,
                    }}
                />
            </div>
            <div>
                <div
                    className="flex items-center justify-end gap-3 pr-3"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Switch
                        checked={isChecked}
                        id="airplane-mode"
                        onCheckedChange={(e) => {
                            setIsChecked(e);
                            setIsActiveStatus(connectorData.id, e)
                                .then(() => {
                                    toast({
                                        title: "Status updated",
                                        description: `Connector ${e ? "enabled" : "disabled"}`,
                                    });
                                })
                                .catch(() => {
                                    setIsChecked(!e);
                                    toast({
                                        title: "Error",
                                        description: `Error in updating status`,
                                        variant: "destructive",
                                    });
                                });
                        }}
                    />
                    <DeleteConnectorAction connectorId={connectorData.id} />
                    <Button
                        size={28}
                        variant={"plain"}
                        className=" mt-[auto] w-[fit-content]  rounded-[10px] border-[.5px] border-[#D5D5D5] !bg-[#FBFBFB] text-[1px] font-[400] tracking-[.3px] text-black-200"
                    >
                        <div
                            className="mt-[2px] text-[13px] font-[500]"
                            onClick={() => {
                                router.push(`/connector/${connectorData.id}`);
                            }}
                        >
                            Open
                        </div>
                    </Button>
                </div>
            </div>
        </>
    );
};

export const DeleteConnectorAction = ({ connectorId }: { connectorId: string }) => {
    const [open, setOpen] = useState(false);
    const { refetch } = useAllIntegrations();
    const { toast } = useToast();

    const router = useRouter();

    const isConnectorPage = router.pathname.includes("connector");
    const handleDeleteion = useMutation(deleteConnector, {
        onSuccess: () => {
            toast({
                title: "Success",
                description: "Connection was deleted successfully",
            });
            if (isConnectorPage) {
                router.push("/your_apps");
            } else {
                setOpen(false);
                refetch();
            }
        },
        onError: (error: Error) => {
            toast({
                title: "Error",
                description: error?.message,
                variant: "destructive",
            });
        },
    });

    const handleDelete = () => {
        handleDeleteion.mutate({
            connectorId: connectorId,
        });
    };

    return (
        <>
            {open && (
                <DeletePopup
                    handleDelete={handleDelete}
                    setOpen={() => {
                        setOpen(false);
                    }}
                />
            )}
            <div
                onClick={() => {
                    setOpen(true);
                }}
                className="cursor-pointer px-[px] text-black-600"
            >
                <Trash2Icon height={14} width={14} />
            </div>
        </>
    );
};
export const gridBox = css`
    display: grid;
    grid-template-columns: 240px 180px 180px 120px 1fr;
    gap: 0px;
    width: 100%;
`;
