// @ts-nocheck
import { useCallback, useRef } from "react";

/**
 * Debounce a function call to be executed after a specified delay period has elapsed
 * since the last time it was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {Function} A debounced version of the provided function.
 */
export function debounce(func, delay) {
    let timerId;

    return function (...args) {
        // @ts-ignore
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

/**
 * React hook to create a debounced function that will delay the execution of the
 * function it is given until after the delay has elapsed since the last time it was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay.
 * @returns {Function} A debounced version of the provided function.
 */
export function useDebounce(func, delay) {
    const functionRef = useRef(func);

    functionRef.current = func;
    // eslint-disable-next-line

    return useCallback(debounce(functionRef.current, delay), [delay]);
}

export function tryToCatch(func: () => void) {
    try {
        func();
    } catch (e) {}
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
