import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "~/constants/keys";
import { getActions, getTriggers } from "~/dataProcessor/api/api";

export const useAppActions = (appName: string, usecase: string) => {
    const {
        data: actionsData = {
            items: [],
        },
        isLoading: isActionsLoading,
    } = useQuery({
        queryKey: QUERY_KEYS.getAppActionsQueryKey(appName, usecase as string),
        queryFn: () =>
            getActions({
                appName: appName,
                usecase: usecase as string,
            }),
        staleTime: 10000 * 60 * 60 * 24,
        enabled: !!appName,
    });

    return {
        actionsData,
        isActionsLoading,
    };
};

export const useAppTriggers = (appName: string) => {
    const { data: triggersData = [], isLoading: isTriggerLoading } = useQuery({
        queryKey: QUERY_KEYS.getAppTriggersQueryKey(appName),
        queryFn: () =>
            getTriggers({
                appName: appName,
            }),
    });

    return {
        triggersData,
        isTriggerLoading,
    };
};
