import { css } from "@emotion/css";
import clsx from "clsx";
import * as React from "react";
import { scrollBarStyle } from "~/ui/constants/style/common";
import { Dialog, DialogContent } from "./dialog";

export const DialogBoxCenter = ({
    setOpen,
    children,
    className,
}: {
    open: boolean;
    setOpen: (e: boolean) => void;
    children: React.ReactNode;
    className?: string;
    isAnimating?: boolean;
}) => {
    return (
        <Dialog open={true} onOpenChange={() => setOpen(false)}>
            <DialogContent
                onFocusOutside={(e) => {
                    e?.stopPropagation();
                    setOpen?.(false);
                }}
                className={clsx("block", className)}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};
export const DialogBoxSheet = ({
    setOpen,
    children,
    className,
}: {
    open: boolean;
    setOpen: (e: boolean) => void;
    children: React.ReactNode;
    className?: string;
    isAnimating?: boolean;
}) => {
    return (
        <Dialog open={true} onOpenChange={() => setOpen(false)}>
            <DialogContent
                onFocusOutside={(e) => {
                    e?.stopPropagation();
                    setOpen?.(false);
                }}
                className={clsx(
                    "fixed bottom-[0] ",
                    scrollBarStyle,
                    className,
                    "animate-in slide-in-from-bottom-full",
                    css`
                        border-radius: 30px 30px 0 0 !important;

                        top: unset !important;
                        transform: translateY(0) translateX(-50%) !important;
                    `,
                )}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};
