import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import JsonView from "react18-json-view";
import { z } from "zod";
import { ZConnectionInfoData, sendProxyConnectionRequest } from "~/dataProcessor/api/api";
import { DialogBoxCenter } from "~/design-system/atom/alert-dialog-sheet";
import { Button } from "~/design-system/atom/button";
import { DialogDescription, DialogHeader } from "~/design-system/atom/dialog";
import { Input } from "~/design-system/atom/input";
import { useToast } from "~/design-system/atom/use-toast";
import { scrollBarStyle } from "~/ui/constants/style/common";

export const ProxyActionPlayground = ({
    setOpen,
    connectionInfo,
}: {
    setOpen: (e: boolean) => void;
    connectionInfo: z.infer<typeof ZConnectionInfoData>;
}) => {
    return (
        <DialogBoxCenter
            open={true}
            setOpen={setOpen.bind(this)}
            className={clsx(" max-h-[90vh] min-w-[580px] overflow-y-scroll bg-white ", scrollBarStyle)}
        >
            <DialogHeader className="pb-5">
                <div className="font-cera text-[15px] font-[500]  leading-none tracking-[.35px] text-black-300">API Request playground</div>
            </DialogHeader>
            <DialogDescription className="text-black-300`  !mt-[-12px] pt-[0px] font-gilroy text-[13px] tracking-[.35px]">
                <Playground actionData={connectionInfo} />
            </DialogDescription>
        </DialogBoxCenter>
    );
};

const Playground = ({ actionData: connectionData }: { actionData: z.infer<typeof ZConnectionInfoData> }) => {
    const { toast } = useToast();
    const [urlToCall, setUrlToCall] = useState(connectionData?.meta?.app?.get_current_user_endpoint);

    const [response, setResponse] = useState("");

    const {
        mutate: callProxyAction,
        isLoading,
        error,
        isError,
    } = useMutation({
        mutationFn: sendProxyConnectionRequest,
        onSuccess: (data) => {
            toast({
                title: "Success",
                description: "Request was sent successfully",
            });
            setResponse(JSON.stringify(data, null, 2));
        },
        onError: (err: any) => {
            toast({
                title: "Error",
                description:
                    isError && typeof err.response?.data?.message === "string" ? err.response?.data?.message || err.message : err.message,
                variant: "destructive",
            });

            setResponse(JSON.stringify("Reques", null, 2));
        },
    });

    useEffect(() => {
        if (!!urlToCall) {
            const endpoint = connectionData?.meta?.app?.get_current_user_endpoint;

            if (endpoint && connectionData.id) {
                callProxyAction({
                    endpoint: endpoint,
                    method: "GET",
                    connectedAccountId: connectionData.id,
                    parameters: [],
                });
            }
        }
    }, []);

    return (
        <>
            <div className="mb-0 mt-4 text-left text-[15px] font-[600]  text-black-300">URL to call</div>
            <div className={clsx("mb-4  mt-2 w-full items-center justify-end gap-6 text-left leading-none")}>
                <Input value={urlToCall === null ? "" : urlToCall} onChange={(e) => setUrlToCall(e.target.value)} />
            </div>
            <div className="mb-0 mt-8 text-left text-[15px] font-[600]  text-black-300">Inputs required for the action</div>
            <div className={clsx("mb-2 mt-2 w-full items-center justify-end gap-6 text-left leading-none")}>
                <div className="pt-2 text-[14px]">No parameters are required for this action</div>
            </div>
            <div className="flex w-full items-center justify-end gap-4 leading-none">
                <Button
                    className="ml-[auto] mt-2  flex h-[36px] w-[fit-content] gap-1 rounded-[10px] px-4 text-[13px] text-white"
                    disabled={isLoading}
                    variant="default"
                    onClick={() => {
                        try {
                            if (!urlToCall) {
                                toast({
                                    title: "Invalid URL",
                                    description: "Please enter a valid URL",
                                    variant: "destructive",
                                });
                                return;
                            }

                            callProxyAction({
                                endpoint: urlToCall,
                                connectedAccountId: connectionData.id,
                                method: "GET",
                                parameters: [],
                            });
                        } catch {
                            toast({
                                title: "Invalid JSON",
                                description: "Please validate JSON",
                                variant: "destructive",
                            });
                        }
                    }}
                >
                    {isLoading ? "Loading..." : "Run action"}
                </Button>
            </div>
            {response.length > 0 && (
                <div className={clsx(" mb-6 mt-12 w-full items-center justify-end gap-4 leading-none")}>
                    <div className="mb-6 mt-2 text-[14px] font-[600] text-black-300">Response from request</div>
                    <JsonView
                        src={isError ? error?.response?.data?.message : response ? JSON.parse(response) : "{}"}
                        className=" max-w-[520px] overflow-hidden rounded-[16px] border-[1px] border-grey-300  p-4"
                    />
                </div>
            )}
        </>
    );
};
